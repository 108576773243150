import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqsRoutingModule } from './faqs-routing.module';
import { AddFaqComponent } from './add-faq/add-faq.component';
import {CoreSidebarModule} from '../../../@core/components';
import {FaqsComponent} from './faqs.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [AddFaqComponent,FaqsComponent],
  imports: [
    CommonModule,
    FaqsRoutingModule,
    CoreSidebarModule,
    NgbPaginationModule,
    CoreDirectivesModule,
    ReactiveFormsModule
  ],
  exports :[FaqsComponent ,AddFaqComponent]
})
export class FaqsModule { }
