<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        (click)="toggleSidebar()"
                        aria-label="Close"
                        class="close"
                        data-dismiss="modal"
                        type="button"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 *ngIf="!data || !data['id']" class="modal-title">Add Doctor</h5>
                    <h5 *ngIf="data && data['id']" class="modal-title">Update Doctor</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form [formGroup]="addDoctorForm" class="form mt-2 shadow-none" novalidate>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input class="form-control" formControlName="name" id="name" type="text">
                                        <small *ngIf="submitted && addDoctorForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="username">Username</label>
                                        <input class="form-control" formControlName="username" id="username"
                                               type="text">
                                        <small *ngIf="submitted && addDoctorForm.get('username').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="link">Link</label>
                                        <input class="form-control" formControlName="link" id="link"
                                               type="text">
                                        <small *ngIf="submitted && addDoctorForm.get('link').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="video_username">V. Username</label>
                                        <input class="form-control" formControlName="video_username" id="video_username"
                                               type="text" maxlength="3">
                                        <small *ngIf="submitted && addDoctorForm.get('video_username').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="specialisation">Specialisation</label>
                                        <input class="form-control" formControlName="specialisation" id="specialisation"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="medical_registration_number">Medical Registration Number</label>
                                        <input class="form-control" formControlName="medical_registration_number"
                                               id="medical_registration_number"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control" formControlName="email" id="email" type="text">
                                        <small *ngIf="submitted && addDoctorForm.get('email').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addDoctorForm.controls['email'].errors && addDoctorForm.controls['email'].errors.pattern"
                                               class="text-danger">Invalid email format</small>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contact">Contact</label>
                                        <input class="form-control" formControlName="contact" id="contact"
                                               type="number">
                                        <small *ngIf="submitted && addDoctorForm.get('contact').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addDoctorForm.controls['contact'].errors && addDoctorForm.controls['contact'].errors.pattern"
                                               class="text-danger">Invalid contact format</small>

                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="gender">Gender</label>
                                        <select class="form-control" formControlName="gender" id="gender">
                                            <option value="">Select Gender</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                        </select>
                                        <small *ngIf="submitted && addDoctorForm.get('gender').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="gender">Work Status</label>
                                        <select class="form-control" formControlName="work_status" id="work_status">
                                            <option value="">Select work status</option>
                                            <option value="ACTIVE">Active</option>
                                            <option value="INACTIVE">InActive</option>
                                        </select>
                                        <small *ngIf="submitted && addDoctorForm.get('work_status').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contact">Work Hours</label>
                                        <input class="form-control" formControlName="work_hours" id="work_hours"
                                               type="number">
                                        <small *ngIf="submitted && addDoctorForm.get('work_hours').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contact">Experience</label>
                                        <input class="form-control" formControlName="experience" id="experience"
                                               type="number">
                                        <small *ngIf="submitted && addDoctorForm.get('experience').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="remark">Remark</label>
                                        <textarea class="form-control resize-none" formControlName="remark"
                                                  id="remark"></textarea>
                                        <small *ngIf="submitted && addDoctorForm.get('remark').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="!data || !data.id" class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="d-flex" for="password">
                                            Password
                                            <i (click)="inputTypePassword = 'text'"
                                               [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                               class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                            <i (click)="inputTypePassword = 'password'"
                                               [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                               class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                        </label>
                                        <input [type]="inputTypePassword" class="form-control"
                                               formControlName="password"
                                               id="password">
                                        <small *ngIf="submitted && addDoctorForm.get('password').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button (click)="toggleSidebar()" class="btn btn-danger" rippleEffect type="reset">
                                        Cancel
                                    </button>
                                    <button (click)="submit()" *ngIf="!data || !data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Submit
                                    </button>
                                    <button (click)="update()" *ngIf="data && data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit" [disabled]="user?.user_type !== 'ADMIN'">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
