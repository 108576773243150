import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../shared/services/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    showSearch = false;
    users: any = [];
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private userService: UserService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-user').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    updatePasswordModal(content, id) {
        this.id = id;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.users = [];
        this.userService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.users = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.users = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.userService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.userService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    userOrders(data) {
        this.router.navigateByUrl('pos/add-order/' + data.id);
    }
}
