<div class="content-wrapper">
    <div class="content-body">
        <section id="blogs">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Meta Tags</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Meta Tags
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="title"
                                           placeholder="title">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Title</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Meta
                                                Description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Meta
                                                Keyword</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_og_title</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_og_url</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_og_type</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_og_description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_og_image</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_twitter_card</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_twitter_title</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_twitter_description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_twitter_url </h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                meta_twitter_image</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Status</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="metaTags && metaTags.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let metaTag of metaTags; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.title}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_description}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_keyword}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_og_title}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_og_url}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_og_type}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{metaTag?.meta_og_description}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_og_image}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{metaTag?.meta_twitter_card}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{metaTag?.meta_twitter_title}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{metaTag?.meta_twitter_description}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.meta_twitter_url}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{metaTag?.meta_twitter_image}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{metaTag?.status}}</h6></td>
                                            <td class="text-center" style="padding: 0rem 0rem; width: 200px;">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(metaTag)"></i>
                                                <i class="fas fa-check-circle f_20 text-success cursor-pointer font-weight-light mt-1 mr-1 ml-1"
                                                   title="Active" *ngIf="metaTag?.status === 'ACTIVE'"
                                                   (click)="updateStatus('IN_ACTIVE', metaTag)"></i>
                                                <i class="fas fa-times-circle f_20 text-danger cursor-pointer font-weight-light mt-1 mr-1 ml-1"
                                                   title="In Active" *ngIf="metaTag?.status === 'IN_ACTIVE'"
                                                   (click)="updateStatus('ACTIVE', metaTag)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="15" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="metaTags && metaTags.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let metaTag of metaTags; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{metaTag?.title}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Executive" *ngIf="metaTag?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', metaTag)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Executive" *ngIf="metaTag?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', metaTag)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(metaTag)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-meta-tags"
            overlayClass="modal-backdrop">
        <app-add-meta-tags [data]="data" (valueSubmitted)="getData($event)"></app-add-meta-tags>
    </core-sidebar>
</div>
