import {Component, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {AdminService} from '../../shared/services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnChanges {
    data: any;
    id: any;
    showSearch = false;
    admins = [];
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    submitted: any = false;
    submitting: any = false;
    updatePasswordForm: FormGroup;
    details: any = [
        {
            SNO: 1,
            transaction: '125472',
            amount: '500',
            no_of_products: 5,
            date: '12/05/2022',
            time: '12:30',
            status: 'success'
        }
        , {
            SNO: 2,
            transaction: '125473',
            amount: '400',
            no_of_products: 4,
            date: '12/05/2022',
            time: '12:30',
            status: 'success'
        }
        , {
            SNO: 3,
            transaction: '125478',
            amount: '300',
            no_of_products: 3,
            date: '12/05/2022',
            time: '12:30',
            status: 'failed'
        }
        , {
            SNO: 4,
            transaction: '125425',
            amount: '600',
            no_of_products: 6,
            date: '12/05/2022',
            time: '12:30',
            status: 'Pending'
        }
    ];

    // @ts-ignore
    // @ts-ignore
    inputTypePassword: string;
    constructor(private _coreSidebarService: CoreSidebarService,
                private adminService: AdminService,
                public modalService: NgbModal,
                private fb: FormBuilder) {
    }

    ngOnChanges(): void {
        this.refresh();
    }
    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'testimonial': [''],
            'rating': [''],
        });
        this.get();
    }
    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }
    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-testimonial').toggleOpen();
    }
    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'testimonial': (this.searchForm.value && this.searchForm.value.testimonial) ? this.searchForm.value.testimonial : '',
            'rating': (this.searchForm.value && this.searchForm.value.rating) ? this.searchForm.value.rating : '',
        };
        this.get(params);
    }
    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }
    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'testimonial': (this.searchForm && this.searchForm.value && this.searchForm.value.testimonial) ? this.searchForm.value.testimonial : '',
            'rating': (this.searchForm && this.searchForm.value && this.searchForm.value.rating) ? this.searchForm.value.rating : '',
        };
        this.get(params);
    }
    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }
    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.adminService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

}
