import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';


@Injectable({
    providedIn: 'root'
})
export class DoctorService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.DOCTORS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.DOCTORS_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.DOCTORS(id));
    }

    // consultDetailByDoctorId(id): Observable<any> {
    //     return this.getRequest(AppUrl.DOCTORS_CONSULTS(id));
    // }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.DOCTORS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.DOCTORS(id), data);
    }

    updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.DOCTOR_PASSWORD(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.DOCTOR_STATUS(id), data);
    }

    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    updateProfileImage(id, data): Observable<any> {
        return this.putRequest(AppUrl.PROFILE_IMAGE_UPDATE(id), data);
    }

    update_content(id, data): Observable<any> {
        return this.putRequest(AppUrl.DOCTORS_CONTENT(id), data);
    }
}
