import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {ContactusService} from '../../../shared/services/contactus.service';
import {SelfAssessmentService} from '../../../shared/services/self-assessment.service';

@Component({
  selector: 'app-self-assessment',
  templateUrl: './self-assessment.component.html',
  styleUrls: ['./self-assessment.component.scss']
})
export class SelfAssessmentComponent implements OnInit {
  userdataselfassessment = [];
  data: any;
  submitting: any = false;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private selfAssessmentService: SelfAssessmentService) { }

  ngOnInit(): void {
    this.refresh();
  }
  refresh() {
    this.get();
  }
  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.userdataselfassessment = [];
    this.selfAssessmentService.get(params).subscribe(data => {
      if (data ) {
        this.userdataselfassessment = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.userdataselfassessment = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
    };
    this.get(params);
  }
}
