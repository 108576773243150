import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {CategoryService} from '../../../shared/services/category.service';

@Component({
    selector: 'app-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss']
})

export class AddCategoryComponent implements OnChanges {
    addCategoryForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private categoryService: CategoryService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    refresh() {
        this.addCategoryForm = this.fb.group({
            'name': ['', Validators.required],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addCategoryForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-category').toggleOpen();
    }

    submit() {
        this.submitted = true;
        if (!this.addCategoryForm.valid) {
            return;
        }

        if (this.submitted) {
            const params = {
                'name': (this.addCategoryForm.value && this.addCategoryForm.value.name) ? this.addCategoryForm.value.name : '',
            };
            this.submitting = true;
            this.categoryService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addCategoryForm.reset();
                    this.addCategoryForm.patchValue({
                        'name': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addCategoryForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addCategoryForm.value && this.addCategoryForm.value.name) ? this.addCategoryForm.value.name : ''
            };
            this.submitting = true;
            this.categoryService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }
}
