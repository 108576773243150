import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DoctorService} from '../../shared/services/doctor.service';
import {StorageService} from '../../shared/services/storage.service';
import {AlertService} from '../../shared/services/alert.service';


@Component({
    selector: 'app-doctors',
    templateUrl: './doctors.component.html',
    styleUrls: ['./doctors.component.scss']
})

export class DoctorsComponent implements OnInit {
    showSearch = false;
    doctors = [];
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    user: any;
    onFileChanged: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private doctorService: DoctorService,
                private alertService: AlertService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-doctor').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    updatePasswordModal(content, id) {
        this.id = id;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    doctorInfoPage(data: any) {
        this.router.navigateByUrl('doctors-content-info/' + data.id);
    }

    // onFileChange(e, type, data) {
    //     this.onFileChanged = e;
    //     this.doctorService.fileUpload({
    //         'type': type,
    //         'fileType': 'NORMAL',
    //         // 'insight_id': data.id
    //         // tslint:disable-next-line:no-shadowed-variable
    //     }, this.onFileChanged).subscribe(data => {
    //         if (type === 'PROFILE_IMAGE') {
    //             if (data && data['data']) {
    //                 this.get();
    //             }
    //         }
    //         this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    //     }, error => {
    //         this.alertService.showErrors('Something went wrong, please try again');
    //     });
    // }

    onFileChange(e, type, doctor) {
        if (type === 'PROFILE_IMAGE') {
            this.onFileChanged = e;
            this.doctorService.fileUpload({
                'type': type,
                'fileType': 'NORMAL',
            }, this.onFileChanged).subscribe(data => {
                this.updateProfileImage(doctor, data['data']);
            }, error => {
                this.alertService.showErrors('Something went wrong, please try again');
            });
        }
    }

    updateProfileImage(doctor, data) {
        this.doctorService.updateProfileImage(doctor.id, {'profile_image': data.id}).subscribe(data => {
            this.submitted = false;
            this.get();
        }, error => {
            this.submitted = false;
        });
    }

    openProfileImage(data) {
        window.open(data?.profile_image?.['url'], '_blank');
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.doctors = [];
        this.doctorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.doctors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.doctors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.doctorService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.doctorService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    goTo(data) {
        this.router.navigateByUrl('doctor/' + data.id + '/consults');
    }
}
