import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DoctorService} from '../../../shared/services/doctor.service';
import {StorageService} from '../../../shared/services/storage.service';


@Component({
    selector: 'app-content-info',
    templateUrl: './content-info.component.html',
    styleUrls: ['./content-info.component.scss']
})
export class ContentInfoComponent implements OnInit {
    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    submitting: any = false;
    submitted: any = false;
    contentData: any;
    public doctorId: any;
    user: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private doctorService: DoctorService) {
        this._unsubscribeAll = new Subject();
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.doctorId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.doctorService.detail(this.doctorId).subscribe(data => {
            if (data) {
                this.data = data;
                if (this.data && this.data?.content) {
                    this.contentData = this.data?.content;
                }
            } else {
                this.router.navigateByUrl('doctors');
            }
        }, error => {
            this.router.navigateByUrl('doctors');
        });
    }

    submit() {
        const params = {
            'content': this.contentData ? this.contentData : ''
        };
        this.doctorService.update_content(this.data?.id, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }
}
