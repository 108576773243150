import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../../shared/services/alert.service';
import {DocprescriptionService} from '../../../../shared/services/docprescription.service';
import {ConsultService} from '../../../../shared/services/consult.service';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'app-add-prescription',
    templateUrl: './add-prescription.component.html',
    styleUrls: ['./add-prescription.component.scss']
})
export class AddPrescriptionComponent implements OnChanges {
    medicines: any[] = [];
    medicationData: any = [];
    isMedicationRequired = false;
    isMedicineSaved = false;
    addVitals = false;
    addPrescriptionForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    appointId: any;
    id: any;
    consultData: any;
    medications: string[] = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private docPrescriptionService: DocprescriptionService,
                private consultService: ConsultService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnChanges(): void {
        this.appointId = this.activatedRoute.snapshot.params['appointId'];
        this.id = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-prescription').toggleOpen();
    }

    addInputBox() {
        this.medications.push('');
    }

    refresh() {
        this.getConsultantDetail();
        this.addPrescriptionForm = this.fb.group({
            'chief_complaint': ['', Validators.required],
            'examination': ['', Validators.required],
            'temperature': [''],
            'pulse': [''],
            'respiratory_rate': [''],
            'blood_pressure': [''],
            'spo2': [''],
            'height': [''],
            'weight': [''],
            'diagnosis': [''],
            // 'medication': ['', Validators.required],
            'procedures': [''],
            'investigation': ['', Validators.required],
            'advice': ['']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    addMedicine(value: string): void {
        if (value.trim() === '') {
            this.isMedicationRequired = true;
            return;
        }
        const medicine = {
            name: value,
            frequency: '',
            timing: '',
            duration: '',
            remark: ''
        };
        this.medicines.push(medicine);
        this.updateMedicationData();
        this.clearMedicineInput();
    }

    private clearMedicineInput() {
        // Access the input element and clear its value
        const medicineInput = document.getElementById('medicine') as HTMLInputElement;
        medicineInput.value = '';
    }

    storeValues(
        medicine: any,
        frequencyValue: string,
        timingValue: string,
        durationValue: string,
        remarkValue: string
    ): void {
        medicine.frequency = frequencyValue;
        medicine.timing = timingValue;
        medicine.duration = durationValue;
        medicine.remark = remarkValue;
        this.updateMedicationData();
    }

    updateMedicationData(): void {
        this.medicationData = [];
        this.medicines.forEach((medicine, index) => {
            const medicineKey = `${index}`;
            this.medicationData[medicineKey] = medicine;
        });
    }

    removeMedicine(index: number): void {
        this.medicines.splice(index, 1);
        this.updateMedicationData();
    }

    saveMedicationData(): void {
        if (this.medicines.length === 0) {
            this.isMedicationRequired = true;
        } else {
            this.isMedicationRequired = false;
            this.isMedicineSaved = true;
        }
    }


    getConsultantDetail() {
        this.consultService.detail(this.appointId, this.id).subscribe(data => {
            this.consultData = data;
        });
    }

    // addTags() {
    //     const medication: any = this.addPrescriptionForm.value.medications;
    //     this.medications = medication.trim().split(' ');
    // }

    patchForm() {
        // console.log('patchForm', this.data['medication']);
        this.addPrescriptionForm.patchValue({
            'chief_complaint': (this.data && this.data['chief_complaint']) ? this.data['chief_complaint'] : '',
            'examination': (this.data && this.data['examination']) ? this.data['examination'] : '',
            'temperature': (this.data && this.data['temperature']) ? this.data['temperature'] : '',
            'pulse': (this.data && this.data['pulse']) ? this.data['pulse'] : '',
            'respiratory_rate': (this.data && this.data['respiratory_rate']) ? this.data['respiratory_rate'] : '',
            'blood_pressure': (this.data && this.data['blood_pressure']) ? this.data['blood_pressure'] : '',
            'spo2': (this.data && this.data['spo2']) ? this.data['spo2'] : '',
            'height': (this.data && this.data['height']) ? this.data['height'] : '',
            'weight': (this.data && this.data['weight']) ? this.data['weight'] : '',
            'diagnosis': (this.data && this.data['diagnosis']) ? this.data['diagnosis'] : '',
            // 'medication': (this.data && this.data['medication']) ? this.data['medication'].replace(/,/g, ' ') : '',
            'procedures': (this.data && this.data['procedures']) ? this.data['procedures'] : '',
            'investigation': (this.data && this.data['investigation']) ? this.data['investigation'] : '',
            'advice': (this.data && this.data['advice']) ? this.data['advice'] : ''
        });
        this.medicines = this.data['medication'];
        // const medicationObject = JSON.parse(this.data['medication']);
        // for (const key of Object.keys(medicationObject)) {
        //     const medicineData = medicationObject[key];
        //     this.medicines.push(medicineData);
        // }
    }


    submit() {
        this.submitted = true;
        if (this.medicines.length === 0) {
            this.isMedicationRequired = true;
            return;
        }
        if (!this.addPrescriptionForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'chief_complaint': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.chief_complaint) ? this.addPrescriptionForm.value.chief_complaint : '',
                'examination': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.examination) ? this.addPrescriptionForm.value.examination : '',
                'temperature': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.temperature) ? this.addPrescriptionForm.value.temperature : '',
                'pulse': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.pulse) ? this.addPrescriptionForm.value.pulse : '',
                'respiratory_rate': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.respiratory_rate) ? this.addPrescriptionForm.value.respiratory_rate : '',
                'blood_pressure': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.blood_pressure) ? this.addPrescriptionForm.value.blood_pressure : '',
                'spo2': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.spo2) ? this.addPrescriptionForm.value.spo2 : '',
                'height': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.height) ? this.addPrescriptionForm.value.height : '',
                'weight': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.weight) ? this.addPrescriptionForm.value.weight : '',
                'diagnosis': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.diagnosis) ? this.addPrescriptionForm.value.diagnosis : '',
                'medication': this.medicationData ? this.medicationData : '',
                'procedures': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.procedures) ? this.addPrescriptionForm.value.procedures : '',
                'investigation': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.investigation) ? this.addPrescriptionForm.value.investigation : '',
                'advice': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.advice) ? this.addPrescriptionForm.value.advice : '',
            };
            this.submitting = true;
            this.docPrescriptionService.create(this.consultData.id, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addPrescriptionForm.reset();
                    this.addPrescriptionForm.patchValue({
                        'chief_complaint': '',
                        'examination': '',
                        'temperature': '',
                        'pulse': '',
                        'respiratory_rate': '',
                        'blood_pressure': '',
                        'spo2': '',
                        'height': '',
                        'weight': '',
                        'diagnosis': '',
                        'medication': '',
                        'procedures': '',
                        'investigation': '',
                        'advice': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].medication && error['error']['errors'].medication.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].medication[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        this.updateMedicationData();
        if (this.medicines.length === 0) {
            this.isMedicationRequired = true;
            return;
        }
        if (!this.addPrescriptionForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'chief_complaint': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.chief_complaint) ? this.addPrescriptionForm.value.chief_complaint : '',
                'examination': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.examination) ? this.addPrescriptionForm.value.examination : '',
                'temperature': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.temperature) ? this.addPrescriptionForm.value.temperature : '',
                'pulse': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.pulse) ? this.addPrescriptionForm.value.pulse : '',
                'respiratory_rate': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.respiratory_rate) ? this.addPrescriptionForm.value.respiratory_rate : '',
                'blood_pressure': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.blood_pressure) ? this.addPrescriptionForm.value.blood_pressure : '',
                'spo2': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.spo2) ? this.addPrescriptionForm.value.spo2 : '',
                'height': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.height) ? this.addPrescriptionForm.value.height : '',
                'weight': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.weight) ? this.addPrescriptionForm.value.weight : '',
                'diagnosis': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.diagnosis) ? this.addPrescriptionForm.value.diagnosis : '',
                'medication': this.medicationData ? this.medicationData : '',
                'procedures': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.procedures) ? this.addPrescriptionForm.value.procedures : '',
                'investigation': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.investigation) ? this.addPrescriptionForm.value.investigation : '',
                'advice': (this.addPrescriptionForm.value && this.addPrescriptionForm.value.advice) ? this.addPrescriptionForm.value.advice : '',
            };
            this.submitting = true;
            this.docPrescriptionService.update(this.data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.medicines = [];
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].medication && error['error']['errors'].medication.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].medication[0]);
                        return;
                    }
                }
            });
        }
    }
}
