import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {AlertService} from '../../../shared/services/alert.service';
import {StorageService} from '../../../shared/services/storage.service';
import {ConsultantService} from '../../../shared/services/consultant.service';

@Component({
    selector: 'app-consultant-appointments',
    templateUrl: './consultant-appointments.component.html',
    styleUrls: ['./consultant-appointments.component.scss']
})
export class ConsultantAppointmentsComponent implements OnInit {
    showSearch = false;
    appointments = [];
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    user: any;
    consultantData: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private appointmentService: AppointmentService,
                private consultantService: ConsultantService,
                private activatedRoute: ActivatedRoute) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.getConsultantData();
    }

    getConsultantData() {
        this.consultantService.detail(this.id).subscribe(data => {
            this.consultantData = data;
            this.get();
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params['added_by'] = this.consultantData['id'];
        this.submitting = true;
        this.appointments = [];
        this.appointmentService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.appointments = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.appointments = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    goTo(appointment) {
        this.router.navigateByUrl('appointment/' + appointment.id + '/consults');
    }
}
