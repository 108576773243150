<div class="content-wrapper">
    <div class="content-body">
        <div id="user-profile">
            <section id="profile-info">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-1 order-lg-2 ">
                        <div class="form-group mb-2">
                            <div class="card">
                                <div class="card-header">
                                    <h2>Doctors Content</h2>
                                </div>
                                <div class="card-body">
                                    <quill-editor [(ngModel)]="contentData" class="university-info"
                                                  name="quill-content"></quill-editor>
                                </div>
                                <div class="card-footer">
                                    <button (click)="submit()" class="btn btn-primary pull-right" rippleEffect [disabled]="user?.user_type !== 'ADMIN'">
<!--                                        <i class="d-block d-md-none" data-feather="submit"></i>-->
                                        <span class="font-weight-bold d-none d-md-block">Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

