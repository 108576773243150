<div class="content-wrapper">
    <div class="content-body">
        <section id="orders">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'CHANGE_DELIVERY_STATUS'">Update Delivery Status - {{selectedTransactionData?.invoice_no}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'VIEW_ORDERS'">View orders - {{selectedTransactionData?.invoice_no}} - {{this.selectedTransactionData['orders'][0]['order_id']}}</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="modalType === 'CHANGE_DELIVERY_STATUS'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updateDeliveryStatusForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="status">Status</label>
                                                    <select class="form-control" id="status" formControlName="delivery_status">
                                                        <option value="">Select Status</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="PLACED">Placed</option>
                                                        <option value="DELIVERING">Delivering</option>
                                                        <option value="DELIVERED">Delivered</option>
                                                    </select>
                                                    <small *ngIf="submitted && updateDeliveryStatusForm.get('status').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row p-20px" *ngIf="modalType === 'VIEW_ORDERS'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <table class="table table-bordered p-5">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let order of this.selectedTransactionData['orders']; let i = index">
                                        <td>
                                            {{i + 1}}.
                                        </td>
                                        <td>
                                            {{order?.product?.product_alias}}
                                        </td>
                                        <td>
                                            {{order?.quantity}}
                                        </td>
                                        <td>
                                            {{order?.total_price}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="updateStatus()" *ngIf="modalType === 'CHANGE_DELIVERY_STATUS'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                        <button class="btn btn-danger color-white pull-right" [disabled]="submitting"
                                (click)="modalService.dismissAll('')" *ngIf="modalType === 'VIEW_ORDERS'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Close
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Orders</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name"
                                           placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="contact"
                                           placeholder="Contact">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="invoice_no"
                                           placeholder="Invoice No.">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Date Time</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Transaction Id</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Invoice No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Delivery Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">AMT</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Type</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Address Shipping</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">BRN</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">STC</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">TRN</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">TET</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">PMD</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">RID</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">VER</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">CID</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">TYP</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">CRN</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">CNY</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">CKS</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="orders && orders.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let order of orders; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">
                                                    {{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}.
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">
<!--                                                    <i class="fas fa-trash f_17 text-danger cursor-pointer" (click)="deleteTransaction(order)"></i>-->
                                                    <i class="fas fa-cart-arrow-down f_17 text-info ml-10px cursor-pointer" title="View Orders" (click)="viewOrders(content, order)"></i>
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{order?.created_at}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="order['transaction_id']">{{order?.transaction_id}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['transaction_id']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-info cursor-pointer" (click)="openExternalUrl('product-invoice-pdf/'+order?.transaction_id)" *ngIf="order['invoice_no']">{{order?.invoice_no}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['invoice_no']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-capitalize cursor-pointer text-info"
                                                    *ngIf="order && order['orders'] && order['orders'][0] && order['orders'][0]['delivery_status'] && this.user && this.user['user_type'] && this.user['user_type'] === 'ADMIN'"
                                                    (click)="changeDeliveryStatus(content, order)">{{order?.orders[0]?.delivery_status.toLowerCase()}}</h6>
                                                <h6 class="mt-1 text-capitalize cursor-pointer text-info"
                                                    *ngIf="order && order['orders'] && order['orders'][0] && order['orders'][0]['delivery_status'] && (!this.user || !this.user['user_type'] || this.user['user_type'] !== 'ADMIN')"
                                                    (click)="changeDeliveryStatus(content, order)">{{order?.orders[0]?.delivery_status.toLowerCase()}}</h6>
                                                <h6 class="mt-1"
                                                    *ngIf="!order || !order['orders'] || !order['orders'][0] || !order['orders'][0]['delivery_status']">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['name']">{{order?.name}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['name']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['contact']">{{order?.contact}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['contact']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['amt']">{{order?.amt}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['amt']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-capitalize"
                                                    *ngIf="order['type']">{{order?.type.toLowerCase()}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['type']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-capitalize"
                                                    [ngClass]="{'text-success': order['status'] === 'SUCCESS', 'text-warning':  order['status'] === 'PENDING', 'text-danger':  order['status'] === 'FAILED' }">{{order?.status.toLowerCase()}}</h6>
                                            </td>
                                            <td class="text-center" style="min-width: 200px; max-width: 200px">
                                                <h6 class="mt-1" *ngIf="order['address_id']">{{order?.address?.address_1_shipping}} {{order?.address?.address_2_shipping}}, {{order?.address?.landmark_shipping}} {{order?.address?.city_shipping}}, {{order?.address?.state_shipping?.name}} - {{order?.address?.pin_code_shipping}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['address_id']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['brn']">{{order?.brn}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['brn']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['stc']">{{order?.stc}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['stc']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['trn']">{{order?.trn}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['trn']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['tet']">{{order?.tet}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['tet']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['pmd']">{{order?.pmd}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['pmd']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['rid']">{{order?.rid}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['rid']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['ver']">{{order?.ver}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['ver']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['cid']">{{order?.cid}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['cid']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['typ']">{{order?.typ}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['typ']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['crn']">{{order?.crn}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['crn']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['cny']">{{order?.cny}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['cny']">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="order['cks']">{{order?.cks}}</h6>
                                                <h6 class="mt-1" *ngIf="!order['cks']">-</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="22" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="orders && orders.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let order of orders; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{order?.name}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{order?.contact}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Transaction Id - {{order?.transaction_id}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Amount - {{order?.amount}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - {{order?.status}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious>
                           <span [data-feather]="'chevron-left'" [size]="18"
                                 [class]="'font-weight-bolder'">
                           </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                           <span [data-feather]="'chevron-right'" [size]="18"
                                 [class]="'font-weight-bolder'">
                           </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>


