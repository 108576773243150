import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../../shared/services/product.service';

@Component({
    selector: 'app-products-action-content',
    templateUrl: './products-action-content.component.html',
    styleUrls: ['./products-action-content.component.scss']
})
export class ProductsActionContentComponent implements OnInit {
    public productActionId: any;
    submitting = false;
    submitted = false;
    contentData: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private activatedRoute: ActivatedRoute,
                private productService: ProductService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.productActionId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.productService.detail(this.productActionId).subscribe(data => {
            this.contentData = (data && data['action']) ? data['action'] : '';
            this.data = data;
        });
    }

    submit() {
        const params = {
            'action': this.contentData ? this.contentData : ''
        };
        this.productService.updateProductAction(this.productActionId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

}
