import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class MetaTagsService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService
    ) {
        super(http, alertService, globalService);
    }

    create(data): Observable<any> {
        //alert(data);
        return this.postRequest(AppUrl.META_TAGS(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.META_TAGS(), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.META_TAGS(id));
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.META_TAGS(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.META_TAGS_STATUS_UPDATE(id), data);
    }

// count(): Observable<any> {
    //     return this.getRequest(AppUrl.BLOGS_COUNT());
    // }
    // updateContent(id, data): Observable<any> {
    //     return this.putRequest(AppUrl.BLOG_CONTENT_UPDATE(id), data);
    // }
    //
    // delete(id): Observable<any> {
    //     return this.deleteRequest(AppUrl.BLOG_DELETE(id));
    // }
    //
    // fileUploadAdmin(params, event): Observable<any> {
    //     return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    // }
}
