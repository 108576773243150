import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {AlertService} from '../../../shared/services/alert.service';
import * as moment from 'moment';
import {StorageService} from '../../../shared/services/storage.service';
import {ConsultService} from '../../../shared/services/consult.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-consults',
    templateUrl: './consults.component.html',
    styleUrls: ['./consults.component.scss']
})
export class ConsultsComponent implements OnInit {
    appointmentData: any;
    consultData: any;
    consultUpdateData: any;
    consults: any;
    id: any;
    data: any;
    createConsultForm: FormGroup;
    videoConferenceForm: FormGroup;
    consultUpdateForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    user: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    currentYear: any = parseInt(moment(new Date()).format('YYYY'));
    currentMonth: any = parseInt(moment(new Date()).format('MM')) - 1;
    currentDate: any = parseInt(moment(new Date()).format('DD'));
    currentHour: any = parseInt(moment(new Date()).format('HH'));
    currentMinute: any = parseInt(moment(new Date()).format('mm'));
    public currentDateTime = new Date(this.currentYear, this.currentMonth, this.currentDate, this.currentHour, this.currentMinute);
    consultModalOpen: any = false;
    videoConferenceModalOpen: any = false;
    consultUpdateModalOpen: any = false;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private appointmentService: AppointmentService,
                private consultService: ConsultService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.createConsultForm = this.fb.group({
            'start_date_time': ['', Validators.required],
            'end_date_time': ['', Validators.required],
            'remarks': ['']
        });
        this.videoConferenceForm = this.fb.group({
            'url': ['', Validators.required]
        });
        this.consultUpdateForm = this.fb.group({
            'status': ['', Validators.required]
        });
        this.getAppointmentdetail(this.id);
    }

    add(content): void {
        this.createConsultForm.patchValue({
            'start_date_time': '',
            'end_date_time': '',
            'remarks': ''
        });
        this.data = undefined;
        this.consultModalOpen = true;
        this.modalService.open(content, {'size': 'lg'});
    }

    getAppointmentdetail(id) {
        this.appointmentService.detail(id).subscribe(data => {
            this.appointmentData = data;
            this.get();
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.consults = [];
        this.consultService.get(this.appointmentData.id, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.consults = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.consults = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        this.get();
    }

    createConsult() {
        this.submitted = true;
        if (!this.createConsultForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'appointment_id': (this.appointmentData && this.appointmentData.id) ? this.appointmentData.id : '',
                'start_date_time': (this.createConsultForm.value && moment(this.createConsultForm.value.start_date_time).format('YYYY-MM-DD hh:mm a')) ? moment(this.createConsultForm.value.start_date_time).format('YYYY-MM-DD hh:mm a') : '',
                'end_date_time': (this.createConsultForm.value && moment(this.createConsultForm.value.end_date_time).format('YYYY-MM-DD hh:mm a')) ? moment(this.createConsultForm.value.end_date_time).format('YYYY-MM-DD hh:mm a') : '',
                'remarks': (this.createConsultForm.value && this.createConsultForm.value.remarks) ? this.createConsultForm.value.remarks : ''
            };
            this.submitting = true;
            this.consultService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.createConsultForm.reset();
                    this.createConsultForm.patchValue({
                        'start_date_time': '',
                        'end_date_time': '',
                        'remarks': ''
                    });
                    this.get();
                    this.modalService.dismissAll('');
                    this.consultModalOpen = false;
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].start_date_time.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].start_date_time[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].end_date_time.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].end_date_time[0]);
                        return;
                    }
                }
            });
        }
    }

    createVideoConference() {
        this.submitted = true;
        if (!this.videoConferenceForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'url': (this.videoConferenceForm.value && this.videoConferenceForm.value.url) ? this.videoConferenceForm.value.url : ''
            };
            this.submitting = true;
            this.consultService.updateVideoUrl(this.consultData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.videoConferenceForm.reset();
                this.videoConferenceForm.patchValue({
                    'url': ''
                });
                this.get();
                this.modalService.dismissAll('');
                this.videoConferenceModalOpen = false;
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    updateConsultStatus() {
        this.submitted = true;
        if (!this.consultUpdateForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'status': (this.consultUpdateForm.value && this.consultUpdateForm.value.status) ? this.consultUpdateForm.value.status : ''
            };
            this.submitting = true;
            this.consultService.updateStatus(this.consultUpdateData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.consultUpdateForm.reset();
                this.consultUpdateForm.patchValue({
                    'status': ''
                });
                this.get();
                this.modalService.dismissAll('');
                this.consultUpdateModalOpen = false;
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    delete(data) {
        this.consultService.delete(this.appointmentData.id, data['id']).subscribe(data => {
            this.get();
        });
    }

    goToDocotorConsult() {
        const doctorId = this.appointmentData['doctor']['id'];
        window.open('doctor/' + doctorId + '/consults', '_blank');
    }

    goToPrescription(data) {
        this.router.navigateByUrl('appointment/' + this.appointmentData.id + '/consult/' + data.id + '/prescriptions');
    }


    downloadPrescriptions(data) {
        const baseUrl = environment.appUrl + 'prescription-pdf/' + data.id;
        window.open(baseUrl, '_blank');
    }

    openVideoModal(content, data) {
        this.videoConferenceForm.patchValue({
            'url': ''
        });
        this.data = undefined;
        this.consultData = data;
        this.videoConferenceModalOpen = true;
        this.modalService.open(content, {'size': 'lg'});
    }

    openStatusModal(content, data) {
        this.consultUpdateForm.patchValue({
            'status': ''
        });
        this.data = undefined;
        this.consultData = undefined;
        this.consultUpdateData = data;
        this.consultModalOpen = false;
        this.videoConferenceModalOpen = false;
        this.consultUpdateModalOpen = true;
        this.modalService.open(content, {'size': 'lg'});
    }

    goToVideoUrl(data) {
        window.open(data['url'], '_blank');
    }

    goToAppointmentDocs() {
        this.router.navigateByUrl('appointment/' + this.id + '/appointment-docs');
    }
}
