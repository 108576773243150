import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductsRoutingModule} from './products-routing.module';
import {AddProductComponent} from './add-product/add-product.component';
import {CoreSidebarModule} from '../../../@core/components';
import {ProductsComponent} from './products.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageCropperModule} from 'ngx-image-cropper';
import { ProductsActionContentComponent } from './products-action-content/products-action-content.component';
import {QuillModule} from 'ngx-quill';
import { KeyIngredientComponent } from './key-ingredient/key-ingredient.component';
import { DosageComponent } from './dosage/dosage.component';


@NgModule({
    declarations: [AddProductComponent, ProductsComponent, ProductsActionContentComponent, KeyIngredientComponent, DosageComponent],
    imports: [
        CommonModule,
        ProductsRoutingModule,
        CoreSidebarModule,
        ReactiveFormsModule,
        CoreDirectivesModule,
        NgbPaginationModule,
        ImageCropperModule,
        QuillModule,
        FormsModule,
    ],
    exports: [AddProductComponent, ProductsComponent, ProductsActionContentComponent ,KeyIngredientComponent, DosageComponent]
})
export class ProductsModule {
}
