import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PosRoutingModule} from './pos-routing.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddOrderComponent} from './add-order/add-order.component';
import {PosComponent} from './pos.component';


@NgModule({
    declarations: [AddOrderComponent,],
    imports: [
        CommonModule,
        PosRoutingModule,
        CoreSidebarModule,
        NgbPaginationModule,
        CoreDirectivesModule,
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [AddOrderComponent]
})
export class PosModule {
}
