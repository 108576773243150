import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {KeyIngredientService} from '../../../shared/services/key-ingredient.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-key-ingredient',
    templateUrl: './key-ingredient.component.html',
    styleUrls: ['./key-ingredient.component.scss']
})
export class KeyIngredientComponent implements OnInit {
    keyIngredientForm: FormGroup;
    keyIngredients: any = [];
    submitting = false;
    submitted = false;
    productKeyIngredientId: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    data: any;
    contentData: any;

    constructor(private fb: FormBuilder,
                private keyIngredientService: KeyIngredientService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.productKeyIngredientId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.keyIngredientForm = this.fb.group({
            'ingredient': ['', Validators.required]
        });
        this.get();
    }

    get() {
        this.submitting = true;
        this.keyIngredients = [];
        this.keyIngredientService.detail(this.productKeyIngredientId).subscribe(data => {
            if (data) {
                this.keyIngredients = data;
            } else {
                this.keyIngredients = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    submit() {
        this.submitted = true;
        if (!this.keyIngredientForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'ingredient': (this.keyIngredientForm && this.keyIngredientForm.value && this.keyIngredientForm.value.ingredient) ? this.keyIngredientForm.value.ingredient : '',
                'about': (this.contentData) ? this.contentData : ''
            };
            this.keyIngredientService.create(this.productKeyIngredientId, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.keyIngredientForm.reset();
                    this.contentData = '';
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }


}
