import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubCategoriesRoutingModule } from './sub-categories-routing.module';
import { AddSubCategoryComponent } from './add-sub-category/add-sub-category.component';
import {SubCategoriesComponent} from './sub-categories.component';
import {CoreSidebarModule} from '../../../@core/components';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [AddSubCategoryComponent,SubCategoriesComponent],
    imports: [
        CommonModule,
        SubCategoriesRoutingModule,
        CoreSidebarModule,
        ReactiveFormsModule,
        CoreDirectivesModule,
        NgbPaginationModule
    ],
  exports:[AddSubCategoryComponent,SubCategoriesComponent]
})
export class SubCategoriesModule { }
