import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SubAdminsComponent} from './sub-admins.component';
import {AddSubAdminComponent} from './add-sub-admin/add-sub-admin.component';

const routes: Routes = [
    {
        path: 'sub-admins',
        component: SubAdminsComponent,
        data: {animation: 'sub-admins'}
    },
    {
        path: 'add-sub-admin',
        component: AddSubAdminComponent,
        data: {animation: 'add-sub-admins'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SubAdminsRoutingModule {
}
