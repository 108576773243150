import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {AdminService} from '../../../shared/services/admin.service';

@Component({
  selector: 'app-add-testimonial',
  templateUrl: './add-testimonial.component.html',
  styleUrls: ['./add-testimonial.component.scss']
})
export class AddTestimonialComponent implements OnChanges {
  addTestimonialForm: FormGroup;
  inputTypePassword: any = 'password';
  submitted: any = false;
  submitting: any = false;
  @Output() valueSubmitted = new EventEmitter<any>();
  @Input() data: any;


  constructor(private _coreSidebarService: CoreSidebarService,
              private fb: FormBuilder,
              private alertService: AlertService,
              private adminService: AdminService) { }

  ngOnChanges(): void {
    this.refresh();
  }
  refresh() {
    this.addTestimonialForm = this.fb.group({
      'name': ['', Validators.required],
      'testimonial': ['', Validators.required],
    });
    if (this.data && this.data['id']) {
      this.patchForm();
    }
  }

  patchForm() {
    this.addTestimonialForm.patchValue({
      'name': (this.data && this.data['name']) ? this.data['name'] : '',
      'testimonial': (this.data && this.data['testimonial']) ? this.data['testimonial'] : '',
    });
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-testimonial').toggleOpen();
  }

  submit() {
    this.submitted = true;
    if (!this.addTestimonialForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addTestimonialForm.value && this.addTestimonialForm.value.name) ? this.addTestimonialForm.value.question : '',
        'testimonial': (this.addTestimonialForm.value && this.addTestimonialForm.value.testimonial) ? this.addTestimonialForm.value.testimonial : '',
      };
      this.submitting = true;
      this.adminService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.valueSubmitted.emit(true);
          this.addTestimonialForm.reset();
          this.addTestimonialForm.patchValue({
            'question': '',
            'testimonial': '',
          });
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].testimonial && error['error']['errors'].testimonial.length > 0) {
            this.alertService.showErrors(error['error']['errors'].testimonial[0]);
            return;
          }
        }
      });
    }
  }

  update() {

  }
}
