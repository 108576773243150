import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SymptomsComponent} from './symptoms.component';
import {AddSymptomComponent} from './add-symptom/add-symptom.component';

const routes: Routes = [
  {
    path: 'symptoms',
    component: SymptomsComponent,
    data: {animation: 'Symptoms'}
  },
  {
    path: 'symptom/add',
    component: AddSymptomComponent,
    data: {animation: 'symptom/add'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SymptomsRoutingModule {

}
