import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionsRoutingModule } from './transactions-routing.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreSidebarModule} from '../../../@core/components';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    CoreSidebarModule
  ]
})
export class TransactionsModule { }
