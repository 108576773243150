import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PosComponent} from './pos.component';
import {AddOrderComponent} from './add-order/add-order.component';

const routes: Routes = [
    {
        path: 'pos',
        component: PosComponent,
        data: {animation: 'pos'}
    },
    {
        path: 'pos/add-order/:id',
        component: AddOrderComponent,
        data: {animation: 'add-order'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PosRoutingModule {
}
