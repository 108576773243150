import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class ConsultService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(id, data): Observable<any> {
        return this.getRequest(AppUrl.GET_CONSULTS(id), data);
    }

    getByDoctor(id, data): Observable<any> {
        return this.getRequest(AppUrl.GET_DOCTOR_CONSULTS(id), data);
    }

    detail(appointmentId, id): Observable<any> {
        return this.getRequest(AppUrl.DETAILS_CONSULTS(appointmentId, id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.CONSULTS(), data);
    }

    updateVideoUrl(id, data): Observable<any> {
        return this.putRequest(AppUrl.UPDATE_VIDEO_URL(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.UPDATE_CONSULT_STATUS(id), data);
    }

    delete(appointment_id, id): Observable<any> {
        return this.deleteRequest(AppUrl.DELETE_CONSULTS(appointment_id, id));
    }
}
