import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubAdminsComponent} from './sub-admins.component';
import {SubAdminsRoutingModule} from './sub-admins-routing.module';
import {CoreSidebarModule} from '../../../@core/components';
import { AddSubAdminComponent } from './add-sub-admin/add-sub-admin.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '../../../@core/directives/directives';

@NgModule({
    declarations: [SubAdminsComponent,AddSubAdminComponent],
    imports: [
        CommonModule,
        SubAdminsRoutingModule,
        CoreSidebarModule,
        ReactiveFormsModule,
        NgbPaginationModule,
        CoreDirectivesModule
    ],
    exports: [SubAdminsComponent,AddSubAdminComponent]
})

export class SubAdminsModule {
}
