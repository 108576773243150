<div class="content-wrapper">
    <div class="content-body">
        <section id="orders">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 *ngIf="modalType === 'CHANGE_DELIVERY_STATUS'" class="modal-title font-weight-bold">Update
                            Delivery Status - {{selectedTransactionData?.invoice_no}}</h4>
                        <h4 *ngIf="modalType === 'CHANGE_TRANSACTION_STATUS'" class="modal-title font-weight-bold">
                            Update Transaction Status - {{selectedTransactionData?.transaction_id}}</h4>
                        <h4 *ngIf="modalType === 'FETCH_DETAILS'" class="modal-title font-weight-bold">Fetch
                            Details</h4>
                        <h4 *ngIf="modalType === 'ADD_USER'" class="modal-title font-weight-bold">Add User</h4>
                        <h4 *ngIf="modalType === 'VIEW_ORDERS'" class="modal-title font-weight-bold">View Orders
                            - {{selectedTransactionData?.invoice_no}}</h4>
                        <button
                                (click)="modalService.dismissAll('')"
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="modalType === 'FETCH_DETAILS'" class="row ptb-15px">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="fetchUserDetailsForm" class="form" novalidate>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="contact_fetch"><i
                                                            class="fas fa-phone-square mr-2px"></i> Contact<span
                                                            class="text-danger">*</span></label>
                                                    <input class="form-control" formControlName="contact"
                                                           id="contact_fetch"
                                                           placeholder="Enter your contact no.">
                                                    <small *ngIf="submitted && fetchUserDetailsForm.get('contact').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                    <small *ngIf="submitted && fetchUserDetailsForm.get('contact').hasError('pattern')"
                                                           class="text-danger">
                                                        Enter a valid contact number.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="modalType === 'CHANGE_DELIVERY_STATUS'" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="updateDeliveryStatusForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="status">Status</label>
                                                    <select class="form-control" formControlName="delivery_status"
                                                            id="status">
                                                        <option value="">Select Status</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="PLACED">Placed</option>
                                                        <option value="DELIVERING">Delivering</option>
                                                        <option value="DELIVERED">Delivered</option>
                                                    </select>
                                                    <small *ngIf="submitted && updateDeliveryStatusForm.get('status').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="modalType === 'CHANGE_TRANSACTION_STATUS'" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="updateTransactionStatusForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="status">Transaction Status</label>
                                                    <select class="form-control" formControlName="status"
                                                            id="transaction_status">
                                                        <option value="">Select Status</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="SUCCESS">Success</option>
                                                        <option value="FAILED">Failed</option>
                                                        <option value="CANCELLED">Cancelled</option>
                                                    </select>
                                                    <small *ngIf="submitted && updateTransactionStatusForm.get('transaction_status').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="modalType === 'ADD_USER'" class="row ptb-20px">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="addUserForm" class="form" novalidate>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name"><i class="fas fa-user-circle mr-2px"></i>
                                                        Name<span class="text-danger">*</span></label>
                                                    <input class="form-control" formControlName="name" id="name"
                                                           placeholder="Enter full name">
                                                    <small *ngIf="submitted && addUserForm.get('name').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="email"><i class="fas fa-address-card mr-2px"></i> Email</label>
                                                    <input class="form-control" formControlName="email" id="email"
                                                           placeholder="Enter your email" type="email">
                                                    <small *ngIf="submitted && addUserForm.get('email').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="modalType === 'VIEW_ORDERS'" class="row ptb-20px">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-bordered p-5">
                                        <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Amount (in INR)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let order of this.selectedTransactionData['orders']; let i = index">
                                            <td>
                                                {{i + 1}}
                                            </td>
                                            <td>
                                                {{order?.product?.product_alias}}
                                            </td>
                                            <td>
                                                {{order?.quantity}}
                                            </td>
                                            <td>
                                                {{order?.total_price}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row footer-width">
                            <div class="col-12">
                                <button (click)="fetchDetail()" *ngIf="modalType === 'FETCH_DETAILS'"
                                        [disabled]="submitting" class="btn btn-success color-white pull-right mtb-5px">
                                    <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Fetch Now
                                </button>
                                <button (click)="back()" *ngIf="modalType === 'ADD_USER'"
                                        [disabled]="submitting" class="btn btn-danger color-white  mtb-5px">
                                    <i *ngIf="!submitting" class="fas fa-arrow-left mr-1"></i>
                                    <i *ngIf="submitting" class="fas fa-arrow-left mr-1"></i>Back
                                </button>
                                <button (click)="addUser()" *ngIf="modalType === 'ADD_USER'"
                                        [disabled]="submitting" class="btn btn-success color-white pull-right mtb-5px">
                                    <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Add User
                                </button>
                                <button (click)="updateStatus()" *ngIf="modalType === 'CHANGE_DELIVERY_STATUS'"
                                        [disabled]="submitting" class="btn btn-success color-white pull-right">
                                    <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Update
                                </button>
                                <button (click)="updateTransactionStatus()"
                                        *ngIf="modalType === 'CHANGE_TRANSACTION_STATUS'"
                                        [disabled]="submitting"
                                        class="btn btn-success color-white pull-right">
                                    <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">POS</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="posFetchUser(content)"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-5px">
                        <i class="fas fa-user-circle mr-8px"></i>&nbsp;Add / Fetch User
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="transaction_id"
                                           placeholder="Transaction Id"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="invoice_no" placeholder="Invoice No."
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="name" placeholder="Name"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="contact" placeholder="Contact"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="status">
                                        <option value="">Select Status</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="SUCCESS">Success</option>
                                        <option value="FAILED">Failed</option>
                                        <option value="CANCELLED">Cancelled</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Transaction Id</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Invoice No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Delivery Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Address Shipping</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Address Billing</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Amount (in INR)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Delivery Date Time</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Type</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <!--                                        <pre class="text-white">{{transactions[0]|json}}</pre>-->
                                        <tbody *ngIf="transactions && transactions.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let transaction of transactions; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">
                                                    {{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">
<!--                                                    <i (click)="deleteTransaction(order)"-->
<!--                                                       class="fas fa-trash f_17 text-danger cursor-pointer"></i>-->
                                                    <i (click)="viewOrders(content, transaction)"
                                                       class="fas fa-cart-arrow-down f_17 text-info ml-10px cursor-pointer"
                                                       title="View Orders"></i>
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="transaction['transaction_id']"
                                                    class="mt-1">{{transaction?.transaction_id}}</h6>
                                                <h6 *ngIf="!transaction['transaction_id']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 (click)="openExternalUrl('product-invoice-pdf/'+transaction?.transaction_id)"
                                                    *ngIf="transaction['invoice_no']"
                                                    class="mt-1 text-info cursor-pointer">{{transaction?.invoice_no}}</h6>
                                                <h6 *ngIf="!transaction['invoice_no']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 (click)="updateDeliveryStatus(content, transaction)"
                                                    *ngIf="transaction['orders'] && transaction['orders'].length > 0"
                                                    class="mt-1 text-capitalize text-info cursor-pointer">{{transaction['orders'][0]['delivery_status'].toLowerCase()}}</h6>
                                                <h6 *ngIf="!transaction['orders'] || transaction['orders'].length === 0"
                                                    class="mt-1 text-capitalize text-info">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="transaction['name']" class="mt-1">{{transaction?.name}}</h6>
                                                <h6 *ngIf="!transaction['name']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="transaction['contact']"
                                                    class="mt-1">{{transaction?.contact}}</h6>
                                                <h6 *ngIf="!transaction['contact']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center"
                                                style="min-width: 200px; max-width: 200px; text-transform: capitalize">
                                                <h6 *ngIf="transaction['address_id']"
                                                    class="mt-1">{{transaction?.address?.address_1_shipping}} {{transaction?.address?.address_2_shipping}}
                                                    , {{transaction?.address?.landmark_shipping}}
                                                    , {{transaction?.address?.city_shipping}}
                                                    , {{transaction?.address?.state_shipping?.name}}
                                                    - {{transaction?.address?.pin_code_shipping}}</h6>
                                                <h6 *ngIf="!transaction['address_id']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center"
                                                style="min-width: 200px; max-width: 200px; text-transform: capitalize">
                                                <h6 *ngIf="transaction['address_id']"
                                                    class="mt-1">{{transaction?.address?.address_1_billing}} {{transaction?.address?.address_2_billing}}
                                                    , {{transaction?.address?.landmark_billing}}
                                                    , {{transaction?.address?.city_billing}}
                                                    , {{transaction?.address?.state_billing?.name}}
                                                    - {{transaction?.address?.pin_code_billing}}</h6>
                                                <h6 *ngIf="!transaction['address_id']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="transaction['amt']" class="mt-1">{{transaction?.amt}}</h6>
                                                <h6 *ngIf="!transaction['amt']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="transaction['orders'] && transaction['orders'].length > 0 && transaction['orders'][0]['delivering_date_time'] !== null; else elseBlock "
                                                    class="mt-1 text-capitalize">{{transaction['orders'][0]['delivering_date_time'].toLowerCase()}}</h6>
                                                <ng-template #elseBlock>
                                                    <h6 class="mt-1 text-capitalize">
                                                        -
                                                    </h6>
                                                </ng-template>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="transaction['type']"
                                                    class="mt-1 text-capitalize">{{transaction?.type.toLowerCase()}}</h6>
                                                <h6 *ngIf="!transaction['type']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 (click)="changeTransactionStatus(content, transaction)"
                                                    *ngIf="transaction['status']"
                                                    class="mt-1 text-capitalize text-info cursor-pointer">
                                                    {{transaction?.status.toLowerCase()}}
                                                </h6>
                                                <h6 *ngIf="!transaction['status']" class="mt-1">-</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="12">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="transactions && transactions.length > 0; else noDataToShowMobile"
                 class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let transaction of transactions; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{transaction?.name}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{transaction?.contact}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Transaction Id - {{transaction?.transaction_id}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Amount - {{transaction?.amount}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - {{transaction?.status}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                [boundaryLinks]="true" [collectionSize]="pagination.total"
                                [maxSize]="5" [pageSize]="pagination.perpage"
                                class="pagination-primary">
                    <ng-template ngbPaginationPrevious>
                           <span [class]="'font-weight-bolder'" [data-feather]="'chevron-left'"
                                 [size]="18">
                           </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                           <span [class]="'font-weight-bolder'" [data-feather]="'chevron-right'"
                                 [size]="18">
                           </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>



