import {Component, OnInit} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {AdminService} from '../../shared/services/admin.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-symptoms',
    templateUrl: './symptoms.component.html',
    styleUrls: ['./symptoms.component.scss']
})
export class SymptomsComponent implements OnInit {

    symptoms = [];
    showSearch = false;
    searchForm: FormGroup;
    data: any;
    submitted: any = false;
    submitting: any = false;
    updatePasswordForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    sysdetails: any[] = [
        {
            sno: 1,
            name: 'Dr.Axico',
            action: ''
        },
        {
            sno: 2,
            name: 'Dr.Axico',
            action: ''
        },
        {
            sno: 3,
            name: 'Dr.Axico',
            action: ''
        }
    ];

    constructor(private _coreSidebarService: CoreSidebarService,
                private adminService: AdminService,
                 private router:Router,
                private fb: FormBuilder,
                 private modelService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }
    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
        });
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-symptom').toggleOpen();
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.symptoms = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.symptoms = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.symptoms = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
        };
        this.get(params);
    }
    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
        };
        this.get(params);
    }
}
