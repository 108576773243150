import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DoctorsRoutingModule } from './doctors-routing.module';
import { DoctorsComponent } from './doctors.component';
import { AddDoctorComponent } from './add-doctor/add-doctor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import { DoctorConsultsComponent } from './doctor-consults/doctor-consults.component';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import { ContentInfoComponent } from './content-info/content-info.component';
import {QuillModule} from 'ngx-quill';


@NgModule({
  declarations: [DoctorsComponent, AddDoctorComponent, DoctorConsultsComponent, ContentInfoComponent],
    imports: [
        CommonModule,
        DoctorsRoutingModule,
        ReactiveFormsModule,
        NgbPaginationModule,
        CoreSidebarModule,
        CoreDirectivesModule,
        OwlDateTimeModule,
        QuillModule,
        FormsModule
    ]
})
export class DoctorsModule { }
