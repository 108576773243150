import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrdersRoutingModule } from './orders-routing.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {ReactiveFormsModule} from '@angular/forms';
import {OrdersComponent} from './orders.component';


@NgModule({
  declarations: [OrdersComponent],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    CoreSidebarModule,
    NgbPaginationModule,
    CoreDirectivesModule,
    ReactiveFormsModule
  ]
})
export class OrdersModule { }
