import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {CoreConfigService} from '../../../@core/services/config.service';
import {StateService} from '../../shared/services/state.service';
import {repeaterAnimation} from 'app/main/animations/form-repeater.animation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [repeaterAnimation]
})
export class DashboardComponent implements OnInit, AfterViewInit {
    user: any;
    states: any = [];
    data: any;
    id: any;
    studentSessionForm:FormGroup;
    admissionLink: any;
    public coreConfig: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    details:any[]=[
        {
            admissionDone: '1M',
            reRegistrationDone:'100K',
            lateralEntryDone:'500K',
            creditTransferDone:'150K'
        }
    ]

    constructor(public _coreConfigService: CoreConfigService,
                public stateService: StateService,
                public modalService: NgbModal,
                ) {
        this.user = StorageService.getItem('self');
    }


    ngOnInit(): void {
        this.user = StorageService.getItem('self');
    }


    ngAfterViewInit(): void {
        this.refresh();
    }
    navigateTo(url) {
        window.open(url, 'blank');
    }
    refresh() {
        this.getStates();
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }
   /* getCreditTransferCount(data) {
        this.studentService.creditTransferCount(data).subscribe(data => {
            this.creditTransferDone = data;
        }, error => {
            this.creditTransferDone = 0;
        });
    }*/
    copyMessage() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.admissionLink;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        // this.alertService.showSuccess({'title': 'success', 'message': 'Link Copied successfully'});
    }
}
