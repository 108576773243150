import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.BLOGS(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.BLOGS(), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.BLOGS(id));
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.BLOGS_COUNT());
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOGS(id), data);
    }

    updateEnglishBanner(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOG_ENGLISH_BANNER_UPDATE(id), data);
    }

    updateBlogCover(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOG_COVER_UPDATE(id), data);
    }

    updateBlogHindiBanner(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOG_HINDI_BANNER_UPDATE(id), data);
    }

    updateBlogHindiCover(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOG_HINDI_COVER_UPDATE(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOG_STATUS_UPDATE(id), data);
    }

    updateContent(id, data): Observable<any> {
        return this.putRequest(AppUrl.BLOG_CONTENT_UPDATE(id), data);
    }

    delete(id): Observable<any> {
        return this.deleteRequest(AppUrl.BLOG_DELETE(id));
    }

    fileUploadAdmin(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    create_hindi(data): Observable<any> {
        return this.postRequest(AppUrl.HINDI_BLOGS(), data);
    }

    get_hindi(data): Observable<any> {
        return this.getRequest(AppUrl.HINDI_BLOGS(), data);
    }

    detail_hindi(id): Observable<any> {
        return this.getRequest(AppUrl.HINDI_BLOGS(id));
    }

    count_hindi(): Observable<any> {
        return this.getRequest(AppUrl.HINDI_BLOGS_COUNT());
    }

    update_hindi(id, data): Observable<any> {
        return this.putRequest(AppUrl.HINDI_BLOGS(id), data);
    }

    updateStatus_hindi(id, data): Observable<any> {
        return this.putRequest(AppUrl.HINDI_BLOG_STATUS_UPDATE(id), data);
    }

    updateContent_hindi(id, data): Observable<any> {
        return this.putRequest(AppUrl.HINDI_BLOG_CONTENT_UPDATE(id), data);
    }

    delete_hindi(id): Observable<any> {
        return this.deleteRequest(AppUrl.HINDI_BLOG_DELETE(id));
    }
}
