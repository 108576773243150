import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.PRODUCTS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.PRODUCTS_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.PRODUCTS(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.PRODUCTS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.PRODUCTS(id), data);
    }

    updateProductAction(id, data): Observable<any> {
        return this.putRequest(AppUrl.PRODUCT_ACTION(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.PRODUCT_STATUS(id), data);
    }
    updateStockStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.PRODUCT_STOCK_STATUS(id), data);
    }

    addProductImage(data): Observable<any> {
        return this.postRequest(AppUrl.ADD_PRODUCT_IMAGE(), data);
    }

    deleteProductImage(id): Observable<any> {
        return this.deleteRequest(AppUrl.DELETE_PRODUCT_IMAGE(id));
    }
}
