import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class TransactionService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.TRANSACTIONS(), data);
    }

    getByPos(data): Observable<any> {
        return this.getRequest(AppUrl.TRANSACTIONS_BY_POS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.TRANSACTIONS_COUNT());
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.TRANSACTIONS(), data);
    }

    createByPos(id, data): Observable<any> {
        return this.postRequest(AppUrl.TRANSACTIONS_BY_POS(id), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.TRANSACTIONS(id), data);
    }

    transactionStatusByPos(id, data): Observable<any> {
        return this.putRequest(AppUrl.TRANSACTION_STATUS_BY_POS(id), data);
    }

    delete(id): Observable<any> {
        return this.deleteRequest(AppUrl.TRANSACTIONS(id));
    }
}
