import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentService} from '../../shared/services/appointment.service';
import {DoctorService} from '../../shared/services/doctor.service';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
    showSearch = false;
    appointments = [];
    id: any;
    data: any;
    createAppointmentForm: FormGroup;
    appointmentStatusUpdateForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    doctors: any = [];
    user: any;
    selectedDoctor: any;
    appointmentStatusModalOpen: any = false;
    appointmentCreateModalOpen: any = false;
    appointmentData: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    currentYear: any = parseInt(moment(new Date()).format('YYYY'));
    currentMonth: any = parseInt(moment(new Date()).format('MM')) - 1;
    currentDate: any = parseInt(moment(new Date()).format('DD'));
    currentHour: any = parseInt(moment(new Date()).format('HH'));
    currentMinute: any = parseInt(moment(new Date()).format('mm'));
    public currentDateTime = new Date(this.currentYear, this.currentMonth, this.currentDate, this.currentHour, this.currentMinute);

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private appointmentService: AppointmentService,
                private doctorService: DoctorService,
                private alertService: AlertService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
        this.getDoctors();
    }

    getDoctors() {
        this.doctors = [];
        this.doctorService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.doctors = data['data'];
            } else {
                this.doctors = [];
            }
        });
    }

    refresh() {
        this.createAppointmentForm = this.fb.group({
            'name': ['', Validators.required],
            'contact': ['', Validators.required],
            'gender': ['', Validators.required],
            'age': ['', Validators.required],
            'mode': ['', Validators.required],
            'amount': ['', Validators.required],
            'symptoms': ['', Validators.required],
            'doctor_id': ['', Validators.required],
            'start_date_time': [''],
            'end_date_time': [''],
            'remarks': ['']
        });
        this.appointmentStatusUpdateForm = this.fb.group({
            'status': ['', Validators.required]
        });
        this.searchForm = this.fb.group({
            'last_consult_date': [''],
            'appointment_id': [''],
            'doctor_id': [''],
            'contact': [''],
            'status': ['']
        });
        this.get();
    }

    add(content): void {
        this.createAppointmentForm.patchValue({
            'name': '',
            'contact': '',
            'gender': '',
            'age': '',
            'symptoms': '',
            'mode': '',
            'amount': '',
            'doctor_id': '',
            'start_date_time': '',
            'end_date_time': '',
            'remarks': ''
        });
        this.appointmentStatusModalOpen = false;
        this.appointmentCreateModalOpen = true;
        this.data = undefined;
        this.selectedDoctor = undefined;
        this.modalService.open(content, {'size': 'lg'});
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.appointments = [];
        this.appointmentService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.appointments = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.appointments = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'last_consult_date': (this.searchForm.value && this.searchForm.value.last_consult_date) ? this.searchForm.value.last_consult_date : '',
            'appointment_id': (this.searchForm.value && this.searchForm.value.appointment_id) ? this.searchForm.value.appointment_id : '',
            'doctor_id': (this.searchForm.value && this.searchForm.value.doctor_id) ? this.searchForm.value.doctor_id : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : '',
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'last_consult_date': (this.searchForm.value && this.searchForm.value.last_consult_date) ? this.searchForm.value.last_consult_date : '',
            'appointment_id': (this.searchForm.value && this.searchForm.value.appointment_id) ? this.searchForm.value.appointment_id : '',
            'doctor_id': (this.searchForm.value && this.searchForm.value.doctor_id) ? this.searchForm.value.doctor_id : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : ''
        };
        this.get(params);
    }

    edit(content, data) {
        this.data = data;
        if (this.data) {
            this.createAppointmentForm.patchValue({
                'name': (this.data && this.data['name']) ? this.data['name'] : '',
                'contact': (this.data && this.data['contact']) ? this.data['contact'] : '',
                'gender': (this.data && this.data['gender']) ? this.data['gender'] : '',
                'age': (this.data && this.data['age']) ? this.data['age'] : '',
                'symptoms': (this.data && this.data['symptoms']) ? this.data['symptoms'] : '',
                'mode': (this.data && this.data['mode']) ? this.data['mode'] : '',
                'amount': (this.data && this.data['amount']) ? this.data['amount'] : '',
                'doctor_id': (this.data && this.data['doctor_id']) ? this.data['doctor_id'] : ''
            });
            this.appointmentData = undefined;
            this.appointmentCreateModalOpen = true;
            this.appointmentStatusModalOpen = false;
            this.modalService.open(content, {'size': 'lg'});
        }
    }

    createAppointment() {
        this.submitted = true;
        if (!this.createAppointmentForm.valid) {
            return;
        }
        if (!this.createAppointmentForm || !this.createAppointmentForm.value || !this.createAppointmentForm.value.start_date_time) {
            this.alertService.showErrors('Start Date Time field is required');
            return;
        } else if (!this.createAppointmentForm || !this.createAppointmentForm.value || !this.createAppointmentForm.value.end_date_time) {
            this.alertService.showErrors('End Date Time field is required');
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.createAppointmentForm.value && this.createAppointmentForm.value.name) ? this.createAppointmentForm.value.name : '',
                'contact': (this.createAppointmentForm.value && this.createAppointmentForm.value.contact) ? this.createAppointmentForm.value.contact : '',
                'gender': (this.createAppointmentForm.value && this.createAppointmentForm.value.gender) ? this.createAppointmentForm.value.gender : '',
                'age': (this.createAppointmentForm.value && this.createAppointmentForm.value.age) ? this.createAppointmentForm.value.age : '',
                'symptoms': (this.createAppointmentForm.value && this.createAppointmentForm.value.symptoms) ? this.createAppointmentForm.value.symptoms : '',
                'mode': (this.createAppointmentForm.value && this.createAppointmentForm.value.mode) ? this.createAppointmentForm.value.mode : '',
                'amount': (this.createAppointmentForm.value && this.createAppointmentForm.value.amount) ? this.createAppointmentForm.value.amount : '',
                'doctor_id': (this.createAppointmentForm.value && this.createAppointmentForm.value.doctor_id) ? this.createAppointmentForm.value.doctor_id : '',
                'start_date_time': (this.createAppointmentForm.value && moment(this.createAppointmentForm.value.start_date_time).format('YYYY-MM-DD hh:mm a')) ? moment(this.createAppointmentForm.value.start_date_time).format('YYYY-MM-DD hh:mm a') : '',
                'end_date_time': (this.createAppointmentForm.value && moment(this.createAppointmentForm.value.end_date_time).format('YYYY-MM-DD hh:mm a')) ? moment(this.createAppointmentForm.value.end_date_time).format('YYYY-MM-DD hh:mm a') : '',
                'remarks': (this.createAppointmentForm.value && this.createAppointmentForm.value.remarks) ? this.createAppointmentForm.value.remarks : ''
            };
            this.submitting = true;
            this.appointmentService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.createAppointmentForm.reset();
                    this.createAppointmentForm.patchValue({
                        'name': '',
                        'contact': '',
                        'gender': '',
                        'age': '',
                        'symptoms': '',
                        'mode': '',
                        'amount': '',
                        'doctor_id': '',
                        'start_date_time': '',
                        'end_date_time': '',
                        'remarks': '',
                    });
                    this.get();
                    this.modalService.dismissAll('');
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gender[0]);
                        return;
                    }
                    if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].age[0]);
                        return;
                    }
                    if (error['error']['errors'].symptoms && error['error']['errors'].symptoms.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].symptoms[0]);
                        return;
                    }
                    if (error['error']['errors'].mode && error['error']['errors'].mode.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].mode[0]);
                        return;
                    }
                    if (error['error']['errors'].amount && error['error']['errors'].amount.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].amount[0]);
                        return;
                    }
                    if (error['error']['errors'].doctor_id && error['error']['errors'].doctor_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].doctor_id[0]);
                        return;
                    }
                    if (error['error']['errors'].start_date_time && error['error']['errors'].start_date_time.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].start_date_time[0]);
                        return;
                    }
                    if (error['error']['errors'].end_date_time && error['error']['errors'].end_date_time.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].end_date_time[0]);
                        return;
                    }
                }
            });
        }
    }

    updateAppointment() {
        this.submitted = true;
        if (!this.createAppointmentForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.createAppointmentForm.value && this.createAppointmentForm.value.name) ? this.createAppointmentForm.value.name : '',
                'contact': (this.createAppointmentForm.value && this.createAppointmentForm.value.contact) ? this.createAppointmentForm.value.contact : '',
                'gender': (this.createAppointmentForm.value && this.createAppointmentForm.value.gender) ? this.createAppointmentForm.value.gender : '',
                'age': (this.createAppointmentForm.value && this.createAppointmentForm.value.age) ? this.createAppointmentForm.value.age : '',
                'symptoms': (this.createAppointmentForm.value && this.createAppointmentForm.value.symptoms) ? this.createAppointmentForm.value.symptoms : '',
                'mode': (this.createAppointmentForm.value && this.createAppointmentForm.value.mode) ? this.createAppointmentForm.value.mode : '',
                'amount': (this.createAppointmentForm.value && this.createAppointmentForm.value.amount) ? this.createAppointmentForm.value.amount : '',
                'doctor_id': (this.createAppointmentForm.value && this.createAppointmentForm.value.doctor_id) ? this.createAppointmentForm.value.doctor_id : ''
            };
            this.submitting = true;
            this.appointmentService.update(this.data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.data = undefined;
                this.createAppointmentForm.reset();
                this.createAppointmentForm.patchValue({
                    'name': '',
                    'contact': '',
                    'gender': '',
                    'age': '',
                    'symptoms': '',
                    'mode': '',
                    'amount': '',
                    'doctor_id': ''
                });
                this.get();
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gender[0]);
                        return;
                    }
                    if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].age[0]);
                        return;
                    }
                    if (error['error']['errors'].symptoms && error['error']['errors'].symptoms.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].symptoms[0]);
                        return;
                    }
                    if (error['error']['errors'].mode && error['error']['errors'].mode.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].mode[0]);
                        return;
                    }
                    if (error['error']['errors'].amount && error['error']['errors'].amount.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].amount[0]);
                        return;
                    }
                    if (error['error']['errors'].doctor_id && error['error']['errors'].doctor_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].doctor_id[0]);
                        return;
                    }
                }
            });
        }
    }

    updateAppointmentStatus() {
        this.submitted = true;
        if (!this.appointmentStatusUpdateForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'status': (this.appointmentStatusUpdateForm.value && this.appointmentStatusUpdateForm.value.status) ? this.appointmentStatusUpdateForm.value.status : ''
            };
            this.submitting = true;
            this.appointmentService.updateStatus(this.appointmentData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.appointmentData = undefined;
                this.appointmentStatusUpdateForm.reset();
                this.appointmentStatusUpdateForm.patchValue({
                    'status': ''
                });
                this.get();
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    getDoctorData(id) {
        if (id && this.doctors.length > 0) {
            let selectedDoctor = {};
            this.doctors.forEach(doctor => {
                if (doctor && (parseInt(doctor['id']) === parseInt(id))) {
                    selectedDoctor = doctor;
                }
            });
            this.selectedDoctor = selectedDoctor;
        }
    }

    goTo(appointment) {
        this.router.navigateByUrl('appointment/' + appointment.id + '/consults');
    }

    goToDocotorConsult() {
        let doctorId = this.selectedDoctor['id'];
        window.open('doctor/' + doctorId + '/consults', '_blank');
    }

    openStatusModal(content, data) {
        this.appointmentData = data;
        if (this.appointmentData) {
            this.appointmentStatusUpdateForm.patchValue({
                'status': (this.appointmentData && this.appointmentData['status']) ? this.appointmentData['status'] : ''
            });
            this.data = undefined;
            // this.appointmentData = data;
            this.appointmentStatusModalOpen = true;
            this.appointmentCreateModalOpen = false;
            this.modalService.open(content, {'size': 'lg'});
        }
    }

    downloadInvoice(data) {
        let baseUrl = environment.appUrl + 'payment-receipt-pdf/' + data.id;
        window.open(baseUrl, '_blank');
    }
}
