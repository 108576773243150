<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Product</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Product</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addProductForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input class="form-control" id="name" type="text" formControlName="name">
                                        <small *ngIf="submitted && addProductForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="product_alias">Product Alias</label>
                                        <input class="form-control" id="product_alias" type="text" formControlName="product_alias">
                                        <small *ngIf="submitted && addProductForm.get('form-control').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="product_code">Product Code</label>
                                        <input class="form-control" id="product_code" type="text" formControlName="product_code">
                                        <small *ngIf="submitted && addProductForm.get('product_code').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="tagline">Tagline</label>
                                        <input class="form-control" id="tagline" type="text"
                                               formControlName="tagline">
                                        <small *ngIf="submitted && addProductForm.get('tagline').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="actual_price">Actual Price</label>
                                        <input class="form-control" id="actual_price" type="number"
                                               formControlName="actual_price">
                                        <small *ngIf="submitted && addProductForm.get('actual_price').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="discounted_price">Discount Price</label>
                                        <input class="form-control" id="discounted_price" type="number"
                                               formControlName="discounted_price">
                                        <small *ngIf="submitted && addProductForm.get('discounted_price').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="category_id">Category</label>
                                        <select class="form-control" formControlName="category_id" id="category_id">
                                            <option value="">Select Category</option>
                                            <option [value]="category.id"
                                                    *ngFor="let category of categories">{{category?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addProductForm.get('category_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="sub_category_id">Sub Category</label>
                                        <select class="form-control" formControlName="sub_category_id"
                                                id="sub_category_id">
                                            <option value="">Select Sub Category</option>
                                            <option [value]="subCategory.id"
                                                    *ngFor="let subCategory of subCategories">{{subCategory?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addProductForm.get('sub_category_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="description">Description</label>
                                        <textarea class="form-control resize-none" id="description" type="text"
                                                  formControlName="description"></textarea>
                                        <small *ngIf="submitted && addProductForm.get('description').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addProductForm.controls['description'].errors && addProductForm.controls['description'].errors.pattern"
                                               class="text-danger">Invalid description format</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="indication">Indication</label>
                                        <textarea class="form-control resize-none" id="indication" type="text"
                                                  formControlName="indication"></textarea>
                                        <small *ngIf="submitted && addProductForm.get('indication').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addProductForm.controls['indication'].errors && addProductForm.controls['indication'].errors.pattern"
                                               class="text-danger">Invalid indication format</small>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="action">Action</label>
                                        <textarea class="form-control resize-none" id="action" type="text"
                                                  formControlName="action"></textarea>
                                        <small *ngIf="submitted && addProductForm.get('action').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addProductForm.controls['action'].errors && addProductForm.controls['action'].errors.pattern"
                                               class="text-danger">Invalid action format</small>
                                    </div>
                                </div>
                            </div>-->
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="disclaimer">Disclaimer</label>
                                        <textarea class="form-control resize-none" id="disclaimer" type="text"
                                                  formControlName="disclaimer"></textarea>
                                        <small *ngIf="submitted && addProductForm.get('disclaimer').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addProductForm.controls['disclaimer'].errors && addProductForm.controls['disclaimer'].errors.pattern"
                                               class="text-danger">Invalid disclaimer format</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contraindications">Contraindications</label>
                                        <textarea class="form-control resize-none" id="contraindications" type="text"
                                                  formControlName="contraindications"></textarea>
                                        <small *ngIf="submitted && addProductForm.get('contraindications').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addProductForm.controls['contraindications'].errors && addProductForm.controls['contraindications'].errors.pattern"
                                               class="text-danger">Invalid contraindications format</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="manufacturer_details">Manufacturer Details</label>
                                        <textarea class="form-control resize-none" id="manufacturer_details" type="text"
                                                  formControlName="manufacturer_details">
                                        </textarea>
                                        <small *ngIf="submitted && addProductForm.get('manufacturer_details').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="rating">Rating</label>
                                        <input class="form-control" id="rating" type="text" formControlName="rating">
                                        <small *ngIf="submitted && addProductForm.get('rating').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="rating">Url</label>
                                        <input class="form-control" id="url" type="text" formControlName="url">
                                        <small *ngIf="submitted && addProductForm.get('url').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="country_id">Country</label>
                                        <select class="form-control" formControlName="country_id" id="country_id">
                                            <option value="">Select Country</option>
                                            <option [value]="country.id"
                                                    *ngFor="let country of countries">{{country?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addProductForm.get('country_id').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="hsn">HSN</label>
                                        <input class="form-control" id="hsn" type="number"
                                               formControlName="hsn">
                                        <small *ngIf="submitted && addProductForm.get('hsn').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="cgst">CGST</label>
                                        <input class="form-control" id="cgst" type="number"
                                               formControlName="cgst">
                                        <small *ngIf="submitted && addProductForm.get('cgst').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="cgst">SGST</label>
                                        <input class="form-control" id="sgst" type="number"
                                               formControlName="sgst">
                                        <small *ngIf="submitted && addProductForm.get('sgst').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="cgst">IGST</label>
                                        <input class="form-control" id="igst" type="number"
                                               formControlName="igst">
                                        <small *ngIf="submitted && addProductForm.get('igst').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="cgst">UGST</label>
                                        <input class="form-control" id="ugst" type="number"
                                               formControlName="ugst">
                                        <small *ngIf="submitted && addProductForm.get('ugst').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button type="reset" rippleEffect class="btn btn-danger" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
