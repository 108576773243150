import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DosageService} from '../../../shared/services/dosage.service';

@Component({
    selector: 'app-dosage',
    templateUrl: './dosage.component.html',
    styleUrls: ['./dosage.component.scss']
})
export class DosageComponent implements OnInit {
    public productDosageId: any;
    submitting = false;
    submitted = false;
    productData: any;
    geriatricData: any;
    adultData: any;
    childData: any;

    constructor(private activatedRoute: ActivatedRoute,
                private dosageService: DosageService) {
    }

    ngOnInit(): void {
        this.productDosageId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.detail();
    }

    detail() {
        this.dosageService.detail(this.productDosageId).subscribe(data => {
            this.productData = data;
            this.childData = (data && data['child_dosage']) ? data['child_dosage'] :  '';
            this.adultData = (data && data['adult_dosage']) ? data['adult_dosage'] :  '';
            this.geriatricData = (data && data['geriatric_dosage']) ? data['geriatric_dosage'] :  '';
        });
    }

    submit() {
        const params = {
            'child_dosage': this.childData ? this.childData : '',
            'adult_dosage': this.adultData ? this.adultData : '',
            'geriatric_dosage': this.geriatricData ? this.geriatricData : ''
        };
        this.dosageService.create(this.productDosageId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }
}
