import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {CountryService} from '../../shared/services/country.service';
import {CategoryService} from '../../shared/services/category.service';
import {SubCategoryService} from '../../shared/services/sub-category.service';
import {ProductService} from '../../shared/services/product.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FileService} from '../../shared/services/file.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
    showSearch = false;
    data: any;
    products = [];
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    stockStatusForm: FormGroup;
    id: any;
    viewDescription: any;
    viewIndication: any;
    viewAction: any;
    viewDisclaimer: any;
    viewContraindications: any;
    viewImages: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    countries: any = [];
    categories: any = [];
    subCategories: any = [];
    croppedImage: any;
    croppedImageEvent: any;
    imageChangedEvent: any;
    profileImageUploadArea: any = 'assets/images/image-backgrounds/500-300.png';
    user: any;

    constructor(public modalService: NgbModal,
                private router: Router,
                private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private productService: ProductService,
                private categoryService: CategoryService,
                private subCategoryService: SubCategoryService,
                private fileService: FileService,
                private countryservice: CountryService) {
    }

    ngOnInit(): void {
        this.getCategories();
        this.getSubCategories();
        this.refresh();
    }

    refresh() {
        this.user = StorageService.getItem('self');
        this.searchForm = this.fb.group({
            'name': [''],
            'actual_price': [''],
            'discounted_price': [''],
            'category': [''],
            'sub_category': ['']
        });
        this.stockStatusForm = this.fb.group(
            {
                'stock_status': ['']
            }
        );
        this.getCountries();
    }

    getCategories() {
        this.categoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.categories = data['data'];
                this.refresh();
            } else {
                this.categories = [];
            }
        });
    }

    getSubCategories() {
        this.subCategoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.subCategories = data['data'];
            } else {
                this.subCategories = [];
            }
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-product').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getCountries() {
        this.countryservice.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.countries = data['data'];
                this.get();
            } else {
                this.countries = [];
            }
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.products = [];
        this.productService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.products = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.products = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'actual_price': (this.searchForm && this.searchForm.value && this.searchForm.value.actual_price) ? this.searchForm.value.actual_price : '',
            'discounted_price': (this.searchForm && this.searchForm.value && this.searchForm.value.discounted_price) ? this.searchForm.value.discounted_price : '',
            'category': (this.searchForm && this.searchForm.value && this.searchForm.value.category) ? this.searchForm.value.category : '',
            'sub_category': (this.searchForm && this.searchForm.value && this.searchForm.value.sub_category) ? this.searchForm.value.sub_category : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'actual_price': (this.searchForm && this.searchForm.value && this.searchForm.value.actual_price) ? this.searchForm.value.actual_price : '',
            'discounted_price': (this.searchForm && this.searchForm.value && this.searchForm.value.discounted_price) ? this.searchForm.value.discounted_price : '',
            'category': (this.searchForm && this.searchForm.value && this.searchForm.value.category) ? this.searchForm.value.category : '',
            'sub_category': (this.searchForm && this.searchForm.value && this.searchForm.value.sub_category) ? this.searchForm.value.sub_category : ''
        };
        this.get(params);
    }

    viewDescriptionModal(content, data) {
        this.viewIndication = undefined;
        this.viewAction = undefined;
        this.viewDisclaimer = undefined;
        this.viewContraindications = undefined;
        this.viewImages = undefined;
        this.viewDescription = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    viewIndicationModal(content, data) {
        this.viewDescription = undefined;
        this.viewAction = undefined;
        this.viewDisclaimer = undefined;
        this.viewContraindications = undefined;
        this.viewImages = undefined;
        this.viewIndication = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    viewActionModel(content, data) {
        this.viewDescription = undefined;
        this.viewIndication = undefined;
        this.viewDisclaimer = undefined;
        this.viewContraindications = undefined;
        this.viewImages = undefined;
        this.viewAction = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    viewDisclaimerModel(content, data) {
        this.viewDescription = undefined;
        this.viewIndication = undefined;
        this.viewAction = undefined;
        this.viewContraindications = undefined;
        this.viewImages = undefined;
        this.viewDisclaimer = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    viewContraindicationsModel(content, data) {
        this.viewDescription = undefined;
        this.viewIndication = undefined;
        this.viewAction = undefined;
        this.viewContraindications = undefined;
        this.viewImages = undefined;
        this.viewDisclaimer = data;
        this.modalService.open(content, {'size': 'sm'});
    }

    viewImagesModel(content, data) {
        this.viewDescription = undefined;
        this.viewIndication = undefined;
        this.viewAction = undefined;
        this.viewDisclaimer = undefined;
        this.viewContraindications = undefined;
        this.viewImages = data;
        this.modalService.open(content, {'size': 'lg'});
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.productService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    onImageChange(e) {
        this.imageChangedEvent = e;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageEvent = event;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    saveProductImage() {
        const params = {
            'type': 'PRODUCT_IMAGE',
            'product_id': (this.viewImages && this.viewImages.id ? this.viewImages.id : ''),
            'user_id': (this.user && this.user['self'] && this.user['self']['userId']) ? this.user['self']['userId'] : '',
            'fileType': 'BASE64'
        };
        this.fileService.fileUpload(params, this.croppedImage).subscribe(data => {
            if (data) {
                this.imageChangedEvent = undefined;
                this.updateProductImage(data);
                /*if (this.user && this.user['user_type'] === 'UNIVERSITY') {
                    this.updateUniversityProfileImage({'university_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'COUNSELLOR') {
                    this.updateCounsellorProfileImage({'counsellor_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'ADMIN') {
                    this.updateAdminProfileImage({'admin_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'HEAD_COUNSELLOR') {
                    this.updateHeadCounsellorProfileImage({'admin_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'BUSINESS_HEAD_CENTER') {
                    this.updateBusinessHeadCenterProfileImage({
                        'business_head_center_id': this.user.userId,
                        'profile_image': data['data'].id
                    });
                } else if (this.user && this.user['user_type'] === 'BUSINESS_CENTER') {
                    this.updateBusinessCenterProfileImage({'business_center_id': this.user.userId, 'profile_image': data['data'].id});
                } else if (this.user && this.user['user_type'] === 'BUSINESS_ADMIN') {
                    this.updateBusinessAdminProfileImage({'business_admin_id': this.user.userId, 'profile_image': data['data'].id});
                }*/
            }
        });
    }

    updateProductImage(data) {
        const params = {
            'product_id': (this.viewImages && this.viewImages.id ? this.viewImages.id : ''),
            'product_image': (data && data['data'] && data['data'].id) ? data['data'].id : ''
        };
        this.productService.addProductImage(params).subscribe(data => {
            if (data) {
                this.get();
                this.modalService.dismissAll('');
            }
        });
    }

    deleteProductImage(data) {
        this.productService.deleteProductImage(data.id).subscribe(dt => {
            this.viewImages['product_image'].splice(this.viewImages['product_image'].findIndex(a => parseInt(a.id) === parseInt(data.id)), 1);
        });
    }
    updateStockStatus(data: any) {

        const params = {
            'product_status': this.stockStatusForm.value.stock_status,
        };
        this.productService.updateStockStatus(data.id, params).subscribe(dt => {
            this.get();
        }, error => {
        });
    }
    goTo(url) {
        window.open(url, '_blank');
    }

    GoToProductsActionContent(data) {
        this.router.navigateByUrl('product-action/' + data.id );
    }
    GoToKeyIngredient(data) {
        this.router.navigateByUrl('key-ingredient/' + data.id );
    }
    GoToDosage(data) {
        this.router.navigateByUrl('dosage/' + data.id );
    }
}
