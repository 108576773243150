<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        (click)="toggleSidebar()"
                        aria-label="Close"
                        class="close"
                        data-dismiss="modal"
                        type="button"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 *ngIf="!data || !data['id']" class="modal-title">Add Consultant</h5>
                    <h5 *ngIf="data && data['id']" class="modal-title">Update Consultant</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form [formGroup]="addConsultantForm" class="form mt-2 shadow-none" novalidate>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input class="form-control" formControlName="name" id="name" type="text">
                                        <small *ngIf="submitted && addConsultantForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="username">Username</label>
                                        <input class="form-control" formControlName="username" id="username"
                                               type="text">
                                        <small *ngIf="submitted && addConsultantForm.get('username').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control" formControlName="email" id="email" type="text">
                                        <small *ngIf="submitted && addConsultantForm.get('email').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addConsultantForm.controls['email'].errors && addConsultantForm.controls['email'].errors.pattern"
                                               class="text-danger">Invalid email format</small>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contact">Contact</label>
                                        <input class="form-control" formControlName="contact" id="contact"
                                               type="number">
                                        <small *ngIf="submitted && addConsultantForm.get('contact').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addConsultantForm.controls['contact'].errors && addConsultantForm.controls['contact'].errors.pattern"
                                               class="text-danger">Invalid contact format</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="address">Address</label>
                                        <textarea class="form-control" formControlName="address"
                                                  id="address" style="resize: none;"></textarea>
                                        <small *ngIf="submitted && addConsultantForm.get('address').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="lic_no">License Number</label>
                                        <input class="form-control" formControlName="lic_no" id="lic_no"
                                               type="text">
                                        <small *ngIf="submitted && addConsultantForm.get('lic_no').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="fassai_lic_no">FSSAI License Number</label>
                                        <input class="form-control" formControlName="fassai_lic_no" id="fassai_lic_no"
                                               type="text">
                                        <small *ngIf="submitted && addConsultantForm.get('fassai_lic_no').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="gst_no">GST Number</label>
                                        <input class="form-control" formControlName="gst_no" id="gst_no"
                                               type="text">
                                        <small *ngIf="submitted && addConsultantForm.get('gst_no').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!data || !data.id" class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="d-flex" for="password">
                                            Password
                                            <i (click)="inputTypePassword = 'text'"
                                               [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                               class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                            <i (click)="inputTypePassword = 'password'"
                                               [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                               class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                        </label>
                                        <input [type]="inputTypePassword" class="form-control"
                                               formControlName="password"
                                               id="password">
                                        <small *ngIf="submitted && addConsultantForm.get('password').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button (click)="toggleSidebar()" class="btn btn-danger" rippleEffect type="reset">
                                        Cancel
                                    </button>
                                    <button (click)="submit()" *ngIf="!data || !data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Submit
                                    </button>
                                    <button (click)="update()" *ngIf="data && data['id']"
                                            [disabled]="user?.user_type !== 'ADMIN'"
                                            class="btn btn-primary ml-1 pull-right" rippleEffect
                                            type="submit">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
