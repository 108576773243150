import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {ConsultantService} from '../../../shared/services/consultant.service';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
    selector: 'app-add-consultant',
    templateUrl: './add-consultant.component.html',
    styleUrls: ['./add-consultant.component.scss']
})
export class AddConsultantComponent implements OnChanges {
    addConsultantForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    user: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private consultantService: ConsultantService) {
        this.user = StorageService.getItem('self');
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-consultant').toggleOpen();
    }

    refresh() {
        this.addConsultantForm = this.fb.group({
            'name': ['', Validators.required],
            'username': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'address': ['', Validators.required],
            'lic_no': ['', Validators.required],
            'fassai_lic_no': ['', Validators.required],
            'gst_no': ['', Validators.required],
            'password': ['', (!this.data || !this.data['id']) ? Validators.required : '']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addConsultantForm.patchValue({
            'name': (this.data && this.data?.user?.name) ? this.data?.user?.name : '',
            'username': (this.data && this.data?.user?.username) ? this.data?.user?.username : '',
            'email': (this.data && this.data?.user?.email) ? this.data?.user?.email : '',
            'address': (this.data && this.data?.address) ? this.data?.address : '',
            'contact': (this.data && this.data?.user?.contact) ? this.data?.user?.contact : '',
            'lic_no': (this.data && this.data?.lic_no) ? this.data?.lic_no : '',
            'fassai_lic_no': (this.data && this.data?.fassai_lic_no) ? this.data?.fassai_lic_no : '',
            'gst_no': (this.data && this.data?.gst_no) ? this.data?.gst_no : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addConsultantForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addConsultantForm.value && this.addConsultantForm.value.name) ? this.addConsultantForm.value.name : '',
                'username': (this.addConsultantForm.value && this.addConsultantForm.value.username) ? this.addConsultantForm.value.username : '',
                'email': (this.addConsultantForm.value && this.addConsultantForm.value.email) ? this.addConsultantForm.value.email : '',
                'contact': (this.addConsultantForm.value && this.addConsultantForm.value.contact) ? this.addConsultantForm.value.contact : '',
                'address': (this.addConsultantForm.value && this.addConsultantForm.value.address) ? this.addConsultantForm.value.address : '',
                'lic_no': (this.addConsultantForm.value && this.addConsultantForm.value.lic_no) ? this.addConsultantForm.value.lic_no : '',
                'fassai_lic_no': (this.addConsultantForm.value && this.addConsultantForm.value.fassai_lic_no) ? this.addConsultantForm.value.fassai_lic_no : '',
                'gst_no': (this.addConsultantForm.value && this.addConsultantForm.value.gst_no) ? this.addConsultantForm.value.gst_no : '',
                'password': (this.addConsultantForm.value && this.addConsultantForm.value.password) ? this.addConsultantForm.value.password : ''
            };
            this.submitting = true;
            this.consultantService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addConsultantForm.reset();
                    this.addConsultantForm.patchValue({
                        'name': '',
                        'username': '',
                        'specialisation': '',
                        'email': '',
                        'contact': '',
                        'address': '',
                        'lic_no': '',
                        'fassai_lic_no': '',
                        'gst_no': '',
                        'password': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].address && error['error']['errors'].address.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address[0]);
                        return;
                    }
                    if (error['error']['errors'].lic_no && error['error']['errors'].lic_no.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].lic_no[0]);
                        return;
                    }
                    if (error['error']['errors'].fassai_lic_no && error['error']['errors'].fassai_lic_no.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].fassai_lic_no[0]);
                        return;
                    }
                    if (error['error']['errors'].gst_no && error['error']['errors'].gst_no.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gst_no[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addConsultantForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addConsultantForm.value && this.addConsultantForm.value.name) ? this.addConsultantForm.value.name : '',
                'username': (this.addConsultantForm.value && this.addConsultantForm.value.username) ? this.addConsultantForm.value.username : '',
                'email': (this.addConsultantForm.value && this.addConsultantForm.value.email) ? this.addConsultantForm.value.email : '',
                'contact': (this.addConsultantForm.value && this.addConsultantForm.value.contact) ? this.addConsultantForm.value.contact : '',
                'address': (this.addConsultantForm.value && this.addConsultantForm.value.address) ? this.addConsultantForm.value.address : '',
                'lic_no': (this.addConsultantForm.value && this.addConsultantForm.value.lic_no) ? this.addConsultantForm.value.lic_no : '',
                'fassai_lic_no': (this.addConsultantForm.value && this.addConsultantForm.value.fassai_lic_no) ? this.addConsultantForm.value.fassai_lic_no : '',
                'gst_no': (this.addConsultantForm.value && this.addConsultantForm.value.gst_no) ? this.addConsultantForm.value.gst_no : '',
            };
            this.submitting = true;
            this.consultantService.update(this.data?.user?.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].address && error['error']['errors'].address.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address[0]);
                        return;
                    }
                    if (error['error']['errors'].lic_no && error['error']['errors'].lic_no.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].lic_no[0]);
                        return;
                    }
                    if (error['error']['errors'].fassai_lic_no && error['error']['errors'].fassai_lic_no.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].fassai_lic_no[0]);
                        return;
                    }
                    if (error['error']['errors'].gst_no && error['error']['errors'].gst_no.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gst_no[0]);
                        return;
                    }
                }
            });
        }
    }
}
