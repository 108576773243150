import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentService} from '../../../shared/services/appointment.service';

@Component({
    selector: 'app-appointment-docs',
    templateUrl: './appointment-docs.component.html',
    styleUrls: ['./appointment-docs.component.scss']
})
export class AppointmentDocsComponent implements OnInit {
    appointmentDocs = [];
    id: any;
    submitting: any = false;
    submitted: any = false;
    appointmentData: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private appointmentService: AppointmentService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['appointId'];
        this.refresh();
    }

    refresh() {
        this.getAppointmentdetail(this.id);
    }

    getAppointmentdetail(id) {
        this.appointmentService.detail(id).subscribe(data => {
            this.appointmentData = data;
            console.log('appointmentData', this.appointmentData);
            this.get();
        });
    }

    get() {
        this.submitting = true;
        this.appointmentDocs = [];
        this.appointmentService.getAppointmentDocs(this.id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.appointmentDocs = data['data'];
                console.log('appointmentDocs', this.appointmentDocs);
            } else {
                this.appointmentDocs = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    goTo(url) {
        window.open(url, '_blank');
    }
}
