import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {StorageService} from './storage.service';
import {AlertService} from './alert.service';
import {Router} from '@angular/router';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                private router: Router,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    login(data): Observable<any> {
        return this.postRequest(AppUrl.LOGIN, data);
    }

    verifyUser(data): Observable<any> {
        return this.http.post(AppUrl.VERIFY_USER, data).pipe(catchError(this.handleError));
    }

    self(params = {}): Observable<any> {
        return this.getRequest(AppUrl.SELF, params);
    }

    updateSelfPassword(data): Observable<any> {
        return this.putRequest(AppUrl.UPDATE_SELF_PASSWORD(), data);
    }

    update(data): Observable<any> {
        return this.putRequest(AppUrl.UPDATE_SELF(), data);
    }

    logout(): Observable<any> {
        return this.getRequest(AppUrl.LOGOUT());
    }
}
