import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsultantsRoutingModule} from './consultants-routing.module';
import {ConsultantsComponent} from './consultants.component';
import {AddConsultantComponent} from './add-consultant/add-consultant.component';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {ReactiveFormsModule} from '@angular/forms';
import { ConsultantAppointmentsComponent } from './consultant-appointments/consultant-appointments.component';

@NgModule({
    declarations: [
        ConsultantsComponent,
        AddConsultantComponent,
        ConsultantAppointmentsComponent
    ],
    imports: [
        CommonModule,
        ConsultantsRoutingModule,
        CoreSidebarModule,
        NgbPaginationModule,
        CoreDirectivesModule,
        ReactiveFormsModule
    ]
})

export class ConsultantsModule {
}
