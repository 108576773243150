<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="mt-5px">Appointment Docs - {{appointmentData?.appointment_id}}</h2>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Remarks</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">View
                                                Doc</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="appointmentDocs && appointmentDocs.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let appointmentDoc of appointmentDocs; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{i + 1}}.</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{appointmentDoc?.remark}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-primary"
                                                        (click)="goTo(appointmentDoc?.document?.url)">View Doc
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="3" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="appointmentDocs && appointmentDocs.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let appointmentDoc of appointmentDocs; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-12">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{i + 1}}.
                                        Document {{i + 1}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Remark - {{appointmentDoc?.remark}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Document - <span class="text-info" (click)="goTo(appointmentDoc?.document?.url)">View Doc</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
        </section>
    </div>
</div>
