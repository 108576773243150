export const locale = {
    lang: 'en',
    data: {
        MENU: {
            DASHBOARD: 'Dashboard',
            ADMINS: 'Admins',
            SUBADMINS: 'SubAdmins',
            SYMPTOMS: 'Symptoms',
            DOCTORS: 'Doctors',
            CONSULTANTS: 'Consultants',
            USERS: 'Users',
            APPOINTMENTS: 'Appointments',
            CATEGORIES: 'Categories',
            SUBCATEGORIES: 'SubCategories',
            PRODUCTS: 'Products',
            FAQS: 'Faqs',
            TESTIMONIALS: 'Testimonials',
            TRANSACTIONS: 'Transactions',
            ORDERS: 'Orders',
            POS: 'Pos',
            BLOGS: 'Blogs',
            METATAGS: 'MetaTags',
            BLOGSHINDI: 'BlogsHindi',
            CONTACTUSDATA: 'ContactUsData',
            SELFASSEMENT: 'SelfAssessment'
        }
    }
};
