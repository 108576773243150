<div class="content-wrapper">
    <div class="content-body">
        <section id="doctors">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Update Password</h4>
                        <button
                                (click)="modalService.dismissAll('')"
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="updatePasswordForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="d-flex" for="password">
                                                        Password
                                                        <i (click)="inputTypePassword = 'text'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                                           class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                                        <i (click)="inputTypePassword = 'password'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                                           class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                                    </label>
                                                    <input [type]="inputTypePassword" class="form-control"
                                                           formControlName="password"
                                                           id="password">
                                                    <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button (click)="updatePassword()" [disabled]="submitting"
                                [disabled]="user?.user_type !== 'ADMIN'"
                                class="btn btn-success color-white pull-right">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Update
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Doctors</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="add()"
                            *ngIf="user && ['ADMIN'].indexOf(user['user_type']) !== -1"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Doctor
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="name" placeholder="Name" type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="username" placeholder="Username"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="email" placeholder="Email" type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="contact" placeholder="Contact"
                                           type="number">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <!--                                    <pre class="text-white">{{doctors[0]}}</pre>-->
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Link</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Video Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">
                                                    Specialisation
                                                </h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">
                                                    Medical Reg. No.
                                                </h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Work Status</h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Profile Image</h6>
                                            </th>
                                            <!--                                            <th class="text-center">-->
                                            <!--                                                <h6 class="mt-1 mb-1 font-weight-bold">Signature</h6>-->
                                            <!--                                            </th>-->
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Work Hours</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Remark</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Experience</h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Content</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Gender</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Password</h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="doctors && doctors.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let doctor of doctors; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{doctor?.user?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{doctor?.user?.username}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{doctor?.link}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{doctor?.video_username}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{doctor?.specialisation}}</h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6
                                                        class="mt-1">{{doctor?.medical_registration_number}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6
                                                        class="mt-1">{{doctor?.work_status}}</h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <div *ngIf="doctor && doctor?.profile_image"
                                                     class="btn-group"
                                                     role="group">
                                                    <button (click)="profileImageUpload.click()"
                                                            [disabled]="user?.user_type !== 'ADMIN'"
                                                            class="btn btn-primary" title="Upload Cover"
                                                            type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openProfileImage(doctor)"
                                                            class="btn btn-primary" title="View Cover"
                                                            type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="profileImageUpload.click()"
                                                        *ngIf="doctor && !doctor?.profile_image"
                                                        [disabled]="user?.user_type !== 'ADMIN'"
                                                        class="btn btn-primary color-white" title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #profileImageUpload
                                                       (change)="onFileChange($event, 'PROFILE_IMAGE', doctor)"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>
                                            <!--                                            <td class="text-center"><h6-->
                                            <!--                                                    class="mt-1">{{doctor?.signature}}</h6></td>-->
                                            <td class="text-center">
                                                <h6
                                                        class="mt-1">{{doctor?.work_hours}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6
                                                        class="mt-1">{{doctor?.remark}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6
                                                        class="mt-1">{{doctor?.experience}}</h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6
                                                        class="mt-1">
                                                    <i (click)="doctorInfoPage(doctor)"
                                                       aria-hidden="true"
                                                       class="fab fa-artstation f_20 mt-1 text-primary cursor-pointer font-weight-light ml-5px"
                                                       title="Perform Actions"></i>
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6
                                                        class="mt-1">{{doctor?.gender}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{doctor?.user?.email}}</h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <h6 class="mt-1">{{doctor?.user?.contact}}</h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='ADMIN'" class="text-center cursor-pointer">
                                                <h6 (click)="updatePasswordModal(content, doctor.id)"
                                                    class="mt-1 text-info">
                                                    Update
                                                </h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='ADMIN'" class="text-center">
                                                <i (click)="edit(doctor)"
                                                   aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit"></i>
                                                <i (click)="updateStatus('INACTIVE', doctor)"
                                                   *ngIf="doctor?.status === 'ACTIVE'"
                                                   aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Admin"></i>
                                                <i (click)="updateStatus('ACTIVE', doctor)"
                                                   *ngIf="doctor?.status === 'INACTIVE'"
                                                   aria-hidden="true"
                                                   class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Active Admin"></i>
                                                <i (click)="goTo(doctor)"
                                                   class="fas fa-notes-medical f_20 ml-10px text-success cursor-pointer mt-1 mr-1"
                                                   title="Consults"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="9">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            <div *ngIf="doctors && doctors.length > 0; else noDataToShowMobile" class="row mt-3 d-block d-md-none">-->
            <!--                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
            <!--                    <div *ngFor="let doctor of doctors; let i = index" class="card">-->
            <!--                        <div class="card-header bottom-shadow-light pb-18px">-->
            <!--                            <div class="row width-100-per">-->
            <!--                                <div class="col-6">-->
            <!--                                    <h4-->
            <!--                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}-->
            <!--                                        . {{doctor?.name}}-->
            <!--                                    </h4>-->
            <!--                                </div>-->
            <!--                                <div class="col-6">-->
            <!--                                    <i (click)="goTo(doctor)"-->
            <!--                                       class="fas fa-notes-medical f_20 ml-10px text-success cursor-pointer mt-1 mr-1"-->
            <!--                                       title="Consults"></i>-->
            <!--                                    <i (click)="updateStatus('INACTIVE', doctor)"-->
            <!--                                       *ngIf="doctor?.status === 'ACTIVE'"-->
            <!--                                       aria-hidden="true"-->
            <!--                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"-->
            <!--                                       title="In Active Executive"></i>-->
            <!--                                    <i (click)="updateStatus('ACTIVE', doctor)"-->
            <!--                                       *ngIf="doctor?.status === 'INACTIVE'"-->
            <!--                                       aria-hidden="true"-->
            <!--                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"-->
            <!--                                       title="Active Executive"></i>-->
            <!--                                    <i (click)="edit(doctor)"-->
            <!--                                       aria-hidden="true"-->
            <!--                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"-->
            <!--                                       title="Edit"></i>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="card-body">-->
            <!--                            <div class="row p-2">-->
            <!--                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">-->
            <!--                                    Username - {{doctor?.username}}-->
            <!--                                </div>-->
            <!--                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">-->
            <!--                                    Specialisation - {{doctor?.doctor_detail?.specialisation}}-->
            <!--                                </div>-->
            <!--                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">-->
            <!--                                    Medical Reg. No. - {{doctor?.doctor_detail?.medical_registration_number}}-->
            <!--                                </div>-->
            <!--                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">-->
            <!--                                    Email - {{doctor?.email}}-->
            <!--                                </div>-->
            <!--                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">-->
            <!--                                    Contact - {{doctor?.contact}}-->
            <!--                                </div>-->
            <!--                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">-->
            <!--                                    Password - <span (click)="updatePasswordModal(content, doctor.id)"-->
            <!--                                                     class="text-info">Update</span>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                [boundaryLinks]="true" [collectionSize]="pagination.total"
                                [maxSize]="5" [pageSize]="pagination.perpage"
                                class="pagination-primary">
                    <ng-template ngbPaginationPrevious>
               <span [class]="'font-weight-bolder'" [data-feather]="'chevron-left'"
                     [size]="18">
               </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
               <span [class]="'font-weight-bolder'" [data-feather]="'chevron-right'"
                     [size]="18">
               </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-doctor"
            overlayClass="modal-backdrop">
        <app-add-doctor (valueSubmitted)="getData($event)" [data]="data"></app-add-doctor>
    </core-sidebar>
</div>
