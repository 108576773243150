import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AdminService} from '../../../shared/services/admin.service';
import {ContactusService} from '../../../shared/services/contactus.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  userdatacontactus = [];
  data: any;
  submitting: any = false;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private contactUsService: ContactusService) { }

  ngOnInit(): void {
    this.refresh();
  }
  refresh() {
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.userdatacontactus = [];
    this.contactUsService.get(params).subscribe(data => {
      if (data ) {
        this.userdatacontactus = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.userdatacontactus = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
    };
    this.get(params);
  }


}
