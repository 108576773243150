import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DoctorsComponent} from './doctors.component';
import {AddDoctorComponent} from './add-doctor/add-doctor.component';
import {DoctorConsultsComponent} from './doctor-consults/doctor-consults.component';
import {ContentInfoComponent} from './content-info/content-info.component';

const routes: Routes = [
    {
        path: 'doctors',
        component: DoctorsComponent,
        data: {animation: 'doctors'}
    },
    {
        path: 'doctor/add',
        component: AddDoctorComponent,
        data: {animation: 'doctor/add'}
    },
    {
        path: 'doctor/:id/consults',
        component: DoctorConsultsComponent,
        data: {animation: 'doctor/:id/consults'}
    },
    {
        path: 'doctors-content-info/:id',
        component: ContentInfoComponent,
        data: {animation: 'doctors-content-info'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class DoctorsRoutingModule {
}
