import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AdminService} from '../../shared/services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';


@Component({
    selector: 'app-sub-admins',
    templateUrl: './sub-admins.component.html',
    styleUrls: ['./sub-admins.component.scss']
})
export class SubAdminsComponent implements OnInit {


    searchForm: FormGroup;
    subAdmins = [];
    showSearch = false;
    admins = [];
    id: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;


    details: any = [
        {
            SNO: 1,
            Name: 'MS 1',
            Username: 'ADMIN 1',
            email: 'abc@gmail.com',
            contact: 1234567890,
            password: 'update',
            action: ''
        }
        , {
            SNO: 2,
            Name: 'MS 2',
            Username: 'ADMIN 2',
            email: 'abc@gmail.com',
            contact: 1234567890,
            password: 'update',
            action: ''
        }
        , {
            SNO: 3,
            Name: 'MS 3',
            Username: 'ADMIN 3',
            email: 'abc@gmail.com',
            contact: 1234567890,
            password: 'update',
            action: ''
        }
        , {
            SNO: 4,
            Name: 'MS 4',
            Username: 'ADMIN 4',
            email: 'abc@gmail.com',
            contact: 1234567890,
            password: 'update',
            action: ''
        }
        , {
            SNO: 5,
            Name: 'MS 5',
            Username: 'ADMIN 5',
            email: 'abc@gmail.com',
            contact: 1234567890,
            password: 'update',
            action: ''
        }
    ];

    constructor(private _coreSidebarService: CoreSidebarService,
                private adminService: AdminService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private router: Router,) {
    }

    ngOnInit(): void {
        this.refresh();
    }
    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.get();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    data: any;

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-sub-admin').toggleOpen();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getData({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.subAdmins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.subAdmins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.subAdmins = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePasswordModal(content, id) {
        this.id = id;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }
    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.adminService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }
    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }
    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }
    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.adminService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

}
