import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {AdminService} from '../../../shared/services/admin.service';

@Component({
  selector: 'app-add-symptom',
  templateUrl: './add-symptom.component.html',
  styleUrls: ['./add-symptom.component.scss']
})
export class AddSymptomComponent implements OnInit {
  addSymptomForm: FormGroup;
  inputTypePassword: any = 'password';
  submitted: any = false;
  submitting: any = false;
  @Output() valueSubmitted = new EventEmitter<any>();
  @Input() data: any;

  constructor(private _coreSidebarService: CoreSidebarService,
              private fb: FormBuilder,
              private alertService: AlertService,
              private adminService: AdminService) { }

  ngOnInit(): void {
    this.refresh();
  }
  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-symptom').toggleOpen();
  }
  refresh() {
    this.addSymptomForm = this.fb.group({
      'name': ['', Validators.required]
    });
    if (this.data && this.data['id']) {
      this.patchForm();
    }
  }
  patchForm() {
    this.addSymptomForm.patchValue({
      'name': (this.data && this.data['name']) ? this.data['name'] : ''
    });
  }
  submit() {
    this.submitted = true;
    if (!this.addSymptomForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addSymptomForm.value && this.addSymptomForm.value.name) ? this.addSymptomForm.value.name : ''
      };
      this.submitting = true;
      this.adminService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.valueSubmitted.emit(true);
          this.addSymptomForm.reset();
          this.addSymptomForm.patchValue({
            'name': '',
          });
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
            this.alertService.showErrors(error['error']['errors'].name[0]);
            return;
          }
        }
      });
    }
  }
}
