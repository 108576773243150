import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {AdminService} from '../../../shared/services/admin.service';

@Component({
    selector: 'app-add-faq',
    templateUrl: './add-faq.component.html',
    styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnChanges {
    addFaqForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    // @ts-ignore
    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private adminService: AdminService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    refresh() {
        this.addFaqForm = this.fb.group({
            'qestion': ['', Validators.required],
            'answer': ['', Validators.required],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addFaqForm.patchValue({
            'question': (this.data && this.data['question']) ? this.data['question'] : '',
            'answer': (this.data && this.data['answer']) ? this.data['answer'] : '',
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-faq').toggleOpen();
    }

    submit() {
        this.submitted = true;
        if (!this.addFaqForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'qestion': (this.addFaqForm.value && this.addFaqForm.value.name) ? this.addFaqForm.value.question : '',
                'answer': (this.addFaqForm.value && this.addFaqForm.value.answer) ? this.addFaqForm.value.answer : '',
            };
            this.submitting = true;
            this.adminService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addFaqForm.reset();
                    this.addFaqForm.patchValue({
                        'question': '',
                        'answer': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].answer && error['error']['errors'].answer.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].answer[0]);
                        return;
                    }
                }
            });
        }
    }

}
