import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MetaTagsComponent} from './meta-tags.component';
import {AddMetaTagsComponent} from './add-meta-tags/add-meta-tags.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreSidebarModule} from '../../../@core/components';
import {QuillModule} from 'ngx-quill';
import {CoreCommonModule} from '../../../@core/common.module';

const routes: Routes = [
    {path: 'meta-tags', component: MetaTagsComponent},
    {
        path: 'meta-tags-info/:id',
        component: AddMetaTagsComponent,
        data: {animation: 'MetaTags/Info'}
    }
];


@NgModule({
    declarations: [MetaTagsComponent, AddMetaTagsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CoreSidebarModule,
        QuillModule,
        FormsModule,
        CoreCommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        AddMetaTagsComponent,
    ]
})
export class MetaTagsModule {
}
