import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {BlogService} from '../../../shared/services/blog.service';
import {MetaTagsService} from '../../../shared/services/meta-tags.service';

@Component({
    selector: 'app-add-meta-tags',
    templateUrl: './add-meta-tags.component.html',
    styleUrls: ['./add-meta-tags.component.scss']
})

export class AddMetaTagsComponent implements OnChanges {
    addMetaTagsForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    id: any;
    insights: any = [];
    allInsight: any = [];
    allBlogInsight: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private blogService: BlogService,
                private metaTagsService: MetaTagsService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    /* ngOnInit(): void {

     }*/

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-meta-tags').toggleOpen();
    }

    refresh() {
        this.getAllBlogs();
        this.addMetaTagsForm = this.fb.group({
            'blog_id': ['', (!this.id) ? Validators.required : ''],
            'title': ['', (!this.id) ? Validators.required : ''],
            'meta_description': ['', (!this.id) ? Validators.required : ''],
            'meta_keyword': ['', (!this.id) ? Validators.required : ''],
            'meta_og_url': [''],
            'meta_og_title': [''],
            'meta_og_type': [''],
            'meta_og_description': [''],
            'meta_og_image': [''],
            'meta_twitter_card': [''],
            'meta_twitter_title': [''],
            'meta_twitter_description': [''],
            'meta_twitter_url': [''],
            'meta_twitter_image': ['']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    getAllBlogs() {
        // @ts-ignore
        this.blogService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allBlogInsight = (data && data['data']) ? data['data'] : [];
            } else {
                this.allBlogInsight = [];
            }
        });
    }


    patchForm() {
        if (this.data) {
            this.getMetaTags(this.data['id']);
        }
        this.addMetaTagsForm.patchValue({
            'blog_id': (this.data && this.data?.blog?.id) ? this.data?.blog?.id : '',
            'title': (this.data && this.data['title']) ? this.data['title'] : '',
            'meta_description': (this.data && this.data['meta_description']) ? this.data['meta_description'] : '',
            'meta_keyword': (this.data && this.data['meta_keyword']) ? this.data['meta_keyword'] : '',
            'meta_og_title': (this.data && this.data['meta_og_title']) ? this.data['meta_og_title'] : '',
            'meta_og_url': (this.data && this.data['meta_og_url']) ? this.data['meta_og_url'] : '',
            'meta_og_type': (this.data && this.data['meta_og_type']) ? this.data['meta_og_type'] : '',
            'meta_og_description': (this.data && this.data['meta_og_description']) ? this.data['meta_og_description'] : '',
            'meta_og_image': (this.data && this.data['meta_og_image']) ? this.data['meta_og_image'] : '',
            'meta_twitter_card': (this.data && this.data['meta_twitter_card']) ? this.data['meta_twitter_card'] : '',
            'meta_twitter_title': (this.data && this.data['meta_twitter_title']) ? this.data['meta_twitter_title'] : '',
            'meta_twitter_description': (this.data && this.data['meta_twitter_description']) ? this.data['meta_twitter_description'] : '',
            'meta_twitter_url': (this.data && this.data['meta_twitter_url']) ? this.data['meta_twitter_url'] : '',
            'meta_twitter_image': (this.data && this.data['meta_twitter_image']) ? this.data['meta_twitter_image'] : '',
        });
    }

    getMetaTags(id) {
        this.metaTagsService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allInsight = (data && data['data']) ? data['data'] : [];
            } else {
                this.allInsight = [];
            }
        });
    }


    submit() {
        this.submitted = true;
        if (!this.addMetaTagsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'blog_id': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.blog_id) ? this.addMetaTagsForm.value.blog_id : '',
                'title': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.title) ? this.addMetaTagsForm.value.title : '',
                'meta_description': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_description) ? this.addMetaTagsForm.value.meta_description : '',
                'meta_keyword': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_keyword) ? this.addMetaTagsForm.value.meta_keyword : '',
                'meta_og_title': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_title) ? this.addMetaTagsForm.value.meta_og_title : '',
                'meta_og_url': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_url) ? this.addMetaTagsForm.value.meta_og_url : '',
                'meta_og_type': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_type) ? this.addMetaTagsForm.value.meta_og_type : '',
                'meta_og_description': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_description) ? this.addMetaTagsForm.value.meta_og_description : '',
                'meta_og_image': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_image) ? this.addMetaTagsForm.value.meta_og_image : '',
                'meta_twitter_card': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_card) ? this.addMetaTagsForm.value.meta_twitter_card : '',
                'meta_twitter_title': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_title) ? this.addMetaTagsForm.value.meta_twitter_title : '',
                'meta_twitter_description': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_description) ? this.addMetaTagsForm.value.meta_twitter_description : '',
                'meta_twitter_url': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_url) ? this.addMetaTagsForm.value.meta_twitter_url : '',
                'meta_twitter_image': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_image) ? this.addMetaTagsForm.value.meta_twitter_image : '',
            };
            this.submitting = true;
            this.metaTagsService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addMetaTagsForm.reset();
                    this.addMetaTagsForm.patchValue({
                        'blog_id': '',
                        'title': '',
                        'meta_description': '',
                        'meta_keyword': '',
                        'meta_og_title': '',
                        'meta_og_url': '',
                        'meta_og_type': '',
                        'meta_og_description': '',
                        'meta_og_image': '',
                        'meta_twitter_card': '',
                        'meta_twitter_title': '',
                        'meta_twitter_description': '',
                        'meta_twitter_url': '',
                        'meta_twitter_image': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].blog_id && error['error']['errors'].blog_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].blog_id[0]);
                        return;
                    }
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_description && error['error']['errors'].meta_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_description[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_keyword && error['error']['errors'].meta_keyword.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_keyword[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_og_url && error['error']['errors'].meta_og_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_og_url[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addMetaTagsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'blog_id': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.blog_id) ? this.addMetaTagsForm.value.blog_id : '',
                'title': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.title) ? this.addMetaTagsForm.value.title : '',
                'meta_description': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_description) ? this.addMetaTagsForm.value.meta_description : '',
                'meta_keyword': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_keyword) ? this.addMetaTagsForm.value.meta_keyword : '',
                'meta_og_title': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_title) ? this.addMetaTagsForm.value.meta_og_title : '',
                'meta_og_url': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_url) ? this.addMetaTagsForm.value.meta_og_url : '',
                'meta_og_type': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_type) ? this.addMetaTagsForm.value.meta_og_type : '',
                'meta_og_description': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_description) ? this.addMetaTagsForm.value.meta_og_description : '',
                'meta_og_image': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_og_image) ? this.addMetaTagsForm.value.meta_og_image : '',
                'meta_twitter_card': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_card) ? this.addMetaTagsForm.value.meta_twitter_card : '',
                'meta_twitter_title': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_title) ? this.addMetaTagsForm.value.meta_twitter_title : '',
                'meta_twitter_description': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_description) ? this.addMetaTagsForm.value.meta_twitter_description : '',
                'meta_twitter_url': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_url) ? this.addMetaTagsForm.value.meta_twitter_url : '',
                'meta_twitter_image': (this.addMetaTagsForm.value && this.addMetaTagsForm.value.meta_twitter_image) ? this.addMetaTagsForm.value.meta_twitter_image : '',

            };
            this.submitting = true;
            this.metaTagsService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].blog_id && error['error']['errors'].blog_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].blog_id[0]);
                        return;
                    }
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_description && error['error']['errors'].meta_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_description[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_keyword && error['error']['errors'].meta_keyword.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_keyword[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_og_url && error['error']['errors'].meta_og_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_og_url[0]);
                        return;
                    }
                }
            });
        }
    }
}
