import {Component, OnChanges, OnInit} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AdminService} from '../../shared/services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnChanges {
    data: any;
    id: any;
    showSearch = false;
    admins = [];
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    submitted: any = false;
    submitting: any = false;
    updatePasswordForm: FormGroup;

    details: any = [
        {
            SNO: 1,
            question: 'Problem h Kya 1',
            answer: 'Mai hu Na 1',
            action: ''
        }
        , {
            SNO: 2,
            question: 'Problem h Kya 2',
            answer: 'Mai hu Na 2',
            action: ''
        }
        , {
            SNO: 3,
            question: 'Problem h Kya 3',
            answer: 'Mai hu Na 3',
            action: ''
        }
        , {
            SNO: 4,
            question: 'Problem h Kya 4',
            answer: 'Mai hu Na 4',
            action: ''
        }
        , {
            SNO: 5,
            question: 'Problem h Kya 5',
            answer: 'Mai hu Na 5',
            action: ''
        }
    ];

    constructor(private _coreSidebarService: CoreSidebarService,
                private adminService: AdminService,
                public modalService: NgbModal,
                private fb: FormBuilder) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'question': [''],
            'answer': [''],
        });
        this.get();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-faq').toggleOpen();
    }

    search() {
        const params = {
            'question': (this.searchForm.value && this.searchForm.value.question) ? this.searchForm.value.question : '',
            'answer': (this.searchForm.value && this.searchForm.value.answer) ? this.searchForm.value.answer : '',
        };
        this.get(params);
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.admins = [];
        this.adminService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.admins = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.admins = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'question': (this.searchForm && this.searchForm.value && this.searchForm.value.question) ? this.searchForm.value.question : '',
            'answer': (this.searchForm && this.searchForm.value && this.searchForm.value.answer) ? this.searchForm.value.answer : '',
        };
        this.get(params);
    }
    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }
    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.adminService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }


}
