import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../../shared/services/storage.service';
import {ProductService} from '../../../shared/services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {PosService} from '../../../shared/services/pos.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Helper} from '../../../shared/constants/helper';
import {AddressService} from '../../../shared/services/address.service';
import {StateService} from '../../../shared/services/state.service';
import {TransactionService} from '../../../shared/services/transaction.service';
import {OrderService} from '../../../shared/services/order.service';

@Component({
    selector: 'app-add-order',
    templateUrl: './add-order.component.html',
    styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {
    orders = [];
    products = [];
    addedProducts: any = [
        {
            'product_id': '',
            'quantity': 1,
            'taxPercentageAndAmount': 0,
            'taxAmount': 0,
            'unit_price': 0,
            'total_price': 0
        }
    ];
    countries = [];
    searchForm: FormGroup;
    showSearch = false;
    modalType: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    user: any;
    userId: any;
    submitted: any = false;
    submitting: any = false;
    posUser: any;
    userAddresses: any = [];
    grandTotal: any = 0;
    unitPriceTotal: any = 0;
    taxAmountTotal: any = 0;
    addAddressForm: FormGroup;
    pinCodeShippingData: any = [];
    pinCodeBillingData: any = [];
    pinCodeLoader: any = false;
    sameBillingAddress: any = false;
    states: any = [];
    selectedAddress: any;
    selectedDeliveryType: any;

    constructor(public modalService: NgbModal,
                private fb: FormBuilder,
                private productService: ProductService,
                private authService: AuthService,
                private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private posService: PosService,
                private addressService: AddressService,
                private transactionService: TransactionService,
                private stateService: StateService,
                private orderService: OrderService,
                private router: Router) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.userId = this.activatedRoute.snapshot.params['id'];
        this.addAddressForm = this.fb.group({
            'address_1_billing': ['', Validators.required],
            'address_2_billing': [''],
            'landmark_billing': ['', Validators.required],
            'city_billing': ['', Validators.required],
            'pin_code_billing': ['', Validators.required],
            'state_id_billing': ['', Validators.required],
            'name_billing': ['', Validators.required],
            'contact_billing': ['', Validators.required],
            'address_1_shipping': ['', Validators.required],
            'address_2_shipping': [''],
            'landmark_shipping': ['', Validators.required],
            'city_shipping': ['', Validators.required],
            'pin_code_shipping': ['', Validators.required],
            'state_id_shipping': ['', Validators.required],
            'name_shipping': ['', Validators.required],
            'contact_shipping': ['', Validators.required],
            'type': ['', Validators.required],
            'delivery_amount': ['', this.selectedDeliveryType === 'CASH' ? Validators.required : ''],
            'discount': ['']
        });
        this.searchForm = this.fb.group({
            'order_id': [''],
            'product_id': [''],
            'delivery_status': ['']
        });
        this.getStates();
    }

    getStates() {
        this.submitting = true;
        this.stateService.get().subscribe(data => {
            this.states = data['data'];
            this.submitting = false;
            this.getUser();
        }, error => {
            this.submitting = false;
        });
    }

    getUser() {
        this.submitting = true;
        this.posService.getUser(this.userId).subscribe(data => {
            this.posUser = data;
            this.submitting = false;
            if (this.posUser && this.posUser.id) {
                this.getProducts();
            } else {
                this.router.navigateByUrl('pos');
            }
        }, error => {
            this.router.navigateByUrl('pos');
            this.submitting = false;
        });
    }

    getProducts(params: any = {}) {
        this.submitting = true;
        this.productService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.products = data['data'];
                this.getAddress();
                this.getOrders();
            } else {
                this.products = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getOrders(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.orderService.getByPos(this.userId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.orders = data['data'];
                if (this.orders && this.orders.length > 0) {
                    this.orders.forEach(order => {
                        order['total_price'] = Math.round((parseFloat(order['discounted_price']) * parseFloat(order['quantity'])) + ((parseFloat(order['discounted_price']) * parseFloat(order['quantity'])) * parseFloat(order['igst']) / 100));
                    });
                }
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.orders = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getAddress() {
        this.addressService.getAddressByPos(this.userId).subscribe(data => {
            if (data && data.length > 0) {
                this.userAddresses = data;
                if (this.userAddresses && this.userAddresses.length > 0) {
                    let selectedAddress: any;
                    this.userAddresses.forEach(address => {
                        if (address && address['status'] === 'SELECTED') {
                            selectedAddress = address;
                        }
                    });
                    this.selectedAddress = selectedAddress;
                }
            }
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'order_id': (this.searchForm.value && this.searchForm.value.order_id) ? this.searchForm.value.order_id : '',
            'product_id': (this.searchForm.value && this.searchForm.value.product_id) ? this.searchForm.value.product_id : '',
            'delivery_status': (this.searchForm.value && this.searchForm.value.delivery_status) ? this.searchForm.value.delivery_status : ''
        };
        this.getOrders(params);
    }

    onProductChange(event, data) {
        const val = event.target.value;
        if (val && this.products.length > 0) {
            if (this.addedProducts && this.addedProducts.length > 1) {
                let productAlreadyAdded = false;
                let i = 0;
                this.addedProducts.forEach(addedProduct => {
                    if (addedProduct && parseInt(addedProduct.product_id) === parseInt(val)) {
                        i++;
                        productAlreadyAdded = (i > 1);
                    }
                });
                if (!productAlreadyAdded) {
                    data.product_id = val;
                    data.quantity = 1;
                    let selectedProduct = {};
                    this.products.forEach(product => {
                        if (product && (parseInt(product['id']) === parseInt(val))) {
                            selectedProduct = product;
                        }
                    });
                    data.unit_price = parseFloat(selectedProduct['discounted_price']);
                    data.total_price = Math.round(parseFloat(selectedProduct['discounted_price']) + (parseFloat(selectedProduct['discounted_price']) * parseFloat(selectedProduct['igst']) / 100)) * data.quantity;
                    data.taxPercentageAndAmount = selectedProduct['igst'] + '%' + ' - ₹' + (parseFloat(selectedProduct['discounted_price']) * parseFloat(selectedProduct['igst']) / 100);
                    data.taxAmount = parseFloat(selectedProduct['discounted_price']) * parseFloat(selectedProduct['igst']) / 100;
                    data.igst = selectedProduct['igst'];
                    data.cgst = selectedProduct['cgst'];
                    data.sgst = selectedProduct['sgst'];
                    data.ugst = selectedProduct['ugst'];
                    data.hsn = selectedProduct['hsn'];
                    data.actual_price = selectedProduct['actual_price'];
                    // data.type = 'CASH';

                    let grandTotalArrays = [];
                    let unitPriceTotalArrays = [];
                    let taxAmountTotalArrays = [];
                    this.addedProducts.forEach(addedProduct => {
                        if (addedProduct && addedProduct['total_price']) {
                            grandTotalArrays.push(addedProduct['total_price']);
                            unitPriceTotalArrays.push(addedProduct['unit_price'] * parseFloat(addedProduct['quantity']));
                            taxAmountTotalArrays.push(addedProduct['taxAmount'] * parseFloat(addedProduct['quantity']));
                        }
                    });
                    this.grandTotal = grandTotalArrays.reduce((a, b) => a + b, 0);
                    this.unitPriceTotal = unitPriceTotalArrays.reduce((a, b) => a + b, 0);
                    this.taxAmountTotal = taxAmountTotalArrays.reduce((a, b) => a + b, 0);
                } else {
                    data.product_id = '';
                    data.quantity = 1;
                    data.unit_price = '';
                    data.taxPercentageAndAmount = '';
                    data.taxAmount = '';
                    data.igst = '';
                    data.cgst = '';
                    data.sgst = '';
                    data.ugst = '';
                    data.total_price = '';
                    data.hsn = '';
                    data.actual_price = '';
                    data.type = '';
                    this.alertService.showErrors('Selected Product already added');
                }
            } else {
                data.product_id = val;
                data.quantity = 1;
                let selectedProduct = {};
                this.products.forEach(product => {
                    if (product && (parseInt(product['id']) === parseInt(val))) {
                        selectedProduct = product;
                    }
                });
                data.unit_price = parseFloat(selectedProduct['discounted_price']);
                data.total_price = Math.round(parseFloat(selectedProduct['discounted_price']) + (parseFloat(selectedProduct['discounted_price']) * parseFloat(selectedProduct['igst']) / 100)) * data.quantity;
                data.taxPercentageAndAmount = selectedProduct['igst'] + '%' + ' - ₹' + (parseFloat(selectedProduct['discounted_price']) * parseFloat(selectedProduct['igst']) / 100);
                data.taxAmount = parseFloat(selectedProduct['discounted_price']) * parseFloat(selectedProduct['igst']) / 100;
                data.igst = selectedProduct['igst'];
                data.cgst = selectedProduct['cgst'];
                data.sgst = selectedProduct['sgst'];
                data.ugst = selectedProduct['ugst'];
                data.hsn = selectedProduct['hsn'];
                data.actual_price = selectedProduct['actual_price'];
                // data.type = 'CASH';

                let grandTotalArrays = [];
                let unitPriceTotalArrays = [];
                let taxAmountTotalArrays = [];
                this.addedProducts.forEach(addedProduct => {
                    if (addedProduct && addedProduct['total_price']) {
                        grandTotalArrays.push(addedProduct['total_price']);
                        unitPriceTotalArrays.push(addedProduct['unit_price'] * parseFloat(addedProduct['quantity']));
                        taxAmountTotalArrays.push(addedProduct['taxAmount'] * parseFloat(addedProduct['quantity']));
                    }
                });
                this.grandTotal = grandTotalArrays.reduce((a, b) => a + b, 0);
                this.unitPriceTotal = unitPriceTotalArrays.reduce((a, b) => a + b, 0);
                this.taxAmountTotal = taxAmountTotalArrays.reduce((a, b) => a + b, 0);
            }
        } else {
            data.product_id = '';
            data.quantity = 1;
            data.unit_price = '';
            data.total_price = '';
            data.taxPercentageAndAmount = '';
            data.taxAmount = '';
            data.igst = '';
            data.cgst = '';
            data.sgst = '';
            data.ugst = '';
            data.hsn = '';
            data.actual_price = '';
            data.type = '';
            this.alertService.showErrors('Product can\'t be blank');
        }
    }

    onQuantityChange(event, data) {
        const val = parseFloat(event.target.value);
        if (val && val > 0) {
            data.total_price = Math.round(parseFloat(data['unit_price']) + (parseFloat(data['unit_price']) * parseFloat(data['igst']) / 100)) * val;

            let grandTotalArrays = [];
            let unitPriceTotalArrays = [];
            let taxAmountTotalArrays = [];
            this.addedProducts.forEach(addedProduct => {
                if (addedProduct && addedProduct['total_price']) {
                    grandTotalArrays.push(addedProduct['total_price']);
                    unitPriceTotalArrays.push(addedProduct['unit_price'] * parseFloat(addedProduct['quantity']));
                    taxAmountTotalArrays.push(addedProduct['taxAmount'] * parseFloat(addedProduct['quantity']));
                }
            });
            this.grandTotal = grandTotalArrays.reduce((a, b) => a + b, 0);
            this.unitPriceTotal = unitPriceTotalArrays.reduce((a, b) => a + b, 0);
            this.taxAmountTotal = taxAmountTotalArrays.reduce((a, b) => a + b, 0);
        } else {
            data.quantity = 1;
        }
    }

    addMore() {
        if (this.addedProducts && this.addedProducts.length > 0) {
            let blankProduct = false;
            this.addedProducts.forEach(addedProduct => {
                if (!addedProduct || !addedProduct['product_id']) {
                    blankProduct = true;
                }
            });
            if (blankProduct) {
                this.alertService.showErrors('Please fill all the added entry to add new entry');
            } else {
                this.addedProducts.push({
                    'product_id': '',
                    'quantity': 1,
                    'unit_price': '',
                    'total_price': ''
                });
            }
        }
    }

    posOrder(content) {
        this.modalType = 'ADD_PRODUCT';
        this.modalService.open(content, {'size': 'xl'});
    }

    removeProduct(data) {
        if (this.addedProducts && this.addedProducts.length > 1) {
            this.addedProducts = Helper.removeElementFromArray(this.addedProducts, 'product_id', data['product_id']);
        } else {
            data.product_id = '';
            data.quantity = 1;
            data.unit_price = '';
            data.total_price = '';
        }
    }

    submit() {
    }

    addAddress() {
        if (this.addedProducts && this.addedProducts.length > 0 && this.addedProducts[0].product_id !== '') {
            this.modalType = 'ADD_ADDRESS';
        } else {
            this.alertService.showErrors('Please choose product(s) to add address !');
        }
    }

    search() {
        const params = {
            'order_id': (this.searchForm.value && this.searchForm.value.order_id) ? this.searchForm.value.order_id : '',
            'product_id': (this.searchForm.value && this.searchForm.value.product_id) ? this.searchForm.value.product_id : '',
            'delivery_status': (this.searchForm.value && this.searchForm.value.delivery_status) ? this.searchForm.value.delivery_status : ''
        };
        this.getOrders(params);
    }

    chooseAddress(id, status) {
        const params = {
            'status': status
        };
        this.addressService.updateStatus(id, this.userId, params).subscribe(data => {
            this.getAddress();
        });
    }

    addNewAddress() {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'address_1_shipping': (this.addAddressForm.value && this.addAddressForm.value.address_1_shipping) ? this.addAddressForm.value.address_1_shipping : '',
                'address_2_shipping': (this.addAddressForm.value && this.addAddressForm.value.address_2_shipping) ? this.addAddressForm.value.address_2_shipping : '',
                'landmark_shipping': (this.addAddressForm.value && this.addAddressForm.value.landmark_shipping) ? this.addAddressForm.value.landmark_shipping : '',
                'city_shipping': (this.addAddressForm.value && this.addAddressForm.value.city_shipping) ? this.addAddressForm.value.city_shipping : '',
                'pin_code_shipping': (this.addAddressForm.value && this.addAddressForm.value.pin_code_shipping) ? this.addAddressForm.value.pin_code_shipping : '',
                'state_id_shipping': (this.addAddressForm.value && this.addAddressForm.value.state_id_shipping) ? this.addAddressForm.value.state_id_shipping : '',
                'name_shipping': (this.addAddressForm.value && this.addAddressForm.value.name_shipping) ? this.addAddressForm.value.name_shipping : '',
                'contact_shipping': (this.addAddressForm.value && this.addAddressForm.value.contact_shipping) ? this.addAddressForm.value.contact_shipping : '',
                'address_1_billing': (this.addAddressForm.value && this.addAddressForm.value.address_1_billing) ? this.addAddressForm.value.address_1_billing : '',
                'address_2_billing': (this.addAddressForm.value && this.addAddressForm.value.address_2_billing) ? this.addAddressForm.value.address_2_billing : '',
                'landmark_billing': (this.addAddressForm.value && this.addAddressForm.value.landmark_billing) ? this.addAddressForm.value.landmark_billing : '',
                'city_billing': (this.addAddressForm.value && this.addAddressForm.value.city_billing) ? this.addAddressForm.value.city_billing : '',
                'pin_code_billing': (this.addAddressForm.value && this.addAddressForm.value.pin_code_billing) ? this.addAddressForm.value.pin_code_billing : '',
                'state_id_billing': (this.addAddressForm.value && this.addAddressForm.value.state_id_billing) ? this.addAddressForm.value.state_id_billing : '',
                'name_billing': (this.addAddressForm.value && this.addAddressForm.value.name_billing) ? this.addAddressForm.value.name_billing : '',
                'contact_billing': (this.addAddressForm.value && this.addAddressForm.value.contact_billing) ? this.addAddressForm.value.contact_billing : '',
            };
            this.submitting = true;
            this.addressService.createByPos(this.userId, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.addAddressForm.patchValue({
                        'address_1_shipping': '',
                        'address_2_shipping': '',
                        'landmark_shipping': '',
                        'city_shipping': '',
                        'pin_code_shipping': '',
                        'state_id_shipping': '',
                        'name_shipping': '',
                        'contact_shipping': '',
                        'address_1_billing': '',
                        'address_2_billing': '',
                        'landmark_billing': '',
                        'city_billing': '',
                        'pin_code_billing': '',
                        'state_id_billing': '',
                        'name_billing': '',
                        'contact_billing': '',
                        'discount': ''
                    });
                    this.getAddress();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].address_1_billing && error['error']['errors'].address_1_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address_1_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].landmark_billing && error['error']['errors'].landmark_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].landmark_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].city_billing && error['error']['errors'].city_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].city_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].pin_code_billing && error['error']['errors'].pin_code_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].pin_code_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].state_id_billing && error['error']['errors'].state_id_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].state_id_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].name_billing && error['error']['errors'].name_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].contact_billing && error['error']['errors'].contact_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].address_1_shipping && error['error']['errors'].address_1_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address_1_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].landmark_shipping && error['error']['errors'].landmark_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].landmark_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].city_shipping && error['error']['errors'].city_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].city_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].pin_code_shipping && error['error']['errors'].pin_code_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].pin_code_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].state_id_shipping && error['error']['errors'].state_id_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].state_id_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].name_shipping && error['error']['errors'].name_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].contact_shipping && error['error']['errors'].contact_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact_shipping[0]);
                        return;
                    }
                }
            });
        }
    }

    getPinCodeData(event, type) {
        if (event && event.target && event.target.value) {
            this.pinCodeLoader = true;
            this.stateService.getPinCodeDetails(event.target.value).subscribe(data => {
                if (data && data['data'].length > 0) {
                    if (type === 'SHIPPING') {
                        this.pinCodeShippingData = data['data'];
                        this.addAddressForm.patchValue({
                            'city_shipping': this.pinCodeShippingData[0]['District']
                        });
                        this.states.forEach(state => {
                            if (state && state['name'] && this.pinCodeShippingData[0]['State']) {
                                let checkSimiliarity = this.similarity(this.pinCodeShippingData[0]['State'], state['name']);
                                if (checkSimiliarity > 0.5) {
                                    this.addAddressForm.patchValue({
                                        'state_id_shipping': state.id
                                    });
                                }
                            }
                        });
                    } else {
                        this.pinCodeBillingData = data['data'];
                        this.addAddressForm.patchValue({
                            'city_billing': this.pinCodeBillingData[0]['District']
                        });
                        this.states.forEach(state => {
                            if (state && state['name'] && this.pinCodeBillingData[0]['State']) {
                                let checkSimiliarity = this.similarity(this.pinCodeBillingData[0]['State'], state['name']);
                                if (checkSimiliarity > 0.5) {
                                    this.addAddressForm.patchValue({
                                        'state_id_billing': state.id
                                    });
                                }
                            }
                        });
                    }
                    this.pinCodeLoader = false;
                }
            }, error => {
                this.alertService.showErrors('Please check the pin code you entered');
                this.pinCodeLoader = false;
            });
        }
    }

    similarity(s1, s2) {
        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
    }

    editDistance(s1, s2) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0) {
                    costs[j] = j;
                } else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) != s2.charAt(j - 1)) {
                            newValue = Math.min(Math.min(newValue, lastValue),
                                costs[j]) + 1;
                        }
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0) {
                costs[s2.length] = lastValue;
            }
        }
        return costs[s2.length];
    }

    sameBilling(type) {
        if (this.addAddressForm && this.addAddressForm.getRawValue() && (!this.addAddressForm.getRawValue().address_1_shipping || !this.addAddressForm.getRawValue().city_shipping || !this.addAddressForm.getRawValue().pin_code_shipping || !this.addAddressForm.getRawValue().state_id_shipping || !this.addAddressForm.getRawValue().name_shipping || !this.addAddressForm.getRawValue().contact_shipping)) {
            this.alertService.showErrors('Please fill required shipping address details');
        } else {
            if (type) {
                this.sameBillingAddress = type;
                this.addAddressForm.patchValue({
                    'address_1_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().address_1_shipping) ? this.addAddressForm.getRawValue().address_1_shipping : '',
                    'address_2_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().address_2_shipping) ? this.addAddressForm.getRawValue().address_2_shipping : '',
                    'landmark_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().landmark_shipping) ? this.addAddressForm.getRawValue().landmark_shipping : '',
                    'city_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().city_shipping) ? this.addAddressForm.getRawValue().city_shipping : '',
                    'pin_code_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().pin_code_shipping) ? this.addAddressForm.getRawValue().pin_code_shipping : '',
                    'state_id_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().state_id_shipping) ? this.addAddressForm.getRawValue().state_id_shipping : '',
                    'name_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().name_shipping) ? this.addAddressForm.getRawValue().name_shipping : '',
                    'contact_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().contact_shipping) ? this.addAddressForm.getRawValue().contact_shipping : '',
                });
            } else {
                this.sameBillingAddress = type;
                this.addAddressForm.patchValue({
                    'address_1_billing': '',
                    'address_2_billing': '',
                    'landmark_billing': '',
                    'city_billing': '',
                    'pin_code_billing': '',
                    'state_id_billing': '',
                    'name_billing': '',
                    'contact_billing': '',
                });
            }
        }
    }

    submitProductsAndAddress() {
        if (this.selectedAddress && this.selectedAddress.id) {
            const params = {
                'name': (this.posUser && this.posUser.name) ? this.posUser.name : '',
                'contact': (this.posUser && this.posUser.contact) ? this.posUser.contact : '',
                'amount': this.grandTotal,
                'products': (this.addedProducts && this.addedProducts.length > 0) ? JSON.stringify(this.addedProducts) : '',
                // 'type': 'CASH',
                'type': this.selectedDeliveryType,
                'delivery_amount': (this.addAddressForm.value && this.addAddressForm.value.delivery_amount) ? this.addAddressForm.value.delivery_amount : '',
                'address_id': this.selectedAddress.id,
                'discount': (this.addAddressForm.value && this.addAddressForm.value.discount) ? this.addAddressForm.value.discount : '',
            };
            this.transactionService.createByPos(this.userId, params).subscribe(data => {
                this.modalService.dismissAll('');
                this.getOrders();
            });
        } else {
            this.alertService.showErrors('Please choose Billing & Shipping address to proceed');
        }
    }
}
