<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 *ngIf="!data && appointmentCreateModalOpen" class="modal-title font-weight-bold">Create
                            Appointment</h4>
                        <h4 *ngIf="data && appointmentCreateModalOpen" class="modal-title font-weight-bold">Update
                            Appointment</h4>
                        <h4 *ngIf="appointmentStatusModalOpen" class="modal-title font-weight-bold">Update Appointment
                            Status</h4>
                        <button
                                (click)="modalService.dismissAll('')"
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="appointmentCreateModalOpen" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="createAppointmentForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="name">
                                                        Name
                                                    </label>
                                                    <input class="form-control" formControlName="name" id="name"
                                                           type="text"/>
                                                    <small *ngIf="submitted && createAppointmentForm.get('name').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="contact">
                                                        Contact
                                                    </label>
                                                    <input class="form-control" formControlName="contact" id="contact"
                                                           type="number"/>
                                                    <small *ngIf="submitted && createAppointmentForm.get('contact').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="gender">Gender</label>
                                                    <select class="form-control" formControlName="gender" id="gender">
                                                        <option value="">Select Gender</option>
                                                        <option value="MALE">Male</option>
                                                        <option value="FEMALE">Female</option>
                                                        <option value="OTHER">Others</option>
                                                    </select>
                                                    <small *ngIf="submitted && createAppointmentForm.get('gender').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="age">Age</label>
                                                    <input class="form-control" formControlName="age" id="age"
                                                           type="number"/>
                                                    <small *ngIf="submitted && createAppointmentForm.get('age').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="symptoms">
                                                        Symptoms
                                                    </label>
                                                    <textarea class="form-control resize-none"
                                                              formControlName="symptoms"
                                                              id="symptoms"></textarea>
                                                    <small *ngIf="submitted && createAppointmentForm.get('symptoms').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="mode">
                                                        Mode
                                                    </label>
                                                    <select class="form-control" formControlName="mode" id="mode">
                                                        <option value="">Select</option>
                                                        <option value="ONLINE">Online</option>
                                                        <option value="OFFLINE">Offline</option>
                                                    </select>
                                                    <small *ngIf="submitted && createAppointmentForm.get('mode').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="amount">
                                                        Amount
                                                    </label>&nbsp;
                                                    <label class="text-danger" for="amount" id="passwordHelpInline">
                                                        <sup style="top: unset;font-size: 140%;">*</sup> Excluding GST
                                                    </label>
                                                    <input aria-describedby="passwordHelpInline" class="form-control"
                                                           formControlName="amount"
                                                           id="amount"
                                                           type="number"/>
                                                    <small *ngIf="submitted && createAppointmentForm.get('amount').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="doctor">Doctor</label>
                                                    <select (change)="getDoctorData($event.target.value)"
                                                            class="form-control mb-1"
                                                            formControlName="doctor_id"
                                                            id="doctor">
                                                        <option value="">Select Doctor</option>
                                                        <option *ngFor="let doctor of doctors"
                                                                [value]="doctor.user?.id">{{doctor?.user?.name}}</option>
                                                    </select>
                                                    <small *ngIf="submitted && createAppointmentForm.get('doctor_id').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                    <span (click)="goToDocotorConsult()"
                                                          *ngIf="selectedDoctor && selectedDoctor['id']"
                                                          class="color-twitter cursor-pointer">View "{{selectedDoctor?.name}}
                                                        " Consults</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!data" class="row mt-1">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <h6>Add Consult</h6>
                                            </div>
                                        </div>
                                        <div *ngIf="!data" class="row mt-1">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="start_date_time">Start Date Time</label>
                                                    <input [min]="currentDateTime" [owlDateTimeTrigger]="dt1"
                                                           [owlDateTime]="dt1" class="form-control"
                                                           formControlName="start_date_time"
                                                           id="start_date_time"
                                                           placeholder="Date Time">
                                                    <owl-date-time #dt1 [startAt]="currentDateTime"></owl-date-time>
                                                    <small *ngIf="submitted && createAppointmentForm.get('start_date_time').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="end_date_time">End Date Time</label>
                                                    <input [min]="currentDateTime" [owlDateTimeTrigger]="dt2"
                                                           [owlDateTime]="dt2" class="form-control"
                                                           formControlName="end_date_time"
                                                           id="end_date_time" placeholder="Date Time">
                                                    <owl-date-time #dt2 [startAt]="currentDateTime"></owl-date-time>
                                                    <small *ngIf="submitted && createAppointmentForm.get('end_date_time').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!data" class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="remarks">Remarks</label>
                                                    <textarea class="form-control resize-none" formControlName="remarks"
                                                              id="remarks"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="appointmentStatusModalOpen" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="appointmentStatusUpdateForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="status">
                                                        Status
                                                    </label>
                                                    <select class="form-control" formControlName="status" id="status">
                                                        <option value="">Select Status</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="COMPLETED">Completed</option>
                                                        <option value="FAILED">Failed</option>
                                                    </select>
                                                    <small *ngIf="submitted && appointmentStatusUpdateForm.get('status').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button (click)="createAppointment()" *ngIf="!data && appointmentCreateModalOpen"
                                [disabled]="submitting" class="btn btn-success color-white pull-right mt-1 mb-1">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting && !data" class="fas fa-spinner fa-pulse mr-1"></i>Create
                        </button>
                        <button (click)="updateAppointment()" *ngIf="data && appointmentCreateModalOpen"
                                [disabled]="submitting" class="btn btn-success color-white pull-right mt-1 mb-1">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting && !data" class="fas fa-spinner fa-pulse mr-1"></i>Update
                        </button>
                        <button (click)="updateAppointmentStatus()"
                                *ngIf="appointmentData && appointmentStatusModalOpen"
                                [disabled]="submitting"
                                class="btn btn-success color-white pull-right mt-1 mb-1">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting && !data" class="fas fa-spinner fa-pulse mr-1"></i>Update Status
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Appointments</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="add(content)"
                            *ngIf="user && ['CONSULTANT'].indexOf(user['user_type']) !== -1"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Create Appointment
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="appointment_id"
                                           placeholder="Appointment Id"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="last_consult_date" placeholder="Date"
                                           type="date">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="doctor_id">
                                        <option value="">Select Doctor</option>
                                        <option *ngFor="let doctor of doctors"
                                                [value]="doctor.id">{{doctor?.name}}</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="contact" placeholder="Contact"
                                           type="number">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="status">
                                        <option value="">Select Status</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="COMPLETED">Completed</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Appointment Id</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Invoice No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Transaction Id</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Age</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Gender</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Doctor</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Mode</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Last Consult Date Time</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="appointments && appointments.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let appointment of appointments; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <i (click)="edit(content, appointment)"
                                                   *ngIf="user && ['CONSULTANT'].indexOf(user['user_type']) !== -1"
                                                   aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit"></i>
                                                <i (click)="openStatusModal(content, appointment)"
                                                   *ngIf="appointment"
                                                   class="fas fa-calendar-check f_20 ml-5px text-secondary cursor-pointer mt-1"
                                                   title="Appointment Status Update"></i>
                                                <i (click)="goTo(appointment)"
                                                   *ngIf="appointment && appointment['doctor_id']"
                                                   aria-hidden="true"
                                                   class="fas fa-notes-medical f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Consults"></i>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{appointment?.status}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{appointment?.appointment_id}}</h6></td>
                                            <td class="text-center">
                                                <h6 (click)="downloadInvoice(appointment)"
                                                    *ngIf="appointment && appointment['invoice_no']"
                                                    class="mt-1 text-info cursor-pointer">{{appointment?.invoice_no}}</h6>
                                                <h6 *ngIf="!appointment || !appointment['invoice_no']" class="mt-1">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="appointment && appointment['transaction_id']"
                                                    class="mt-1">{{appointment?.transaction_id}}</h6>
                                                <h6 *ngIf="!appointment || !appointment['transaction_id']" class="mt-1">
                                                    -</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{appointment?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{appointment?.contact}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{appointment?.age}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{appointment?.gender}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{appointment?.doctor?.user?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{appointment?.mode}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{appointment?.last_consult_start_date_time}}</h6></td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="13"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="appointments && appointments.length > 0; else noDataToShowMobile"
                 class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let appointment of appointments; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{appointment?.appointment_id}}</h4>
                                </div>
                                <div class="col-6">
                                    <i (click)="edit(content, appointment)"
                                       aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit"></i>
                                    <i (click)="goTo(appointment)"
                                       aria-hidden="true"
                                       class="fas fa-notes-medical f_20 ml-5px text-danger cursor-pointer mt-1"
                                       title="Consults"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - {{appointment?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{appointment?.contact}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Doctor - {{appointment?.doctor?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Mode - {{appointment?.mode}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Last Consult Date Time - {{appointment?.last_consult_date_time}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - {{appointment?.status}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                [boundaryLinks]="true" [collectionSize]="pagination.total"
                                [maxSize]="5" [pageSize]="pagination.perpage"
                                class="pagination-primary">
                    <ng-template ngbPaginationPrevious>
                        <span [class]="'font-weight-bolder'" [data-feather]="'chevron-left'"
                              [size]="18">
                        </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <span [class]="'font-weight-bolder'" [data-feather]="'chevron-right'"
                              [size]="18">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
