import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SymptomsRoutingModule } from './symptoms-routing.module';
import {SymptomsComponent} from './symptoms.component';
import { AddSymptomComponent } from './add-symptom/add-symptom.component';
import {CoreSidebarModule} from '../../../@core/components';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreDirectivesModule} from '../../../@core/directives/directives';


@NgModule({
  declarations: [SymptomsComponent, AddSymptomComponent],
    imports: [
        CommonModule,
        SymptomsRoutingModule,
        CoreSidebarModule,
        ReactiveFormsModule,
        CoreDirectivesModule
    ],
  exports: [SymptomsComponent,AddSymptomComponent]
})

export class SymptomsModule {}
