import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppointmentsComponent} from './appointments.component';
import {ConsultsComponent} from './consults/consults.component';
import {PrescriptionsComponent} from './prescriptions/prescriptions.component';
import {AppointmentDocsComponent} from './appointment-docs/appointment-docs.component';
import {AddPrescriptionComponent} from './prescriptions/add-prescription/add-prescription.component';

const routes: Routes = [
    {
        path: 'appointments',
        component: AppointmentsComponent,
        data: {animation: 'appointments'}
    },
    {
        path: 'appointment/:id/consults',
        component: ConsultsComponent,
        data: {animation: 'consults'}
    },
    {
        path: 'appointment/:appointId/consult/:id/prescriptions',
        component: PrescriptionsComponent,
        data: {animation: 'prescriptions'}
    },
    {
        path: 'appointment/:appointId/consult/:id/prescriptions/add-prescription',
        component: AddPrescriptionComponent,
        data: {animation: 'add-prescriptions'}
    },
    {
        path: 'appointment/:appointId/appointment-docs',
        component: AppointmentDocsComponent,
        data: {animation: 'apppointment-docs'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppointmentsRoutingModule {
}
