<div class="content-wrapper">
    <div class="content-body">
        <section id="products">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="viewDescription">View Description
                            - {{viewDescription?.name}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="viewIndication">View Indication
                            - {{viewIndication?.name}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="viewAction">View Action
                            - {{viewAction?.name}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="viewDisclaimer">View Disclaimer
                            - {{viewDisclaimer?.name}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="viewContraindications">View Contraindications
                            - {{viewContraindications?.name}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="viewImages">View Images
                            - {{viewImages?.name}}</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="viewDescription">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5 class="p-1">{{viewDescription?.description}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="viewIndication">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h5 class="p-1">{{viewIndication?.indication}}</h5>
                        </div>
                    </div>
                    <div class="row" *ngIf="viewAction">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h5 class="p-1">{{viewAction?.action}}</h5>
                        </div>
                    </div>
                    <div class="row" *ngIf="viewDisclaimer">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h5 class="p-1">{{viewDisclaimer?.disclaimer}}</h5>
                        </div>
                    </div>
                    <div class="row" *ngIf="viewImages">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h5 class="p-1">{{viewImages?.images}}</h5>
                            <div class="row" *ngIf="imageChangedEvent">
                                <div class="text-center col-md-12">
                                    <image-cropper
                                            [imageChangedEvent]="imageChangedEvent"
                                            [maintainAspectRatio]="true"
                                            [aspectRatio]="1000 / 1500"
                                            [resizeToWidth]="500"
                                            format="png, jpeg, jpg"
                                            (imageCropped)="imageCropped($event)"
                                            (imageLoaded)="imageLoaded()"
                                            (cropperReady)="cropperReady()"
                                            (loadImageFailed)="loadImageFailed()">
                                    </image-cropper>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="text-center">
                                        <img [src]="profileImageUploadArea" class="img-fluid width-85-per"
                                             alt="Upload Image"
                                             (click)="imageProfileUpload.click()" *ngIf="!imageChangedEvent"/>
                                        <input class="form-control-file display-hidden" type="file"
                                               (change)="onImageChange($event)" #imageProfileUpload>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                     *ngIf="viewImages && viewImages['product_image']">
                                    <div class="row mt--5px mt-12px text-center">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ng-container *ngFor="let image of viewImages['product_image']">
                                                <i class="fas fa-times-circle text-danger mr-8px cursor-pointer remove-product-image"
                                                   (click)="deleteProductImage(image)"></i>
                                                <img class="mr-8px ml-5px product-img cursor-pointer"
                                                     src="{{image['file']['url']}}"
                                                     (click)="goTo(image['file']['url'])"/>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                    <button class="btn btn-danger color-white pull-right mt-1 mb-1 mr-1"
                                            [disabled]="submitting"
                                            (click)="modalService.dismissAll('')" *ngIf="!imageChangedEvent">
                                        Close
                                    </button>
                                    <button class="btn btn-primary btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                            *ngIf="imageChangedEvent" (click)="saveProductImage()">
                                        <i class="fa fa-save mr-1"></i> Save
                                    </button>
                                    <button class="btn btn-warning btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                            (click)="imageProfileUpload.click()">New Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="viewContraindications">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h5 class="p-1">{{viewContraindications?.contraindications}}</h5>
                        </div>
                    </div>
                    <div class="modal-footer" *ngIf="!viewImages">
                        <button class="btn btn-danger color-white pull-right" [disabled]="submitting"
                                (click)="modalService.dismissAll('')">
                            Close
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Products</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Product
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name" placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="actual_price"
                                           placeholder="Actual Price">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="discounted_price"
                                           placeholder="Discounted Price">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="category">
                                        <option value="">Select Category</option>
                                        <option [value]="category.id"
                                                *ngFor="let category of categories">{{category?.name}}</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="sub_category">
                                        <option value="">Select Sub Category</option>
                                        <option [value]="subCategory.id"
                                                *ngFor="let subCategory of subCategories">{{subCategory?.name}}</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Alias</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Product Code</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Tagline</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Actual
                                                Price</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Discount
                                                Price</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Category</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Sub
                                                Category</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold" style="width: 130px">Product
                                                Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Images</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Key Ingredients</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Dosage</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Action</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Disclaimer</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Contraindications</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Manufacturer
                                                Details</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Rating</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Url</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">HSN</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">CGST</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">SGST</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">IGST</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">UGST</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Country</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="products && products.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let product of products; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(product)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Admin" *ngIf="product?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', product)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Active Admin" *ngIf="product?.status === 'INACTIVE'"
                                                   (click)="updateStatus('ACTIVE', product)"></i>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.product_alias}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.product_code}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.tagline}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.actual_price}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.discounted_price}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.category?.name}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{product?.sub_category?.name}}</h6></td>
                                            <td class="text-center">
                                                <div >
                                                    <form [formGroup]="stockStatusForm">
                                                        <select class="form-select badge badge-primary form-control w-100" aria-label="Default select example" formControlName="stock_status" value="{{product?.product_status}}" (change)="updateStockStatus(product)">
                                                            <option value=""> Select</option>
                                                            <option value="NEW" [selected]="product.product_status === 'NEW'">New</option>
                                                            <option value="IN_STOCK" [selected]="product.product_status === 'IN_STOCK'">In Stock</option>
                                                            <option value="SOLD_OUT" [selected]="product.product_status === 'SOLD_OUT'">Sold Out</option>
                                                        </select>
                                                    </form>
                                                </div>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="viewImagesModel(content, product)">View</h6>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="viewDescriptionModal(content, product)">
                                                    View</h6>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="GoToKeyIngredient(product)">
                                                    View</h6>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="GoToDosage(product)">
                                                    View</h6>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="GoToProductsActionContent(product)">
                                                    View</h6>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="viewDisclaimerModel(content, product)">
                                                    View</h6>
                                            </td>
                                            <td class="text-center cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="viewContraindicationsModel(content, product)">View</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{product?.manufacturer_details}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{product?.rating}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{product?.url}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.hsn}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.cgst}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.sgst}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.igst}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.ugst}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{product?.country?.name}}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="16" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="products && products.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let product of products; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{product?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Executive" *ngIf="product?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', product)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Executive" *ngIf="product?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', product)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(product)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!--        <div class="row p-2">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                            name
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                            tagline -
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                            Contact -
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                            Password - <span class="text-info">Update</span>
                                        </div>
                             </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious>
                                  <span [data-feather]="'chevron-left'" [size]="18"
                                        [class]="'font-weight-bolder'">
                                  </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                                  <span [data-feather]="'chevron-right'" [size]="18"
                                        [class]="'font-weight-bolder'">
                                  </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-product"
            overlayClass="modal-backdrop">
        <app-add-product [data]="data" (valueSubmitted)="getData($event)"></app-add-product>
    </core-sidebar>
</div>


