<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add User</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update User</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addUserForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input class="form-control" id="name" type="text" formControlName="name">
                                        <small *ngIf="submitted && addUserForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control" id="email" type="text" formControlName="email">
                                        <small *ngIf="submitted && addUserForm.get('email').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addUserForm.controls['email'].errors && addUserForm.controls['email'].errors.pattern"
                                               class="text-danger">Invalid email format</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contact">Contact</label>
                                        <input class="form-control" id="contact" type="number"
                                               formControlName="contact">
                                        <small *ngIf="submitted && addUserForm.get('contact').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                        <small *ngIf="submitted && addUserForm.controls['contact'].errors && addUserForm.controls['contact'].errors.pattern"
                                               class="text-danger">Invalid contact format</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!data || !data.id">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="password" class="d-flex">
                                            Password
                                            <i (click)="inputTypePassword = 'text'"
                                               [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                               class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                            <i (click)="inputTypePassword = 'password'"
                                               [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                               class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                        </label>
                                        <input class="form-control" id="password" [type]="inputTypePassword"
                                               formControlName="password">
                                        <small *ngIf="submitted && addUserForm.get('password').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="reset" rippleEffect class="btn btn-danger" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>

