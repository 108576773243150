import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD',
        type: 'item',
        icon: 'fas fa-sign',
        url: 'dashboard',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'DOCTOR', 'CONSULTANT']
    },
    {
        id: 'admin',
        title: 'Admins',
        translate: 'MENU.ADMINS',
        type: 'item',
        icon: 'fas fa-user-shield',
        url: 'admins',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'SubAdmins',
        title: 'Sub Admins',
        translate: 'MENU.SUBADMINS',
        type: 'item',
        icon: 'fas fa-user-friends',
        url: 'sub-admins',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'Symptoms',
        title: 'Symptoms',
        translate: 'MENU.SYMPTOMS',
        type: 'item',
        icon: 'fas fa-thermometer',
        url: 'symptoms',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'doctor',
        title: 'Doctors',
        translate: 'MENU.DOCTORS',
        type: 'item',
        icon: 'fas fa-user-md',
        url: 'doctors',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'CONSULTANT']
    },
    {
        id: 'consultant',
        title: 'Consultants',
        translate: 'MENU.CONSULTANTS',
        type: 'item',
        icon: 'fas fa-user-tie',
        url: 'consultants',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'users',
        title: 'Users',
        translate: 'MENU.USERS',
        type: 'item',
        icon: 'fas fa-users',
        url: 'users',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'CONSULTANT']
    },
    {
        id: 'appointments',
        title: 'Appointments',
        translate: 'MENU.APPOINTMENTS',
        type: 'item',
        icon: 'fas fa-calendar-check',
        url: 'appointments',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'DOCTOR', 'CONSULTANT']
    },
    {
        id: 'categories',
        title: 'Categories',
        translate: 'MENU.CATEGORIES',
        type: 'item',
        icon: 'fas fa-solid fa-list',
        url: 'categories',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'SubCategories',
        title: 'Sub Categories',
        translate: 'MENU.SUBCATEGORIES',
        type: 'item',
        icon: 'fas fa-ellipsis-v',
        url: 'sub-categories',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'products',
        title: 'Products',
        translate: 'MENU.PRODUCTS',
        type: 'item',
        icon: 'fas fa-solid fa-pills',
        url: 'products',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'faqs',
        title: 'FAQs',
        translate: 'MENU.FAQS',
        type: 'item',
        icon: 'fas fa-question',
        url: 'faqs',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'testimonials',
        title: 'Testimonials',
        translate: 'MENU.TESTIMONIALS',
        type: 'item',
        icon: 'fas fa-comment',
        url: 'testimonials',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'transactions',
        title: 'Transactions',
        translate: 'MENU.TRANSACTIONS',
        type: 'item',
        icon: 'fas fa-dollar',
        url: 'transactions',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'orders',
        title: 'Orders',
        translate: 'MENU.ORDERS',
        type: 'item',
        icon: 'fas fa-cart-arrow-down',
        url: 'orders',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'CONSULTANT']
    },
    {
        id: 'pos',
        title: 'POS',
        translate: 'MENU.POS',
        type: 'item',
        icon: 'fas fa-credit-card',
        url: 'pos',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'CONSULTANT']
    },
    {
        id: 'blogs',
        title: 'Blogs',
        translate: 'MENU.BLOGS',
        type: 'item',
        icon: 'fas fa-newspaper',
        url: 'blogs',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'BlogsHindi',
        title: 'Blogs Hindi',
        translate: 'MENU.BLOGSHINDI',
        type: 'item',
        icon: 'fas fa-newspaper',
        url: 'blogs-hindi',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'MetaTags',
        title: 'Meta Tags',
        translate: 'MENU.METATAGS',
        type: 'item',
        icon: 'fas fa-user-friends',
        url: 'meta-tags',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'ContactUsData',
        title: 'Contact Us',
        translate: 'MENU.CONTACTUSDATA',
        type: 'item',
        icon: 'fas fa-users',
        url: 'contactus-data',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'SelfAssessment',
        title: 'Self Assessment',
        translate: 'MENU.SELFASSEMENT',
        type: 'item',
        icon: 'fas fa-users',
        url: 'self-assessment',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
];
