<div class="row">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <h2 class="mt-5px">Symptoms</h2>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
        <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                (click)="showSearch = !showSearch">
            <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
        </button>
        <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                (click)="add()">
            <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Symptoms
        </button>
    </div>
</div>
<div class="row search-card mt-3" *ngIf="showSearch">
    <div class="col-12">
        <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
            <div class="row">
                <div class="col-md-10 col-lg-10 col-sm-10 col-xs-12">
                    <fieldset class="form-group">
                        <input class="form-control" type="text" formControlName="name" placeholder="Name">
                    </fieldset>
                </div>
                <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                    <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                            (click)="search()"
                            [disabled]="submitting">
                        <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="row d-none d-md-block mt-3">
    <div class="col-12">
        <div class="card">
            <div class="container-fluid">
                <div class="row overflow-x-scroll overflow-table">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <table class="table table-list text-center">
                            <thead>
                            <tr>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let sysdetail of sysdetails">
                                <td class="text-center"><h6 class="mt-1">{{sysdetail?.sno}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{sysdetail?.name}}</h6></td>
                                <td class="text-center">
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                       title="Edit"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                       title="In Active Admin"
                                    ></i>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-symptom"
            overlayClass="modal-backdrop">
        <app-add-symptom [data]="data" (valueSubmitted)="getData($event)"></app-add-symptom>
    </core-sidebar>
</div>

