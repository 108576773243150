import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesRoutingModule } from './categories-routing.module';
import {CategoriesComponent} from './categories.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import {CoreSidebarModule} from '../../../@core/components';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CategoriesComponent,AddCategoryComponent],
    imports: [
        CommonModule,
        CategoriesRoutingModule,
        CoreSidebarModule,
        ReactiveFormsModule,
        CoreDirectivesModule,
        NgbPaginationModule
    ],
  exports: [ CategoriesComponent,AddCategoryComponent]
})
export class CategoriesModule { }
