import {Component, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {AdminService} from '../../shared/services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnChanges {

  data: any;
  id: any;
  showSearch = false;
  admins = [];
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  submitted: any = false;
  submitting: any = false;
  updatePasswordForm: FormGroup;

  details: any = [
    {
      SNO: 1,
      name: 'Rahul',
      testimonial: 'osm',
      rating:5,
      action: ''
    }
    , {
      SNO: 2,
      name: 'admin 1',
      testimonial: 'some thing',
      rating:4.5,
      action: ''
    }
    , {
      SNO: 3,
      name: 'admin 3',
      testimonial: 'Nothing ',
      rating:3.9,
      action: ''
    }
    , {
      SNO: 4,
      name: 'admin 4',
      testimonial: 'i am thinking',
      rating:2,
      action: ''
    }
  ];

  constructor(private _coreSidebarService: CoreSidebarService,
              private adminService: AdminService,
              public modalService: NgbModal,
              private fb: FormBuilder) {
  }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh() {
    this.updatePasswordForm = this.fb.group({
      'password': ['']
    });
    this.searchForm = this.fb.group({
      'name': [''],
      'testimonial': [''],
      'rating': [''],
    });
    this.get();
  }

  add(): void {
    this.data = undefined;
    this.toggleSidebar();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-testimonial').toggleOpen();
  }

  search() {
    const params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'testimonial': (this.searchForm.value && this.searchForm.value.testimonial) ? this.searchForm.value.testimonial : '',
      'rating': (this.searchForm.value && this.searchForm.value.rating) ? this.searchForm.value.rating : '',
    };
    this.get(params);
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.admins = [];
    this.adminService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.admins = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.admins = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  getData(valueSubmit: any): void {
    if (valueSubmit) {
      this.get({page: 1, total: null, perpage: 15, pages: null});
      this.toggleSidebar();
    }
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'testimonial': (this.searchForm && this.searchForm.value && this.searchForm.value.testimonial) ? this.searchForm.value.testimonial : '',
      'rating': (this.searchForm && this.searchForm.value && this.searchForm.value.rating) ? this.searchForm.value.rating : '',
    };
    this.get(params);
  }
  edit(data) {
    this.data = data;
    this.toggleSidebar();
  }
  updateStatus(status, data) {
    this.submitted = true;
    if (this.submitted) {
      const params = {
        'status': status
      };
      this.submitting = true;
      this.adminService.updateStatus(data.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
        this.modalService.dismissAll('');
        this.get();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
      });
    }
  }


}



