import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {DoctorService} from '../../../shared/services/doctor.service';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
    selector: 'app-add-doctor',
    templateUrl: './add-doctor.component.html',
    styleUrls: ['./add-doctor.component.scss']
})

export class AddDoctorComponent implements OnChanges {
    addDoctorForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    user: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private doctorService: DoctorService) {
        this.user = StorageService.getItem('self');
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-admin').toggleOpen();
    }

    refresh() {
        this.addDoctorForm = this.fb.group({
            'name': ['', Validators.required],
            'username': ['', Validators.required],
            'link': ['', Validators.required],
            'video_username': ['', Validators.required],
            'specialisation': [''],
            'medical_registration_number': [''],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'password': ['', (!this.data || !this.data['id']) ? Validators.required : ''],
            'gender': ['', Validators.required],
            'work_status': ['', Validators.required],
            'work_hours': ['', Validators.required],
            'experience': ['', Validators.required],
            'remark': ['', Validators.required],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addDoctorForm.patchValue({
            'name': (this.data && this.data['user']['name']) ? this.data['user']['name'] : '',
            'username': (this.data && this.data['user']['username']) ? this.data['user']['username'] : '',
            'link': (this.data && this.data['link']) ? this.data['link'] : '',
            'video_username': (this.data && this.data['video_username']) ? this.data['video_username'] : '',
            'specialisation': (this.data && this.data['specialisation']) ? this.data['specialisation'] : '',
            'medical_registration_number': (this.data && this.data['medical_registration_number']) ? this.data['medical_registration_number'] : '',
            'email': (this.data && this.data['user']['email']) ? this.data['user']['email'] : '',
            'contact': (this.data && this.data['user']['contact']) ? this.data['user']['contact'] : '',
            'gender': (this.data && this.data['gender']) ? this.data['gender'] : '',
            'work_status': (this.data && this.data['work_status']) ? this.data['work_status'] : '',
            'work_hours': (this.data && this.data['work_hours']) ? this.data['work_hours'] : '',
            'experience': (this.data && this.data['experience']) ? this.data['experience'] : '',
            'remark': (this.data && this.data['remark']) ? this.data['remark'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addDoctorForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addDoctorForm.value && this.addDoctorForm.value.name) ? this.addDoctorForm.value.name : '',
                'username': (this.addDoctorForm.value && this.addDoctorForm.value.username) ? this.addDoctorForm.value.username : '',
                'link': (this.addDoctorForm.value && this.addDoctorForm.value.link) ? this.addDoctorForm.value.link : '',
                'video_username': (this.addDoctorForm.value && this.addDoctorForm.value.video_username) ? this.addDoctorForm.value.video_username : '',
                'specialisation': (this.addDoctorForm.value && this.addDoctorForm.value.specialisation) ? this.addDoctorForm.value.specialisation : '',
                'medical_registration_number': (this.addDoctorForm.value && this.addDoctorForm.value.medical_registration_number) ? this.addDoctorForm.value.medical_registration_number : '',
                'email': (this.addDoctorForm.value && this.addDoctorForm.value.email) ? this.addDoctorForm.value.email : '',
                'contact': (this.addDoctorForm.value && this.addDoctorForm.value.contact) ? this.addDoctorForm.value.contact : '',
                'password': (this.addDoctorForm.value && this.addDoctorForm.value.password) ? this.addDoctorForm.value.password : '',
                'gender': (this.addDoctorForm.value && this.addDoctorForm.value.gender) ? this.addDoctorForm.value.gender : '',
                'work_status': (this.addDoctorForm.value && this.addDoctorForm.value.work_status) ? this.addDoctorForm.value.work_status : '',
                'work_hours': (this.addDoctorForm.value && this.addDoctorForm.value.work_hours) ? this.addDoctorForm.value.work_hours : '',
                'experience': (this.addDoctorForm.value && this.addDoctorForm.value.experience) ? this.addDoctorForm.value.experience : '',
                'remark': (this.addDoctorForm.value && this.addDoctorForm.value.remark) ? this.addDoctorForm.value.remark : '',
            };
            this.submitting = true;
            this.doctorService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addDoctorForm.reset();
                    this.addDoctorForm.patchValue({
                        'name': '',
                        'username': '',
                        'link': '',
                        'video_username': '',
                        'specialisation': '',
                        'medical_registration_number': '',
                        'email': '',
                        'contact': '',
                        'password': '',
                        'gender': '',
                        'work_status': '',
                        'work_hours': '',
                        'experience': '',
                        'remark': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].username[0]);
                        return;
                    }
                    if (error['error']['errors'].link && error['error']['errors'].link.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].link[0]);
                        return;
                    }
                    if (error['error']['errors'].video_username && error['error']['errors'].video_username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].video_username[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gender[0]);
                        return;
                    }
                    if (error['error']['errors'].work_status && error['error']['errors'].work_status.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].work_status[0]);
                        return;
                    }
                    if (error['error']['errors'].work_hours && error['error']['errors'].work_hours.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].work_hours[0]);
                        return;
                    }
                    if (error['error']['errors'].experience && error['error']['errors'].experience.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].experience[0]);
                        return;
                    }
                    if (error['error']['errors'].remark && error['error']['errors'].remark.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].remark[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addDoctorForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addDoctorForm.value && this.addDoctorForm.value.name) ? this.addDoctorForm.value.name : '',
                'username': (this.addDoctorForm.value && this.addDoctorForm.value.username) ? this.addDoctorForm.value.username : '',
                'link': (this.addDoctorForm.value && this.addDoctorForm.value.link) ? this.addDoctorForm.value.link : '',
                'video_username': (this.addDoctorForm.value && this.addDoctorForm.value.video_username) ? this.addDoctorForm.value.video_username : '',
                'specialisation': (this.addDoctorForm.value && this.addDoctorForm.value.specialisation) ? this.addDoctorForm.value.specialisation : '',
                'medical_registration_number': (this.addDoctorForm.value && this.addDoctorForm.value.medical_registration_number) ? this.addDoctorForm.value.medical_registration_number : '',
                'email': (this.addDoctorForm.value && this.addDoctorForm.value.email) ? this.addDoctorForm.value.email : '',
                'contact': (this.addDoctorForm.value && this.addDoctorForm.value.contact) ? this.addDoctorForm.value.contact : '',
                'gender': (this.addDoctorForm.value && this.addDoctorForm.value.gender) ? this.addDoctorForm.value.gender : '',
                'work_status': (this.addDoctorForm.value && this.addDoctorForm.value.work_status) ? this.addDoctorForm.value.work_status : '',
                'work_hours': (this.addDoctorForm.value && this.addDoctorForm.value.work_hours) ? this.addDoctorForm.value.work_hours : '',
                'experience': (this.addDoctorForm.value && this.addDoctorForm.value.experience) ? this.addDoctorForm.value.experience : '',
                'remark': (this.addDoctorForm.value && this.addDoctorForm.value.remark) ? this.addDoctorForm.value.remark : '',
            };
            this.submitting = true;
            this.doctorService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errorDetails']) {
                    if (error['error']['errorDetails']) {
                        this.alertService.showErrors(error['error']['errorDetails']);
                        return;
                    }
                    // if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].email[0]);
                    //     return;
                    // }
                    // if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].contact[0]);
                    //     return;
                    // }
                    // if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].gender[0]);
                    //     return;
                    // }
                    // if (error['error']['errors'].work_status && error['error']['errors'].work_status.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].work_status[0]);
                    //     return;
                    // }
                    // if (error['error']['errors'].work_hours && error['error']['errors'].work_hours.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].work_hours[0]);
                    //     return;
                    // }
                    // if (error['error']['errors'].experience && error['error']['errors'].experience.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].experience[0]);
                    //     return;
                    // }
                    // if (error['error']['errors'].remark && error['error']['errors'].remark.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].remark[0]);
                    //     return;
                    // }
                }
            });
        }
    }
}
