import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConsultantsComponent} from './consultants.component';
import {AddConsultantComponent} from './add-consultant/add-consultant.component';
import {ConsultantAppointmentsComponent} from './consultant-appointments/consultant-appointments.component';

const routes: Routes = [
    {
        path: 'consultants',
        component: ConsultantsComponent,
        data: {animation: 'consultants'}
    },
    {
        path: 'consultant/add',
        component: AddConsultantComponent,
        data: {animation: 'consultant/add'}
    },
    {
        path: 'consultant/:id/appointments',
        component: ConsultantAppointmentsComponent,
        data: {animation: 'consultant/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ConsultantsRoutingModule {
}
