import {Component, OnInit} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup} from '@angular/forms';

import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../../shared/services/admin.service';
import {BlogService} from '../../shared/services/blog.service';
import {AlertService} from '../../shared/services/alert.service';
import {MetaTagsService} from '../../shared/services/meta-tags.service';

@Component({
    selector: 'app-meta-tags',
    templateUrl: './meta-tags.component.html',
    styleUrls: ['./meta-tags.component.scss']
})
export class MetaTagsComponent implements OnInit {

    showSearch = false;
    metaTags = [];
    id: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    data: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private adminService: AdminService,
                private blogServices: BlogService,
                private alertService: AlertService,
                private metaTagsService: MetaTagsService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        // this.updatePasswordForm = this.fb.group({
        //   'password': ['']
        // });
        this.searchForm = this.fb.group({
            'title': ['']
        });
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-meta-tags').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.metaTags = [];
        this.metaTagsService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.metaTags = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.metaTags = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'title': (this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
        };
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.metaTagsService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }
}
