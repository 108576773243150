import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class PosService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.POS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.POS_COUNT());
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.POS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.POS(id), data);
    }

    orderDeliveryStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.ORDER_DELIVERY_STATUS(id), data);
    }

    fetchUser(data): Observable<any> {
        return this.postRequest(AppUrl.POS_FETCH_USER(), data);
    }

    getUser(id): Observable<any> {
        return this.getRequest(AppUrl.POS_GET_USER(id));
    }
}
