import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {TransactionService} from '../../shared/services/transaction.service';
import {OrderService} from '../../shared/services/order.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {StorageService} from '../../shared/services/storage.service';
import {environment} from '../../../environments/environment';
import {PosService} from '../../shared/services/pos.service';
import {AlertService} from '../../shared/services/alert.service';

@Component({
    selector: 'app-pos',
    templateUrl: './pos.component.html',
    styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {
    data: any;
    showSearch = false;
    transactions: any = [];
    fetchUserDetailsForm: FormGroup;
    addUserForm: FormGroup;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    submitted: any = false;
    submitting: any = false;
    selectedTransactionData: any;
    user: any;
    modalType: any;
    addUserContact: any;
    updateDeliveryStatusForm: FormGroup;
    updateTransactionStatusForm: FormGroup;

    constructor(private _coreSidebarService: CoreSidebarService,
                private transactionService: TransactionService,
                private orderService: OrderService,
                public modalService: NgbModal,
                private router: Router,
                private fb: FormBuilder,
                private posService: PosService,
                public alertService: AlertService) {
        this.user = StorageService.getItem('self');
        if (!this.user || !this.user['user_type'] || (this.user['user_type'] !== 'ADMIN' && this.user['user_type'] !== 'SUPER_ADMIN' && this.user['user_type'] !== 'CONSULTANT')) {
            this.router.navigateByUrl('dashboard');
        }
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'transaction_id': [''],
            'invoice_no': [''],
            'name': [''],
            'contact': [''],
            'status': ['']
        });
        this.addUserForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
        });
        this.fetchUserDetailsForm = this.fb.group({
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])]
        });
        this.updateDeliveryStatusForm = this.fb.group({
            'delivery_status': ['', Validators.required]
        });
        this.updateTransactionStatusForm = this.fb.group({
            'status': ['', Validators.required]
        });
        this.get();
    }

    add(): void {
        this.data = undefined;
    }

    search() {
        const params = {
            'transaction_id': (this.searchForm.value && this.searchForm.value.transaction_id) ? this.searchForm.value.transaction_id : '',
            'invoice_no': (this.searchForm.value && this.searchForm.value.invoice_no) ? this.searchForm.value.invoice_no : '',
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : ''
        };
        this.get(params);
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.transactions = [];
        this.transactionService.getByPos(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.transactions = data['data'];
                if (this.transactions && this.transactions.length > 0) {
                    this.transactions.forEach(order => {
                        if (order && order['orders'] && order['orders'].length > 0) {
                            order['orders'].forEach(od => {
                                od['total_price'] = Math.round((parseFloat(od['discounted_price']) * parseFloat(od['quantity'])) + ((parseFloat(od['discounted_price']) * parseFloat(od['quantity'])) * parseFloat(od['igst']) / 100));
                            });
                        }
                    });
                }
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.transactions = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
            'invoice_no': (this.searchForm.value && this.searchForm.value.invoice_no) ? this.searchForm.value.invoice_no : ''
        };
        this.get(params);
    }

    /*changeDeliveryStatus(content, data) {
        this.modalService.open(content, {'size': 'lg'});
        this.selectedTransactionData = data;
    }*/

    posFetchUser(content) {
        this.modalType = 'FETCH_DETAILS';
        this.modalService.open(content, {'size': 'md'});
    }

    fetchDetail() {
        this.submitted = true;
        if (!this.fetchUserDetailsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'contact': (this.fetchUserDetailsForm.value && this.fetchUserDetailsForm.value.contact) ? this.fetchUserDetailsForm.value.contact : '',
            };
            this.submitting = true;
            this.posService.fetchUser(params).subscribe(data => {
                if (data && data.id) {
                    this.submitting = false;
                    this.submitted = false;
                    this.fetchUserDetailsForm.patchValue({
                        'contact': '',
                    });
                    this.modalService.dismissAll('');
                    this.router.navigateByUrl('pos/add-order/' + data.id);
                } else {
                    this.modalType = 'ADD_USER';
                    this.addUserContact = params;
                    this.submitting = false;
                    this.submitted = false;
                    this.fetchUserDetailsForm.patchValue({
                        'contact': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    addUser() {
        this.submitted = true;
        if (!this.addUserForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'contact': this.addUserContact.contact,
                'name': (this.addUserForm.value && this.addUserForm.value.name) ? this.addUserForm.value.name : '',
                'email': (this.addUserForm.value && this.addUserForm.value.email) ? this.addUserForm.value.email : '',
            };
            this.submitting = true;
            this.posService.create(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.router.navigateByUrl('pos/add-order/' + data.id);
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    back() {
        this.modalType = 'FETCH_DETAILS';
    }

    viewOrders(content, data) {
        this.modalType = 'VIEW_ORDERS';
        this.modalService.open(content, {'size': 'xl'});
        this.selectedTransactionData = data;
    }

    deleteTransaction(data) {
        this.transactionService.delete(data.id).subscribe(data => {
            this.get();
        });
    }

    openExternalUrl(url) {
        window.open(environment.appUrl + url, '_blank');
    }

    updateDeliveryStatus(content, data) {
        this.modalType = 'CHANGE_DELIVERY_STATUS';
        this.modalService.open(content, {'size': 'lg'});
        this.selectedTransactionData = data;
    }

    updateStatus() {
        this.orderService.orderDeliveryStatus(this.selectedTransactionData['orders']['0']['order_id'], this.updateDeliveryStatusForm.value).subscribe(data => {
            this.get();
            this.modalService.dismissAll('');
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    changeTransactionStatus(content, data) {
        this.modalType = 'CHANGE_TRANSACTION_STATUS';
        this.modalService.open(content, {'size': 'lg'});
        this.selectedTransactionData = data;
    }

    updateTransactionStatus() {
        this.transactionService.transactionStatusByPos(this.selectedTransactionData.id, this.updateTransactionStatusForm.value).subscribe(data => {
            this.get();
            this.modalService.dismissAll('');
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }
}
