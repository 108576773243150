import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminsRoutingModule} from './admins-routing.module';
import {AdminsComponent} from './admins.component';
import {AddAdminComponent} from './add-admin/add-admin.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';

@NgModule({
    declarations: [AdminsComponent, AddAdminComponent],
    imports: [
        CommonModule,
        AdminsRoutingModule,
        ReactiveFormsModule,
        CoreDirectivesModule,
        NgbPaginationModule,
        CoreSidebarModule
    ],
    exports: [AdminsComponent, AddAdminComponent]
})

export class AdminsModule {
}
