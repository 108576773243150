import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DataSharing {
    private universityId = new BehaviorSubject<string>('defaults');
    savedUniversityId = this.universityId.asObservable();
    setUniversityId(id: any) {
        this.universityId.next(id);
    }

}
