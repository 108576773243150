import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../../shared/services/storage.service';
import {AlertService} from '../../../shared/services/alert.service';
import * as moment from 'moment';
import {ConsultService} from '../../../shared/services/consult.service';
import {DoctorService} from '../../../shared/services/doctor.service';

@Component({
    selector: 'app-doctor-consults',
    templateUrl: './doctor-consults.component.html',
    styleUrls: ['./doctor-consults.component.scss']
})
export class DoctorConsultsComponent implements OnInit {
    showSearch = false;
    doctorData: any;
    consults: any;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    user: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    searchForm: FormGroup;
    currentYear: any = parseInt(moment(new Date()).format('YYYY'));
    currentMonth: any = parseInt(moment(new Date()).format('MM')) - 1;
    currentDate: any = parseInt(moment(new Date()).format('DD'));
    currentHour: any = parseInt(moment(new Date()).format('HH'));
    currentMinute: any = parseInt(moment(new Date()).format('mm'));
    public currentDateTime = new Date(this.currentYear, this.currentMonth, this.currentDate, this.currentHour, this.currentMinute);

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private doctorService: DoctorService,
                private consultService: ConsultService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'date': [''],
            'status': ['']
        });
        this.getDoctordetail(this.id);
    }

    add(content): void {
        this.data = undefined;
        this.modalService.open(content, {'size': 'lg'});
    }

    getDoctordetail(id) {
        this.doctorService.detail(id).subscribe(data => {
            this.doctorData = data;
            this.get();
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.consults = [];
        this.consultService.getByDoctor(this.doctorData.id, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.consults = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.consults = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        this.get();
    }

    search() {
        const params = {
            'date': (this.searchForm.value && moment(this.searchForm.value.date).format('YYYY-MM-DD')) ? moment(this.searchForm.value.date).format('YYYY-MM-DD') : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : ''
        };
        this.get(params);
    }
}
