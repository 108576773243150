import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductsComponent} from './products.component';
import {AddProductComponent} from './add-product/add-product.component';
import {ProductsActionContentComponent} from './products-action-content/products-action-content.component';
import {KeyIngredientComponent} from './key-ingredient/key-ingredient.component';
import {DosageComponent} from './dosage/dosage.component';

const routes: Routes = [
    {
        path: 'products',
        component: ProductsComponent,
        data: {animation: 'products'}
    },
    {
        path: 'product/add',
        component: AddProductComponent,
        data: {animation: 'product/add'}
    },
    {
        path: 'product-action/:id',
        component: ProductsActionContentComponent,
        data: {animation: 'product/action'}
    },
    {
        path: 'key-ingredient/:id',
        component: KeyIngredientComponent,
        data: {animation: 'product/key-ingredient'}
    },
    {
        path: 'dosage/:id',
        component: DosageComponent,
        data: {animation: 'product/dosage'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductsRoutingModule {
}
