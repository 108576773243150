import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr'; // For auth after login toast
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
// @ts-ignore
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {coreConfig} from 'app/app-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {SharedModule} from './shared/shared.module';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {GlobalService, MomentService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DataSharing} from './shared/constants/DataSharing';
import {QuillModule} from 'ngx-quill';
import {AdminsModule} from './main/admins/admins.module';
import {DoctorsModule} from './main/doctors/doctors.module';
import {ConsultantsModule} from './main/consultants/consultants.module';
import {AppointmentsModule} from './main/appointments/appointments.module';
import {SubAdminsModule} from './main/sub-admins/sub-admins.module';
import {SymptomsModule} from './main/symptoms/symptoms.module';
import {CategoriesModule} from './main/categories/categories.module';
import {SubCategoriesModule} from './main/sub-categories/sub-categories.module';
import {ProductsModule} from './main/products/products.module';
import {FaqsModule} from './main/faqs/faqs.module';
import {TestimonialsModule} from './main/testimonials/testimonials.module';
import {TransactionsComponent} from './main/transactions/transactions.component';
import {TransactionsModule} from './main/transactions/transactions.module';
import {UsersComponent} from './main/users/users.component';
import {UsersModule} from './main/users/users.module';
import {OrdersModule} from './main/orders/orders.module';
import {PosComponent} from './main/pos/pos.component';
import {PosModule} from './main/pos/pos.module';
import {AddBlogInfoComponent} from './main/blogs/add-blog-info/add-blog-info.component';
import {MetaTagsModule} from './main/meta-tags/meta-tags.module';
import {AddMetaTagsComponent} from './main/meta-tags/add-meta-tags/add-meta-tags.component';
import {AddBlogHindiInfoComponent} from './main/blogs-hindi/add-blog-hindi-info/add-blog-hindi-info.component';
import {ContactusComponent} from './main/contact-us/contactus/contactus.component';
import {SelfAssessmentComponent} from './main/self-assessment/self-assessment/self-assessment.component';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {path: 'blogs', loadChildren: () => import('./main/blogs/blogs.module').then(m => m.BlogsModule)},
    // {path: 'my-profile', loadChildren: () => import('./main/my-profile/my-profile.module').then(m => m.MyProfileModule)},
    {
        path: 'blog-info/:id',
        component: AddBlogInfoComponent,
        data: {animation: 'Blogs/Info'}
    },
    {path: 'blogs-hindi', loadChildren: () => import('./main/blogs-hindi/blogs-hindi.module').then(m => m.BlogsHindiModule)},
    {
        path: 'blog-hindi-info/:id',
        component: AddBlogHindiInfoComponent,
        data: {animation: 'BlogsHindi/Info'}
    },
    {path: 'meta-tags', loadChildren: () => import('./main/meta-tags/meta-tags.module').then(m => m.MetaTagsModule)},
    {
        path: 'meta-tags-info/:id',
        component: AddMetaTagsComponent,
        data: {animation: 'MetaTags/Info'}
    },
    {
        path: 'contactus-data',
        component: ContactusComponent,
        data: {animation: 'ContactUs/Info'}
    },
    {
        path: 'self-assessment',
        component: SelfAssessmentComponent,
        data: {animation: 'SelfAssessment/Info'}
    },
    {
        path: '**',
        redirectTo: '/auth/miscellaneous/error' // Error 404 - Page not found
    }
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
        AppComponent,
        TransactionsComponent,
        UsersComponent,
        PosComponent,
        ContactusComponent,
        SelfAssessmentComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot(),
        // NgBootstrap
        ToastrModule.forRoot(),
        // Core modules
        CoreModule.forRoot(coreConfig),
        QuillModule.forRoot(),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        SharedModule,
        // App modules
        LayoutModule,
        SampleModule,
        SnotifyModule,
        DashboardModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PerfectScrollbarModule,
        ImageCropperModule,
        AdminsModule,
        DoctorsModule,
        ConsultantsModule,
        AppointmentsModule,
        SubAdminsModule,
        SymptomsModule,
        CategoriesModule,
        SubCategoriesModule,
        ProductsModule,
        FaqsModule,
        TestimonialsModule,
        TransactionsModule,
        UsersModule,
        OrdersModule,
        PosModule,
        MetaTagsModule,
        NgbModule
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        AuthGuard,
        HtpInterceptor,
        GlobalService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        MomentService,
        DataSharing,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
})
export class AppModule {
}
