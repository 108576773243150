import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersComponent} from './users.component';
import {AddUserComponent} from './add-user/add-user.component';

const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
    data: {animation: 'users'}
  },
  {
    path: 'add-user/add',
    component: AddUserComponent,
    data: {animation: 'add-user/add'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {
}
