<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Prescriptions - {{consultData?.consult_id}}</h2>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-9 col-xs-12">
                    <button (click)="addPrescription()"
                            *ngIf="this.user['user_type'] === 'DOCTOR'" [disabled]="prescriptions.length === 1"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Prescription
                    </button>
                </div>
            </div>

            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th *ngIf="user?.user_type==='DOCTOR' && consultData?.status==='PENDING'"
                                                class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Cheif Complaint</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">ExaminationNotes</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Temperature(F)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Pulse(bpm)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Respiratory Rate(rpm)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Blood Pressure(mmHg)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">SPO2(%)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Height(cms)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Weight(kgs)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Diagnosis</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Medication</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Procedures</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Investigation</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Advice</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="prescriptions && prescriptions.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let prescription of prescriptions; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td *ngIf="user?.user_type==='DOCTOR' && consultData?.status==='PENDING'"
                                                class="text-center">
                                                <i (click)="edit(prescription)"
                                                   aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit"></i>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{prescription?.chief_complaint}}</h6>
                                            </td>

                                            <td class="text-center">
                                                <h6 *ngIf="prescription && prescription['examination']"
                                                    class="mt-1">{{prescription?.examination}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.temperature}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.pulse}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{prescription?.respiratory_rate}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{prescription?.blood_pressure}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.spo2}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.height}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.weight}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.diagnosis}}</h6>
                                            </td>
                                            <td class="text-left">
                                                <div *ngFor="let medicines of prescription?.medication">
                                                    <h6 class="mt-1">
                                                        <ng-container *ngIf="medicines?.name as name">Name: {{ name }}
                                                            <br></ng-container>
                                                        <ng-container *ngIf="medicines?.frequency as frequency">
                                                            Frequency: {{ frequency }}<br></ng-container>
                                                        <ng-container *ngIf="medicines?.timing as timing">
                                                            Timing: {{ timing }}<br></ng-container>
                                                        <ng-container *ngIf="medicines?.duration as duration">
                                                            Duration: {{ duration }}<br></ng-container>
                                                        <ng-container *ngIf="medicines?.remark as remark">
                                                            Remark: {{ remark }}</ng-container>
                                                    </h6>
                                                    <hr>
                                                </div>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.procedures}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{prescription?.investigation}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{prescription?.advice}}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="10"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="prescriptions && prescriptions.length > 0; else noDataToShowMobile"
                 class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let prescription of prescriptions; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-12">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . Prescription
                                        - {{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Prescription - {{prescription?.prescription}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Remark - {{prescription?.remark}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div class="card">
                            <h5>No data to show</h5>
                        </div>
                    </div>
                </div>

            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                [boundaryLinks]="true" [collectionSize]="pagination.total"
                                [maxSize]="5" [pageSize]="pagination.perpage"
                                class="pagination-primary">
                    <ng-template ngbPaginationPrevious>
                        <span [class]="'font-weight-bolder'" [data-feather]="'chevron-left'"
                              [size]="18">
                        </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <span [class]="'font-weight-bolder'" [data-feather]="'chevron-right'"
                              [size]="18">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-prescription"
            overlayClass="modal-backdrop">
        <app-add-prescription (valueSubmitted)="getData($event)" [data]="data"></app-add-prescription>
    </core-sidebar>
</div>

