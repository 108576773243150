import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get APP_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get LOGIN(): string {
        return AppUrl.APP_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.API_URL + 'self';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.API_URL + 'file-upload';
    }

    static UPDATE_SELF(): string {
        return AppUrl.API_URL + 'update-self-data';
    }

    static UPDATE_SELF_PASSWORD(): string {
        return AppUrl.API_URL + 'update-self-password';
    }

    static LOGOUT(): string {
        return AppUrl.API_URL + 'logout';
    }

    static get VERIFY_USER(): string {
        return AppUrl.APP_URL + 'verify-user';
    }

    static UPDATE_USER_PASSWORD(): string {
        return AppUrl.API_URL + 'update-user-password';
    }

    static STATES(): string {
        return AppUrl.APP_URL + 'states';
    }

    static PIN_CODE_DATA(pin_code): string {
        return AppUrl.API_URL + 'pin-code-data/' + pin_code;
    }

    static COUNTRIES(): string {
        return AppUrl.APP_URL + 'countries';
    }

    static SELFASSESSMENT(): string {
        return AppUrl.API_URL + 'self-assessment';
    }

    static ADMINS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin/' + id;
        } else {
            return AppUrl.APP_URL + 'admin';
        }
    }

    static CONTACTUSDATA(): string {
        return 'https://backendaxico.draxico.com/api/contactUs';
    }

    static ADMIN_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-password/' + id;
        }
    }

    static ADMIN_PROFILE_IMAGE(id?): string {
        if (id) {
            return AppUrl.API_URL + 'admin-profile-image/' + id;
        } else {
            return AppUrl.API_URL + 'admin-profile-image';
        }
    }

    static ADMIN_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-status/' + id;
        }
    }

    static ADMINS_COUNT(): string {
        return AppUrl.APP_URL + 'admins-count';
    }

    static SUBADMINS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'subAdmin/' + id;
        } else {
            return AppUrl.APP_URL + 'subAdmin';
        }
    }

    static SUBADMIN_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'subAdmin-password/' + id;
        }
    }

    static SUBADMIN_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'subAdmin-status/' + id;
        }
    }

    static SUBADMINS_COUNT(): string {
        return AppUrl.APP_URL + 'subAdmins-count';
    }

    static DOCTORS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'doctor/' + id;
        } else {
            return AppUrl.APP_URL + 'doctor';
        }
    }

    static DOCTORS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'doctor-content/' + id;
        }
    }

    static DOCTOR_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'doctor-password/' + id;
        }
    }

    static DOCTOR_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'doctor-status/' + id;
        }
    }

    static PROFILE_IMAGE_UPDATE(id?): string {
        if (id) {
            return AppUrl.API_URL + 'profile-image/' + id;
        }
    }

    static ASSIGN_DOCTORS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'assign-doctor/' + id;
        } else {
            return AppUrl.APP_URL + 'assign-doctor';
        }
    }

    static DOCTORS_COUNT(): string {
        return AppUrl.APP_URL + 'doctors-count';
    }

    static CONSULTANTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consultant/' + id;
        } else {
            return AppUrl.APP_URL + 'consultant';
        }
    }

    static CONSULTANT_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consultant-password/' + id;
        }
    }

    static CONSULTANT_PROFILE_IMAGE(id?): string {
        if (id) {
            return AppUrl.API_URL + 'consultant-profile-image/' + id;
        } else {
            return AppUrl.API_URL + 'consultant-profile-image';
        }
    }

    static CONSULTANT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consultant-status/' + id;
        }
    }

    static CONSULTANTS_COUNT(): string {
        return AppUrl.APP_URL + 'consultants-count';
    }

    static USERS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'user/' + id;
        } else {
            return AppUrl.APP_URL + 'user';
        }
    }

    static USERS_COUNT(): string {
        return AppUrl.APP_URL + 'users-count';
    }

    static USER_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'user-password/' + id;
        }
    }

    static USER_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'user-status/' + id;
        }
    }

    static APPOINTMENTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'appointment/' + id;
        } else {
            return AppUrl.APP_URL + 'appointment';
        }
    }

    static APPOINTMENT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'appointment-status/' + id;
        }
    }

    static APPOINTMENT_DOCS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'appointment-documents/' + id;
        }
    }

    static APPOINTMENTS_COUNT(): string {
        return AppUrl.APP_URL + 'appointment-count';
    }

    static CONSULTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consult/' + id;
        } else {
            return AppUrl.APP_URL + 'consult';
        }
    }

    static UPDATE_VIDEO_URL(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consult-video-url/' + id;
        }
    }

    static UPDATE_CONSULT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'update-consult-status/' + id;
        }
    }

    static GET_CONSULTS(id?): string {
        return AppUrl.APP_URL + 'consult/' + id;
    }

    static GET_DOCTOR_CONSULTS(id?): string {
        return AppUrl.APP_URL + 'doctor-consults/' + id;
    }

    static DETAILS_CONSULTS(appointmentId?, id?): string {
        return AppUrl.APP_URL + 'consult/' + appointmentId + '/' + id;
    }

    static DELETE_CONSULTS(appointment_id?, id?): string {
        return AppUrl.APP_URL + 'consult/' + appointment_id + '/' + id;
    }

    static GET_PRESCRIPTIONS(consultId): string {
        if (consultId) {
            return AppUrl.APP_URL + 'prescription/' + consultId;
        }
    }

    static PRESCRIPTIONS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'prescription/' + id;
        } else {
            return AppUrl.APP_URL + 'prescription';
        }
    }

    static DOCPRESCRIPTION(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'docPrescription/' + id;
        } else {
            return AppUrl.APP_URL + 'docPrescription';
        }
    }

    static CATEGORIES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-category/' + id;
        } else {
            return AppUrl.APP_URL + 'product-category';
        }
    }

    static CATEGORIES_COUNT(): string {
        return AppUrl.APP_URL + 'product-category-count';
    }

    static CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-category-status/' + id;
        }
    }

    static SUB_CATEGORIES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-sub-category/' + id;
        } else {
            return AppUrl.APP_URL + 'product-sub-category';
        }
    }

    static SUB_CATEGORIES_COUNT(): string {
        return AppUrl.APP_URL + 'product-sub-category-count';
    }

    static SUB_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-sub-category-status/' + id;
        }
    }

    static PRODUCTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product/' + id;
        } else {
            return AppUrl.APP_URL + 'product';
        }
    }

    static PRODUCT_ACTION(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-action/' + id;
        }
    }

    static ADD_PRODUCT_IMAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-image/' + id;
        } else {
            return AppUrl.APP_URL + 'product-image';
        }
    }

    static DELETE_PRODUCT_IMAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-image/' + id;
        }
    }

    static PRODUCT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-status/' + id;
        }
    }

    static PRODUCT_STOCK_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-stock-status/' + id;
        }
    }

    static PRODUCTS_COUNT(): string {
        return AppUrl.APP_URL + 'products-count';
    }

    static KEY_INGREDIENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'key-ingredient/' + id;
        } else {
            return AppUrl.APP_URL + 'key-ingredient';
        }
    }

    static KEY_INGREDIENT_COUNT(): string {
        return AppUrl.APP_URL + 'key-ingredient-count';
    }

    static DOSAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'dosage/' + id;
        } else {
            return AppUrl.APP_URL + 'dosage';
        }
    }

    static DOSAGE_COUNT(): string {
        return AppUrl.APP_URL + 'dosage-count';
    }

    static ORDERS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'transaction/' + id;
        } else {
            return AppUrl.API_URL + 'transaction';
        }
    }

    static ORDERS_BY_POS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'orders-by-pos/' + id;
        }
    }

    static ORDERS_COUNT(): string {
        return AppUrl.API_URL + 'transaction-count';
    }

    static ORDER_DELIVERY_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'order-delivery-status/' + id;
        }
    }

    static TRANSACTIONS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'transaction/' + id;
        } else {
            return AppUrl.API_URL + 'transaction';
        }
    }

    static TRANSACTIONS_BY_POS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'transaction-by-pos/' + id;
        } else {
            return AppUrl.API_URL + 'transaction-by-pos';
        }
    }

    static TRANSACTIONS_COUNT(): string {
        return AppUrl.API_URL + 'transaction-count';
    }

    static TRANSACTION_STATUS_BY_POS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'pos-transaction-status/' + id;
        }
    }

    static POS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'pos/' + id;
        } else {
            return AppUrl.API_URL + 'pos';
        }
    }

    static POS_COUNT(): string {
        return AppUrl.API_URL + 'pos-count';
    }

    static POS_FETCH_USER(): string {
        return AppUrl.API_URL + 'pos-user-status';
    }

    static POS_GET_USER(id): string {
        return AppUrl.API_URL + 'pos-user/' + id;
    }

    // static ADD_BLOG(): string {
    //     return AppUrl.APP_URL + 'blogs';
    // }

    static BLOGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blogs/' + id;
        } else {
            return AppUrl.APP_URL + 'blogs';
        }
    }

    static BLOG_ENGLISH_BANNER_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-english-banner/' + id;
        }
    }

    static BLOG_COVER_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-cover/' + id;
        }
    }

    static BLOG_HINDI_BANNER_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-hindi-banner/' + id;
        }
    }

    static BLOG_HINDI_COVER_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-hindi-cover/' + id;
        }
    }


    static BLOGS_COUNT(): string {
        return AppUrl.APP_URL + 'blogs-count';
    }

    static BLOG_STATUS_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-status/' + id;
        }
    }

    static BLOG_CONTENT_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-content/' + id;
        }
    }

    static BLOG_DELETE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blogs/' + id;
        }
    }

    static HINDI_BLOGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'hindi-blogs/' + id;
        } else {
            return AppUrl.APP_URL + 'hindi-blogs';
        }
    }

    static HINDI_BLOGS_COUNT(): string {
        return AppUrl.APP_URL + 'hindi-blogs-count';
    }

    static HINDI_BLOG_STATUS_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'hindi-blog-status/' + id;
        }
    }

    static HINDI_BLOG_CONTENT_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'hindi-blog-content/' + id;
        }
    }

    static HINDI_BLOG_DELETE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'hindi-blogs/' + id;
        }
    }

    static ADDRESS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'address/' + id;
        } else {
            return AppUrl.API_URL + 'address';
        }
    }

    static ADDRESS_BY_POS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'pos-user-address/' + id;
        }
    }

    static ADDRESS_ADD_BY_POS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'pos-user-address-add/' + id;
        }
    }

    static ADDRESS_COUNT(id?): string {
        return AppUrl.API_URL + 'address-count/' + id;
    }

    static UPDATE_ADDRESS_STATUS(id?, userId?): string {
        return AppUrl.API_URL + 'pos-address-status/' + id + '/' + userId;
    }


    static META_TAGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'meta-tags/' + id;
        } else {
            return AppUrl.APP_URL + 'meta-tags';
        }
    }

    // static META_TAGS_COUNT(): string {
    //     return AppUrl.APP_URL + 'meta-tags-count';
    // }
    static META_TAGS_STATUS_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'meta-tags-status/' + id;
        }
    }

    // static META_TAGS_CONTENT_UPDATE(id?): string {
    //     if (id) {
    //         return AppUrl.APP_URL + 'meta-tags-content/' + id;
    //     }
    // }
    // static META_TAGS_DELETE(id?): string {
    //     if (id) {
    //         return AppUrl.APP_URL + 'meta-tags/' + id;
    //     }
    // }
}
