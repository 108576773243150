import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CategoriesComponent} from './categories.component';
import {AddCategoryComponent} from './add-category/add-category.component';

const routes: Routes = [
  {
    path: 'categories',
    component: CategoriesComponent,
    data: {animation: 'categories'}
  },
  {
    path: 'category/add',
    component: AddCategoryComponent,
    data: {animation: 'category/add'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoriesRoutingModule { }
