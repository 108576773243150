<div class="row">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <h2 class="mt-5px">Self Assessment</h2>
    </div>
</div>
<div class="row d-none d-md-block mt-3">
    <div class="col-12">
        <div class="card">
            <div class="container-fluid">
                <div class="row overflow-x-scroll overflow-table">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <table class="table table-list text-center">
                            <thead>
                            <tr>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Gender</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Age</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Type</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Chance</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Time</h6>
                                </th>
                               <!-- <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Time</h6>
                                </th>-->
                            </tr>
                            </thead>
                            <tbody *ngIf="userdataselfassessment && userdataselfassessment.length > 0; else noDataToShowWeb">
                            <tr *ngFor="let data of userdataselfassessment; let i = index">
                                <td class="text-center">
                                    <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        .</h6>
                                </td>
                                <td class="text-center"><h6 class="mt-1">{{data?.name}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{data?.gender}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{data?.age}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{data?.type}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{data?.chance}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{data?.email}}</h6></td>
                                <td class="text-center"><h6 class="mt-1">{{data?.contact}}</h6></td>
                                <td class="text-center" ><h6 class="mt-1">{{data?.created_at}}</h6></td>


                            </tr>
                            </tbody>
                            <ng-template #noDataToShowWeb>
                                <tbody>
                                <tr>
                                    <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                </tr>
                                </tbody>
                            </ng-template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row pagination-table">
    <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                    [(page)]="pagination.page" [boundaryLinks]="true"
                    [pageSize]="pagination.perpage" [maxSize]="5"
                    (pageChange)="onPageChange($event)">
        <ng-template ngbPaginationPrevious>
                        <span [data-feather]="'chevron-left'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
        </ng-template>
        <ng-template ngbPaginationNext>
                        <span [data-feather]="'chevron-right'" [size]="18"
                              [class]="'font-weight-bolder'">
                        </span>
        </ng-template>
    </ngb-pagination>
</div>
