import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {BlogService} from '../../../shared/services/blog.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-add-blog-hindi-info',
    templateUrl: './add-blog-hindi-info.component.html',
    styleUrls: ['./add-blog-hindi-info.component.scss']
})
export class AddBlogHindiInfoComponent implements OnInit {
    public blogId: any;
    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    blogtId: any;
    submitting: any = false;
    submitted: any = false;
    contentData: any;


    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private blogService: BlogService,
                private _location: Location) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        // this.title = this.route.snapshot.params['blogs-name'];
        this.blogId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.blogService.detail_hindi(this.blogId).subscribe(data => {
            if (data) {
                this.data = data;
                if (this.data && this.data['content']) {
                    this.contentData = this.data['content'];
                }
            } else {
                this.router.navigateByUrl('blogs-hindi');
            }
        }, error => {
            this.router.navigateByUrl('blogs-hindi');
        });
    }



    submit() {
        const params = {
            'content': this.contentData ? this.contentData : ''
        };
        this.blogService.updateContent_hindi(this.blogId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
            this._location.back();
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

}
