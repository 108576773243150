<div class="content-wrapper">
    <div class="content-body">
        <section id="admins">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 *ngIf="consultModalOpen" class="modal-title font-weight-bold">Create Consult</h4>
                        <h4 *ngIf="videoConferenceModalOpen" class="modal-title font-weight-bold">Create Video
                            Conference Url</h4>
                        <h4 *ngIf="consultUpdateModalOpen" class="modal-title font-weight-bold">Consult Status</h4>
                        <button
                                (click)="modalService.dismissAll('')"
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="consultModalOpen" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="createConsultForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="start_date_time">Start Date Time</label>
                                                    <input [min]="currentDateTime" [owlDateTimeTrigger]="dt1"
                                                           [owlDateTime]="dt1" class="form-control"
                                                           formControlName="start_date_time"
                                                           id="start_date_time"
                                                           placeholder="Date Time">
                                                    <owl-date-time #dt1 [startAt]="currentDateTime"></owl-date-time>
                                                    <small *ngIf="submitted && createConsultForm.get('start_date_time').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div class="form-group">
                                                    <label for="end_date_time">End Date Time</label>
                                                    <input [min]="currentDateTime" [owlDateTimeTrigger]="dt2"
                                                           [owlDateTime]="dt2" class="form-control"
                                                           formControlName="end_date_time"
                                                           id="end_date_time" placeholder="Date Time">
                                                    <owl-date-time #dt2 [startAt]="currentDateTime"></owl-date-time>
                                                    <small *ngIf="submitted && createConsultForm.get('end_date_time').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="remarks">Remarks</label>
                                                    <textarea class="form-control resize-none" formControlName="remarks"
                                                              id="remarks"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <span (click)="goToDocotorConsult()"
                                                      class="color-twitter cursor-pointer">View "{{appointmentData?.doctor?.user?.name}}
                                                    " Consults</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="videoConferenceModalOpen" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="videoConferenceForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="video_url">Video Url</label>
                                                    <input class="form-control" formControlName="url" id="video_url"
                                                           type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="consultUpdateModalOpen" class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form [formGroup]="consultUpdateForm" class="form" novalidate>
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="form-group">
                                                    <label for="status">Status</label>
                                                    <select class="form-control" formControlName="status" id="status">
                                                        <option value="">Select Status</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="COMPLETED">Completed</option>
                                                        <option value="FAILED">Failed</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button (click)="createConsult()" *ngIf="!data && consultModalOpen"
                                [disabled]="submitting" class="btn btn-success color-white pull-right mt-1 mb-1">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting && !data" class="fas fa-spinner fa-pulse mr-1"></i>Submit
                        </button>
                        <button (click)="createVideoConference()" *ngIf="!data && videoConferenceModalOpen"
                                [disabled]="submitting" class="btn btn-success color-white pull-right mt-1 mb-1">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting && !data" class="fas fa-spinner fa-pulse mr-1"></i>Submit
                        </button>
                        <button (click)="updateConsultStatus()" *ngIf="!data && consultUpdateModalOpen"
                                [disabled]="submitting" class="btn btn-success color-white pull-right mt-1 mb-1">
                            <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                            <i *ngIf="submitting && !data" class="fas fa-spinner fa-pulse mr-1"></i>Submit
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <h2 class="mt-5px">Consults - {{appointmentData?.appointment_id}}</h2>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <button (click)="goToAppointmentDocs()"
                            *ngIf="user && ['CONSULTANT', 'DOCTOR'].indexOf(user['user_type']) !== -1"
                            class="btn btn-primary pull-right color-white color-white users-btn cursor-pointer ml-2">
                        <i aria-hidden="true" class="fas fa-notes-medical mr-8px"></i>&nbsp;Appointment Docs
                    </button>
                    <button (click)="add(content)"
                            *ngIf="user && ['CONSULTANT'].indexOf(user['user_type']) !== -1"
                            class="btn btn-primary pull-right color-white color-white users-btn cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Create Consult
                    </button>
                </div>
            </div>
            <div class="row mt-3 text-center shadow-complete details-area">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="mt-1 mb--2px">{{appointmentData?.doctor?.user?.name}}</h4>
                    <small>Doctor</small>
                </div>
                <!--                <pre class="text-white">{{appointmentData|json}}</pre>-->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="mt-1 mb--2px">{{appointmentData?.consultant?.user?.name}}</h4>
                    <small>Consultant</small>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="mt-1 mb--2px">{{appointmentData?.account?.name}}</h4>
                    <small>User Account</small>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="mt-1 mb--2px">{{appointmentData?.name}}</h4>
                    <small>Patient Name</small>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="mt-1 mb--2px">{{appointmentData?.age}}</h4>
                    <small>Patient Age</small>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="mt-1 mb--2px">{{appointmentData?.gender}}</h4>
                    <small>Patient Gender</small>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 *ngIf="appointmentData && appointmentData['symptoms']"
                        class="mt-1 mb--2px">{{appointmentData?.symptoms}}</h4>
                    <h4 *ngIf="!appointmentData || !appointmentData['symptoms']" class="mt-1 mb--2px">-</h4>
                    <small>Patient Symptoms</small>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Consult Id</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Start Date Time</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">End Date Time</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Remarks</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                            <th *ngIf="(this.user['user_type'] === 'CONSULTANT')" class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Video Meeting</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="consults && consults.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let consult of consults; let i = index">
                                            <!--                                            <pre class="text-white">{{consult|json}}</pre>-->
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{consult?.consult_id}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{consult?.start_date_time}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{consult?.end_date_time}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{consult?.remarks}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{consult?.status}}</h6>
                                            </td>
                                            <td *ngIf="(this.user['user_type'] === 'CONSULTANT')" class="text-center">
                                                <h6 (click)="openVideoModal(content, consult)"
                                                    *ngIf="consult && consult['status'] === 'PENDING'"
                                                    class="mt-1 text-info cursor-pointer">Update</h6>
                                                <h6 *ngIf="consult && consult['status'] !== 'PENDING'"
                                                    class="mt-1 cursor-pointer">-</h6>
                                                <!--<i class="fas fa-tv f_20 ml-5px text-primary cursor-pointer mt-1"
                                                   title="Video Meeting" *ngIf="consult || consult['url']"
                                                   (click)="goToVideoUrl(consult)"></i>-->
                                            </td>
                                            <td *ngIf="this.user['user_type'] === 'DOCTOR'" class="text-center">
                                                <i (click)="goToVideoUrl(consult)"
                                                   *ngIf="consult && consult['url']"
                                                   class="fas fa-tv f_20 ml-5px text-primary cursor-pointer mt-1"
                                                   title="Video Url"></i>
                                                <!--<i class="fas fa-tv f_20 ml-5px text-primary cursor-pointer mt-1"
                                                   title="Video Meeting" *ngIf="consult || consult['url']"
                                                   (click)="goToVideoUrl(consult)"></i>-->
                                            </td>
                                            <td class="text-center">
                                                <!--<i class="fas fa-tv f_20 ml-5px text-primary cursor-pointer mt-1"
                                                   title="Video Url" *ngIf="consult && consult['url']"
                                                   (click)="goToVideoUrl(consult)"></i>-->
                                                <!--                                                <pre class="text-white">{{consult|json}}</pre>-->
                                                <span [class.disabled]="(consult?.status== 'COMPLETED')"
                                                      id="goToPrescription">
                                                <i (click)="goToPrescription(consult)"
                                                   class="fas fa-mortar-pestle f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Prescription"></i>
                                                    </span>
                                                <!--                                                <i (click)="downloadPrescriptions(consult)"-->
                                                <!--                                                   [class]="['fas', 'fa-download', 'f_20', 'ml-5px', 'text-primary', 'cursor-pointer', 'mt-1']"-->
                                                <!--                                                   [title]="'Download'"-->
                                                <!--                                                   [disabled]="!(consult?.prescriptions?.length > 0)"-->
                                                <!--                                                ></i>-->
                                                <span [class.disabled]="!(consult?.prescriptions?.length > 0)"
                                                      id="downloadPrescriptions">
                                                  <i (click)="downloadPrescriptions(consult)"
                                                     class="fas fa-download f_20 ml-5px text-primary cursor-pointer mt-1"
                                                     title="Download"></i>
                                                </span>
                                                <i (click)="openStatusModal(content, consult)"
                                                   *ngIf="consult"
                                                   class="fas fa-calendar-check f_20 ml-5px text-secondary cursor-pointer mt-1"
                                                   title="Consult Status Update"></i>
                                                <i (click)="delete(consult)"
                                                   *ngIf="user && user['user_type']!='DOCTOR' && consult && consult['status'] === 'PENDING'"
                                                   aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Delete"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="8"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="consults && consults.length > 0; else noDataToShowMobile"
                 class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let consult of consults; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{consult?.consult_id}}</h4>
                                </div>
                                <div class="col-6">
                                    <i (click)="delete(consult)"
                                       aria-hidden="true"
                                       class="fas fa-trash f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Start Date Time - {{consult?.start_date_time}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    End Date Time - {{consult?.end_date_time}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Remarks - {{consult?.remarks}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - {{consult?.status}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                [boundaryLinks]="true" [collectionSize]="pagination.total"
                                [maxSize]="5" [pageSize]="pagination.perpage"
                                class="pagination-primary">
                    <ng-template ngbPaginationPrevious>
                        <span [class]="'font-weight-bolder'" [data-feather]="'chevron-left'"
                              [size]="18">
                        </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <span [class]="'font-weight-bolder'" [data-feather]="'chevron-right'"
                              [size]="18">
                        </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
