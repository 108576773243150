import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AppointmentsRoutingModule} from './appointments-routing.module';
import {AppointmentsComponent} from './appointments.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {ReactiveFormsModule} from '@angular/forms';
import {ConsultsComponent} from './consults/consults.component';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import {PrescriptionsComponent} from './prescriptions/prescriptions.component';
import { AppointmentDocsComponent } from './appointment-docs/appointment-docs.component';
import { AddPrescriptionComponent } from './prescriptions/add-prescription/add-prescription.component';
import {CoreSidebarModule} from '../../../@core/components';


@NgModule({
    declarations: [AppointmentsComponent, ConsultsComponent, PrescriptionsComponent, AppointmentDocsComponent, AddPrescriptionComponent],
    imports: [
        CommonModule,
        AppointmentsRoutingModule,
        NgbPaginationModule,
        CoreDirectivesModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        CoreSidebarModule
    ]
})
export class AppointmentsModule {
}
