import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {ConsultService} from '../../../shared/services/consult.service';
import {AlertService} from '../../../shared/services/alert.service';
import {StorageService} from '../../../shared/services/storage.service';
import {PrescriptionService} from '../../../shared/services/prescription.service';
import {DocprescriptionService} from '../../../shared/services/docprescription.service';
// @ts-ignore
import {LocalStorageService} from 'angular-webstorage-service';

@Component({
    selector: 'app-prescriptions',
    templateUrl: './prescriptions.component.html',
    styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent implements OnInit {
    appointmentData: any;
    consultData: any;
    consults: any;
    prescriptions: any = [];
    appointId: any;
    PrescriptionForm: FormGroup;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    user: any;
    medicationInfo: any[];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private appointmentService: AppointmentService,
                private prescriptionService: PrescriptionService,
                private docprescriptionService: DocprescriptionService,
                private consultService: ConsultService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute,
    ) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.appointId = this.activatedRoute.snapshot.params['appointId'];
        this.id = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.getConsultantDetail();
        this.PrescriptionForm = this.fb.group(
            {
                'prescription': ['', Validators.required],
                'remark': ['', Validators.required],
            }
        );
    }

    addPrescription(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-prescription').toggleOpen();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    createPrescription() {
        this.submitted = true;
        if (!this.PrescriptionForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'consult_id': (this.consultData && this.consultData.id) ? this.consultData.id : '',
                // tslint:disable-next-line:max-line-length
                'prescription': (this.PrescriptionForm.value && this.PrescriptionForm.value.prescription) ? this.PrescriptionForm.value.prescription : '',
                'remark': (this.PrescriptionForm.value && this.PrescriptionForm.value.remark) ? this.PrescriptionForm.value.remark : ''
            };
            this.submitting = true;
            this.prescriptionService.create(this.consultData.id, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.PrescriptionForm.reset();
                    this.PrescriptionForm.patchValue({
                        'prescription': '',
                        'remark': ''
                    });
                    this.get();
                    this.modalService.dismissAll('');
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].prescription.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].prescription[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].remark.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].remark[0]);
                        return;
                    }
                }
            });
        }
    }


    getConsultantDetail() {
        this.consultService.detail(this.appointId, this.id).subscribe(data => {
            this.consultData = data;
            this.get();
        });
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.consults = [];
        this.docprescriptionService.get(this.consultData.id, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.prescriptions = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.prescriptions = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        this.get();
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    delete(data) {
        this.consultService.delete(this.appointmentData.id, data['id']).subscribe(data => {
            this.get();
        });
    }

    // protected readonly Object = Object;
}
