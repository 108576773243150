import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialsRoutingModule } from './testimonials-routing.module';
import { AddTestimonialComponent } from './add-testimonial/add-testimonial.component';
import {CoreDirectivesModule} from '../../../@core/directives/directives';
import {ReactiveFormsModule} from '@angular/forms';
import {TestimonialsComponent} from './testimonials.component';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [AddTestimonialComponent,TestimonialsComponent],
    imports: [
        CommonModule,
        TestimonialsRoutingModule,
        CoreDirectivesModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        CoreSidebarModule
    ]
})
export class TestimonialsModule { }
