<div class="content-wrapper">
    <div class="content-body">
        <section id="orders">
            <div class="container">
                <ng-template #content>
                    <div id="modal">
                        <div class="modal-header font-weight-bold">
                            <h4 *ngIf="modalType === 'ADD_PRODUCT'" class="modal-title font-weight-bold">Add
                                Product</h4>
                            <h4 *ngIf="modalType === 'ADD_ADDRESS'" class="modal-title font-weight-bold">Add
                                Address</h4>
                            <button
                                    (click)="modalService.dismissAll('')"
                                    aria-label="Close"
                                    class="close"
                                    data-dismiss="modal"
                                    type="button"
                            >
                                ×
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div *ngIf="modalType === 'ADD_PRODUCT'" class="row p-20px">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form">
                                            <div *ngFor="let addedProduct of addedProducts; let i = index" class="row">
                                                <!--                                                <pre class="text-white">{{addedProduct|json}}</pre>-->
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="product_id">
                                                            <i class="fa fa-product-hunt mr-5px"></i>Product
                                                        </label>
                                                        <select (change)="onProductChange($event, addedProduct)"
                                                                [(ngModel)]="addedProduct.product_id"
                                                                class="form-control"
                                                                id="product_id">
                                                            <option value="">Select Product</option>
                                                            <option *ngFor="let product of products"
                                                                    [value]="product.id">{{product?.product_alias}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="quantity">
                                                            <i class="fa fa-trello mr-5px"></i>Quantity</label>
                                                        <input (change)="onQuantityChange($event, addedProduct)"
                                                               [(ngModel)]="addedProduct.quantity" class="form-control"
                                                               id="quantity"
                                                               type="number">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="quantity">
                                                            <i class="fa fa-trello mr-5px"></i>Tax</label>
                                                        <input [(ngModel)]="addedProduct.taxPercentageAndAmount"
                                                               class="form-control" disabled id="tax"
                                                               type="text">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="unit_price">
                                                            <i class="fa fa-rupee mr-5px"></i>Unit price</label>
                                                        <input [(ngModel)]="addedProduct.unit_price"
                                                               class="form-control" disabled
                                                               id="unit_price">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="total_price">
                                                            <i class="fa fa-rupee mr-5px"></i>Total Price
                                                        </label>
                                                        <input [(ngModel)]="addedProduct.total_price"
                                                               class="form-control" disabled
                                                               id="total_price">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <button (click)="removeProduct(addedProduct)"
                                                            class="btn btn-danger mt-23px btn-block"><i
                                                            class="fa fa-times-circle mr-1"></i>Remove
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row background-light-grey">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <div class="form-group mt-5px ml-5px mt-20px font-weight-bold">
                                                        Total
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <input [(ngModel)]="taxAmountTotal" class="form-control mt-10px"
                                                               disabled
                                                               id="tax_total">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <input [(ngModel)]="unitPriceTotal" class="form-control mt-10px"
                                                               disabled id="unit_price_total">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <input [(ngModel)]="grandTotal" class="form-control mt-10px"
                                                               disabled
                                                               id="grand_total">
                                                    </div>
                                                </div>
                                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="modalType === 'ADD_ADDRESS'" class="row p-20px">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div *ngIf="userAddresses && userAddresses.length > 0" class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div *ngFor="let userAddress of userAddresses"
                                                         class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 background-light-grey m-10px p-10px">
                                                        <div class="row">
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <i *ngIf="userAddress && userAddress['status'] === 'SELECTED'"
                                                                   class="fas fa-check-circle text-success cursor-pointer address-check-circle"></i>
                                                                <i (click)="chooseAddress(userAddress.id, 'SELECTED')"
                                                                   *ngIf="userAddress && userAddress['status'] === 'UN_SELECTED'"
                                                                   class="fas fa-check-circle text-secondary cursor-pointer address-check-circle"></i>
                                                                <small>Shipping Address</small>
                                                                <br>
                                                                {{userAddress?.address_1_shipping}}
                                                                , {{userAddress?.address_2_shipping}}
                                                                <br>
                                                                {{userAddress?.landmark_shipping}}
                                                                , {{userAddress?.city_shipping}}
                                                                <br>
                                                                {{userAddress?.state_shipping?.name}}
                                                                , {{userAddress?.pin_code_shipping}}
                                                                <br>
                                                                <br>
                                                                <small>Billing Address</small>
                                                                <br>
                                                                {{userAddress?.address_1_billing}}
                                                                , {{userAddress?.address_2_billing}}
                                                                <br>
                                                                {{userAddress?.landmark_billing}}
                                                                , {{userAddress?.city_billing}}
                                                                <br>
                                                                {{userAddress?.state_billing?.name}}
                                                                , {{userAddress?.pin_code_billing}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <form [formGroup]="addAddressForm" novalidate>
                                                    <div *ngIf="pinCodeLoader"
                                                         style="position: absolute; z-index: 1; width: 100%; background-color: rgba(255, 255, 255, 0.8); opacity: 0.9; height: 100%; top: 0; left: 0; text-align: center">
                                                        <i class="fas fa-spinner fa-pulse fa-2x"
                                                           style="margin-top: 350px"></i>
                                                        <br>
                                                        <small>Please wait while we are fetching pin code
                                                            data...</small>
                                                    </div>
                                                    <div class="row p-10 mt-12px">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>
                                                                <i class="icofont-address-book text-success"></i><strong>
                                                                Shipping Address :-</strong>
                                                            </label>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-address-book"></i>
                                                                <label class="p-10">Address Line 1<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="address_1_shipping"
                                                                       id="shipping-address-line1"
                                                                       placeholder="Enter Your Address"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('address_1_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-address-book"></i>
                                                                <label class="p-10">Address Line 2</label>
                                                                <input class="form-control"
                                                                       formControlName="address_2_shipping"
                                                                       id="shipping-address-line2"
                                                                       placeholder="Enter Your Address"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('address_2_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-location-pin"></i>
                                                                <label class="p-10">Landmark</label>
                                                                <input class="form-control"
                                                                       formControlName="landmark_shipping"
                                                                       id="shipping-landmark"
                                                                       placeholder="Enter Your landmark"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('landmark_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-code"></i>
                                                                <label class="p-10">Pin Code<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input (change)="getPinCodeData($event, 'SHIPPING')"
                                                                       class="form-control"
                                                                       formControlName="pin_code_shipping"
                                                                       id="shipping-pin-code"
                                                                       placeholder="Enter Pincode"
                                                                       type="number"/>
                                                                <small *ngIf="submitted && addAddressForm.get('pin_code_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-map-pins"></i>
                                                                <label class="p-10">City / District<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control" disabled
                                                                       formControlName="city_shipping"
                                                                       id="shipping-city"
                                                                       placeholder="Enter City" type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('city_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-map-pins"></i>
                                                                <label class="p-10">State<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <select class="form-control" disabled
                                                                        formControlName="state_id_shipping"
                                                                        id="shipping-state">
                                                                    <option value="">Select State</option>
                                                                    <option *ngFor="let state of states"
                                                                            [value]="state.id">{{state?.name}}</option>
                                                                </select>
                                                                <small *ngIf="submitted && addAddressForm.get('state_id_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-user"></i>
                                                                <label class="p-10">Name<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="name_shipping"
                                                                       id="shipping-email"
                                                                       placeholder="Enter Your Name"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('name_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-ui-call"></i>
                                                                <label class="p-10">Contact no.<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="contact_shipping"
                                                                       id="shipping-contact"
                                                                       placeholder="Enter Your Contact Number"
                                                                       type="number"/>
                                                                <small *ngIf="submitted && addAddressForm.get('contact_shipping').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-10">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <i (click)="sameBilling(true)"
                                                               *ngIf="!sameBillingAddress"
                                                               class="fas fa-check-circle cursor-pointer"></i>
                                                            <i (click)="sameBilling(false)"
                                                               *ngIf="sameBillingAddress"
                                                               class="fas fa-check-circle text-success cursor-pointer"></i>
                                                            <label class="form-check-label">
                                                                <strong class="ml-5px">Billing address same as Shipping
                                                                    address </strong>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="row p-10">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>
                                                                <i class="icofont-address-book text-danger"></i><strong>
                                                                Billing Address :-</strong>
                                                            </label>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-address-book"></i>
                                                                <label class="p-10">Address Line 1<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="address_1_billing"
                                                                       id="billing-address-line1"
                                                                       placeholder="Enter Your Address"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('address_1_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-address-book"></i>
                                                                <label class="p-10">Address Line 2</label>
                                                                <input class="form-control"
                                                                       formControlName="address_2_billing"
                                                                       id="billing-address-line2"
                                                                       placeholder="Enter Your Address"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('address_2_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-location-pin"></i>
                                                                <label class="p-10">Landmark</label>
                                                                <input class="form-control"
                                                                       formControlName="landmark_billing"
                                                                       id="billing-landmark"
                                                                       placeholder="Enter Your landmark"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('landmark_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-code"></i>
                                                                <label class="p-10">Pin Code<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input (change)="getPinCodeData($event, 'BILLING')"
                                                                       class="form-control"
                                                                       formControlName="pin_code_billing"
                                                                       id="billing-pin-code"
                                                                       placeholder="Enter Pincode"
                                                                       type="number"/>
                                                                <small *ngIf="submitted && addAddressForm.get('pin_code_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-map-pins"></i>
                                                                <label class="p-10">City / District<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control" disabled
                                                                       formControlName="city_billing" id="billing-city"
                                                                       placeholder="Enter City" type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('city_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-map-pins"></i>
                                                                <label class="p-10">State<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <select class="form-control" disabled
                                                                        formControlName="state_id_billing"
                                                                        id="billing-state">
                                                                    <option value="">Select State</option>
                                                                    <option *ngFor="let state of states"
                                                                            [value]="state.id">{{state?.name}}</option>
                                                                </select>
                                                                <small *ngIf="submitted && addAddressForm.get('state_id_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-user"></i>
                                                                <label class="p-10">Name<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="name_billing"
                                                                       id="billing-email" placeholder="Enter Your Name"
                                                                       type="text"/>
                                                                <small *ngIf="submitted && addAddressForm.get('name_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-ui-call"></i>
                                                                <label class="p-10">Contact no.<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="contact_billing"
                                                                       id="billing-contact"
                                                                       placeholder="Enter Your Contact Number"
                                                                       type="number"/>
                                                                <small *ngIf="submitted && addAddressForm.get('contact_billing').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-map-pins"></i>
                                                                <label class="p-10">Delivery Type<small
                                                                        class="text-danger ml-5px">*</small></label><br>
                                                                <input [(ngModel)]="selectedDeliveryType"
                                                                       formControlName="type"
                                                                       id="prepaid" type="radio"
                                                                       value="PREPAID">&nbsp;
                                                                <label for="prepaid">PREPAID</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <input [(ngModel)]="selectedDeliveryType"
                                                                       formControlName="type"
                                                                       id="cash" type="radio"
                                                                       value="CASH">&nbsp;
                                                                <label for="cash">COD</label><br>
                                                                <small *ngIf="submitted && addAddressForm.get('type').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <label for="discount">Discount</label>
                                                                <select class="form-control" formControlName="discount"
                                                                        id="discount">
                                                                    <option value="">Select Discount</option>
                                                                    <option value="5">5%</option>
                                                                    <option value="10">10%</option>
                                                                    <option value="15">15%</option>
                                                                    <option value="20">20%</option>
                                                                    <option value="25">25%</option>
                                                                </select>
<!--                                                                <small *ngIf="submitted && addAddressForm.get('discount').hasError('required')"-->
<!--                                                                       class="text-danger">-->
<!--                                                                    Field is required.-->
<!--                                                                </small>-->
                                                            </div>
                                                        </div>

                                                        <div *ngIf="selectedDeliveryType==='CASH'"
                                                             class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <i class="icofont-code"></i>
                                                                <label class="p-10">Delivery Amount<small
                                                                        class="text-danger ml-5px">*</small></label>
                                                                <input class="form-control"
                                                                       formControlName="delivery_amount"
                                                                       placeholder="Enter Delivery Amount"
                                                                       type="number"/>
                                                                <small *ngIf="submitted && addAddressForm.get('delivery_amount').hasError('required')"
                                                                       class="text-danger">
                                                                    Field is required.
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="row footer-width">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <button (click)="modalType = 'ADD_PRODUCT'"
                                            *ngIf="modalType === 'ADD_ADDRESS'"
                                            class="btn btn-primary color-white mr-5px"
                                            style="margin-top: 10px; margin-bottom: 10px">
                                        <i *ngIf="!submitting" class="fa fa-chevron-circle-left mr-1"></i>Add Product
                                    </button>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <button (click)="addAddress()"
                                            *ngIf="modalType === 'ADD_PRODUCT'"
                                            [disabled]="submitting" class="btn btn-success color-white pull-right"
                                            style="margin-top: 10px; margin-bottom: 10px">
                                        <i *ngIf="!submitting" class="fas fa-pen-square mr-1"></i>
                                        <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Add Address
                                    </button>
                                    <button (click)="addMore()"
                                            *ngIf="modalType === 'ADD_PRODUCT'"
                                            [disabled]="submitting"
                                            class="btn btn-primary color-white pull-right mr-5px"
                                            style="margin-top: 10px; margin-bottom: 10px">
                                        <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>
                                        <i *ngIf="!submitting" class="fa fa-plus-circle mr-1"></i>Add More
                                    </button>
                                    <button (click)="submitProductsAndAddress()"
                                            *ngIf="modalType === 'ADD_ADDRESS'" [disabled]="submitting"
                                            class="btn btn-primary color-white pull-right mr-5px"
                                            style="margin-top: 10px; margin-bottom: 10px">
                                        <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>
                                        <i *ngIf="!submitting" class="fa fa-save mr-1"></i>Submit with above selected
                                        address
                                    </button>
                                    <button (click)="addNewAddress()"
                                            *ngIf="modalType === 'ADD_ADDRESS'"
                                            [disabled]="submitting"
                                            class="btn btn-primary color-white pull-right mr-5px"
                                            style="margin-top: 10px; margin-bottom: 10px">
                                        <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>
                                        <i *ngIf="!submitting" class="fa fa-save mr-1"></i>Add new address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Add Order - {{posUser?.name}}</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="posOrder(content)"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-5px">
                        <i aria-hidden="true" class="fas fa-cart-arrow-down mr-8px"></i>&nbsp;Add Order
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="order_id" placeholder="Order Id"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="product_id">
                                        <option value="">Select Product</option>
                                        <option *ngFor="let product of products"
                                                [value]="product.id">{{product?.name}}</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <select class="form-control" formControlName="delivery_status">
                                        <option value="">Select Status</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="PLACED">Placed</option>
                                        <option value="DELIVERING">Delivering</option>
                                        <option value="DELIVERED">Delivered</option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Order Id</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Product</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Quantity</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Unit Price</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Tax (in %)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Total (in INR)</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="orders && orders.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let order of orders; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">
                                                    {{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="order['order_id']" class="mt-1">{{order?.order_id}}</h6>
                                                <h6 *ngIf="!order['order_id']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="order['product']" class="mt-1">{{order?.product?.name}}</h6>
                                                <h6 *ngIf="!order['product']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="order['quantity']"
                                                    class="mt-1 text-capitalize">{{order?.quantity}}</h6>
                                                <h6 *ngIf="!order['quantity']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="order['discounted_price']"
                                                    class="mt-1 text-capitalize">{{order?.discounted_price}}</h6>
                                                <h6 *ngIf="!order['discounted_price']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="order['igst']"
                                                    class="mt-1 text-capitalize">{{order?.igst}}</h6>
                                                <h6 *ngIf="!order['igst']" class="mt-1">-</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 *ngIf="order['igst'] && order['discounted_price']"
                                                    class="mt-1 text-capitalize">{{order['total_price']}}</h6>
                                                <h6 *ngIf="!order['igst'] || !order['discounted_price'] || !order['total_price']"
                                                    class="mt-1">
                                                    -</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 [ngClass]="{'text-success': order['delivery_status'] === 'SUCCESS', 'text-warning':  order['delivery_status'] === 'PENDING', 'text-danger':  order['delivery_status'] === 'FAILED' }"
                                                    class="mt-1 text-capitalize">{{order?.delivery_status.toLowerCase()}}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="7">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="orders && orders.length > 0; else noDataToShowMobile" class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let order of orders; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{order?.name}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{order?.contact}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Transaction Id - {{order?.transaction_id}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Amount - {{order?.amount}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - {{order?.status}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                [boundaryLinks]="true" [collectionSize]="pagination.total"
                                [maxSize]="5" [pageSize]="pagination.perpage"
                                class="pagination-primary">
                    <ng-template ngbPaginationPrevious>
                           <span [class]="'font-weight-bolder'" [data-feather]="'chevron-left'"
                                 [size]="18">
                           </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                           <span [class]="'font-weight-bolder'" [data-feather]="'chevron-right'"
                                 [size]="18">
                           </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
