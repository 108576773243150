<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        (click)="toggleSidebar()"
                        aria-label="Close"
                        class="close"
                        data-dismiss="modal"
                        type="button"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 *ngIf="!data || !data['id']" class="modal-title">Add Prescription</h5>
                    <h5 *ngIf="data && data['id']" class="modal-title">Update Prescription</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form [formGroup]="addPrescriptionForm" class="form mt-2 shadow-none" novalidate>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="chief_complaint">Chief Complaint</label>
                                        <input class="form-control" formControlName="chief_complaint"
                                               id="chief_complaint"
                                               list="complaints" type="text">
                                        <datalist id="complaints">
                                            <option value="Cough">
                                            <option value="Cold">
                                            <option value="Fatigue">
                                            <option value="Nausea">
                                            <option value="Vomiting">
                                            <option value="Common Cold">
                                            <option value="Sore Throat">
                                            <option value="Weakness">
                                            <option value="Pain in Throat">
                                        </datalist>
                                        <small *ngIf="submitted && addPrescriptionForm.get('chief_complaint').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="examination">On ExaminationNotes</label>
                                        <input class="form-control" formControlName="examination" id="examination"
                                               placeholder="Enter Examination Notes " type="text">
                                        <small *ngIf="submitted && addPrescriptionForm.get('examination').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-1">
                                <div class="col-12">
                                    <div class="form-group">
                                        <!--<label for="vitals">Vitals</label>
                                        <input class="form-control" id="vitals" type="text"
                                               formControlName="vitals">-->
                                        <button (click)="addVitals = !addVitals"
                                                class="btn btn-warning pull-left color-white color-white users-btn">
                                            <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>Add Vitals
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addVitals" class="card  shadow p-3  bg-white rounded">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="temperature">Temperature(F)</label>
                                            <input class="form-control" formControlName="temperature" id="temperature"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="pulse">Pulse(bpm)</label>
                                            <input class="form-control" formControlName="pulse" id="pulse"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="respiratory_rate">Respiratory Rate(rpm)</label>
                                            <input class="form-control" formControlName="respiratory_rate"
                                                   id="respiratory_rate"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="blood_pressure">Blood Pressure(mmHg)</label>
                                            <input class="form-control" formControlName="blood_pressure"
                                                   id="blood_pressure"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="spo2">SPO2(%)</label>
                                            <input class="form-control" formControlName="spo2" id="spo2"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="height">Height(cms)</label>
                                            <input class="form-control" formControlName="height" id="height"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="weight">Weight(kgs)</label>
                                            <input class="form-control" formControlName="weight" id="weight"
                                                   type="text">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="diagnosis">Diagnosis</label>
                                        <input class="form-control" formControlName="diagnosis" id="diagnosis"
                                               list="diagnosises" type="text">
                                        <datalist id="diagnosises">
                                            <option value="Common Cold">
                                            <option value="Typhoid fever">
                                            <option value="Pneumania">
                                            <option value="Dengue fever">
                                            <option value="Strep throat">
                                            <option value="Food Poisoning">
                                            <option value="Viral Fever">
                                            <option value="Dengue">
                                        </datalist>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-1">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="medicine">Medication</label>
                                        <div class="row">
                                            <div class="col-8">
                                                <input #medicineInput class="form-control" id="medicine" type="text">
                                            </div>
                                            <div class="col-4">
                                                <button (click)="addMedicine(medicineInput.value)"
                                                        class="btn btn-primary" type="button">Add
                                                </button>
                                            </div>
                                            <div class="col-12">
                                                <small *ngIf="medicines.length === 0 && isMedicationRequired===true"
                                                       class="text-danger">
                                                    Medication is required.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let medicine of medicines; let i = index" class="row">
                                <div class="col-12">
                                    <div class="card shadow-lg bg-body rounded">
                                        <div class="card-header p-1" style="display: inline">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h5 class="card-title">{{ medicine.name }}</h5>
                                                </div>
                                                <div class="col-6">
                                                    <i (click)="removeMedicine(i)"
                                                       aria-hidden="true"
                                                       class="fas fa-trash f_20 float-right text-danger cursor-pointer"
                                                       title="Delete"></i>
                                                    <!--                                                    <i class="far fa-trash-alt text-danger f_20" (click)="removeMedicine(i)"></i>-->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label for="frequency">Frequency</label>
                                                </div>
                                                <div class="col-6">
                                                    <input #frequency
                                                           (focusout)="storeValues(medicine, frequency.value, timing.value, duration.value, remark.value)"
                                                           [value]="data && data['id'] ? medicine.frequency : ''"
                                                           class="form-control" id="frequency"
                                                           type="text">
                                                </div>
                                            </div>
                                            <div class="row py-1">
                                                <div class="col-6">
                                                    <label for="timing">Timing</label>
                                                </div>
                                                <div class="col-6">
                                                    <input #timing
                                                           (focusout)="storeValues(medicine, frequency.value, timing.value, duration.value, remark.value)"
                                                           [value]="data && data['id'] ? medicine.timing : ''"
                                                           class="form-control" id="timing"
                                                           type="text">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <label for="duration">Duration</label>
                                                </div>
                                                <div class="col-6">
                                                    <input #duration
                                                           (focusout)="storeValues(medicine, frequency.value, timing.value, duration.value, remark.value)"
                                                           [value]="data && data['id'] ? medicine.duration : ''"
                                                           class="form-control" id="duration"
                                                           type="text">
                                                </div>
                                            </div>
                                            <div class="row pt-1">
                                                <div class="col-6">
                                                    <label for="remark">Remark</label>
                                                </div>
                                                <div class="col-6">
                                                    <input #remark
                                                           (focusout)="storeValues(medicine, frequency.value, timing.value, duration.value, remark.value)"
                                                           [value]="data && data['id'] ? medicine.remark : ''"
                                                           class="form-control" id="remark"
                                                           type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--                            <div class="row">-->
                            <!--                                <div class="col-12">-->
                            <!--                                    <p *ngIf="isMedicineSaved=== true" class="text-success">-->
                            <!--                                        Medicine saved successfully!-->
                            <!--                                    </p>-->
                            <!--                                </div>-->
                            <!--                                <div class="col-12">-->
                            <!--                                    <p *ngIf="isMedicationRequired === true && isMedicineSaved === false"-->
                            <!--                                       class="text-danger">-->
                            <!--                                        Please,first saved your Medicines.-->
                            <!--                                    </p>-->
                            <!--                                </div>-->
                            <!--                                <div class="col-12">-->
                            <!--                                    <button (click)="saveMedicationData()"-->
                            <!--                                            class="btn btn-primary" type="button">Save Medicine-->
                            <!--                                    </button>-->
                            <!--                                </div>-->
                            <!--                            </div>-->

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="procedures">Procedures</label>
                                        <input class="form-control" formControlName="procedures" id="procedures"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="investigation">Investigartion</label>
                                        <input class="form-control" formControlName="investigation" id="investigation"
                                               type="text">
                                        <small *ngIf="submitted && addPrescriptionForm.get('investigation').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="advice">Advice</label>
                                        <input class="form-control" formControlName="advice" id="advice"
                                               list="advices" type="text">
                                        <datalist id="advices">
                                            <option value="Drink more water">
                                            <option value="Avoid oily Food">
                                            <option value="Exercise Regularly">
                                            <option value="Avoid smoking">
                                            <option value="Eat more fruits">
                                            <option value="Get 8 hours sleep">
                                        </datalist>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button (click)="toggleSidebar()" class="btn btn-danger" rippleEffect type="reset">
                                        Cancel
                                    </button>
                                    <button (click)="submit()" *ngIf="!data || !data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Submit
                                    </button>
                                    <button (click)="update()" *ngIf="data && data['id']" class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>

