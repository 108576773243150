<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h4 class="mt-1 mb-1 font-weight-bold">Add Key Ingredients</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <form novalidate [formGroup]="keyIngredientForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="font-weight-bold" for="Ingredient">Ingredient</label>
                                    <input class="form-control" id="Ingredient" type="text" placeholder="Ingredient"
                                           formControlName="ingredient"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                        <label class="font-weight-bold" for="about">About</label>
                        <quill-editor class="university-info" [(ngModel)]="contentData"
                                      name="quill-content" id="about"></quill-editor>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <button class="btn btn-primary pull-right" (click)="submit()">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="table table-list text-center">
                    <thead>
                    <tr>
                        <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                        </th>
                        <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Ingredient</h6>
                        </th>
                        <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">About</h6>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let keyIngredient of keyIngredients let i= index">
                        <td class="text-center">
                            <h6 class="mt-1">{{i + 1}}.</h6>
                        </td>
                        <td class="text-center">
                            <h6 class="mt-1">{{keyIngredient?.ingredient}}</h6>
                        </td>
                        <td class="text-center">
                            <div [innerHTML]="keyIngredient?.about"></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
