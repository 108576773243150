<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Meta Tags</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Meta Tags</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <!--                    <pre class="text-white text-justify"><p>{{data | json}}</p></pre>-->
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addMetaTagsForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="blog-id">Blog</label>
                                        <select class="form-control" formControlName="blog_id" id="blog-id">
                                            <option value="" selected disabled>Select Blog</option>
                                            <option [value]="blog?.id"
                                                    *ngFor="let blog of allBlogInsight">{{blog?.heading}}
                                            </option>
                                        </select>
<!--                                        <small *ngIf="submitted && addMetaTagsForm.get('blog_id').hasError('required')"-->
<!--                                               class="text-danger">-->
<!--                                            Field is required.-->
<!--                                        </small>-->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input class="form-control" id="title" type="text"
                                               formControlName="title">
                                        <small *ngIf="submitted && addMetaTagsForm.get('title').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_description">Meta Description</label>
                                        <textarea class="form-control" id="meta_description"
                                                  formControlName="meta_description"></textarea>
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_description').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_keyword">Meta Keyword</label>
                                        <textarea class="form-control" id="meta_keyword"
                                                  formControlName="meta_keyword"></textarea>
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_keyword').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_og_title">Meta_og_Title</label>
                                        <input class="form-control" id="meta_og_title" type="text"
                                               formControlName="meta_og_title">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_og_title').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_og_url">Meta_og_Url</label>
                                        <input class="form-control" id="meta_og_url" type="text"
                                               formControlName="meta_og_url">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_og_url').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_og_type">Meta_og_Type</label>
                                        <input class="form-control" id="meta_og_type" type="text"
                                               formControlName="meta_og_type">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_og_type').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_og_description">Meta_og_Description</label>
                                        <textarea class="form-control" id="meta_og_description"
                                                  formControlName="meta_og_description"></textarea>
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_og_description').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_og_image">Meta_og_Image</label>
                                        <input class="form-control" id="meta_og_image" type="text"
                                               formControlName="meta_og_image">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_og_image').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_twitter_card">Meta_Twitter_Card</label>
                                        <input class="form-control" id="meta_twitter_card" type="text"
                                               formControlName="meta_twitter_card">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_twitter_card').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_twitter_title">Meta_Twitter_Title</label>
                                        <input class="form-control" id="meta_twitter_title" type="text"
                                               formControlName="meta_twitter_title">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_twitter_title').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_twitter_description">Meta_Twitter_Description</label>
                                        <textarea class="form-control" id="meta_twitter_description"
                                                  formControlName="meta_twitter_description"></textarea>
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_twitter_description').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_twitter_url">Meta_Twitter_Url</label>
                                        <input class="form-control" id="meta_twitter_url" type="text"
                                               formControlName="meta_twitter_url">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_twitter_url').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="meta_twitter_image">Meta_Twitter_Image</label>
                                        <input class="form-control" id="meta_twitter_image" type="text"
                                               formControlName="meta_twitter_image">
                                        <small *ngIf="submitted && addMetaTagsForm.get('meta_twitter_image').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button type="reset" rippleEffect class="btn btn-danger" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
