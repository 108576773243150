import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {CountryService} from '../../../shared/services/country.service';
import {CategoryService} from '../../../shared/services/category.service';
import {SubCategoryService} from '../../../shared/services/sub-category.service';
import {ProductService} from '../../../shared/services/product.service';


@Component({
    selector: 'app-add-product',
    templateUrl: './add-product.component.html',
    styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnChanges {
    addProductForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    countries: any = [];
    categories: any = [];
    subCategories: any = [];

    constructor(private _coreSidebarService: CoreSidebarService,
                private productService: ProductService,
                private fb: FormBuilder,
                private countryService: CountryService,
                private categoryService: CategoryService,
                private subCategoryService: SubCategoryService,
                private alertService: AlertService) {
    }

    ngOnChanges(): void {
        this.refresh();
        // this.refresh();
    }

    refresh() {
        this.addProductForm = this.fb.group({
            'name': ['', Validators.required],
            'product_alias': [''],
            'product_code': [''],
            'tagline': ['', Validators.required],
            'actual_price': ['', Validators.required],
            'discounted_price': ['', Validators.required],
            'category_id': ['', Validators.required],
            'sub_category_id': ['', Validators.required],
            'description': ['', Validators.required],
            'indication': ['', Validators.required],
            'disclaimer': ['', Validators.required],
            'contraindications': ['', Validators.required],
            'manufacturer_details': ['', Validators.required],
            'rating': [''],
            'url': [''],
            'hsn': [''],
            'cgst': [''],
            'sgst': [''],
            'igst': [''],
            'ugst': [''],
            'country_id': ['', Validators.required]
        });
        this.getCountries();
    }

    getCountries() {
        this.countryService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.countries = data['data'];
                this.getCategories();
            } else {
                this.countries = [];
            }
        });
    }

    getCategories() {
        this.categoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.categories = data['data'];
                this.getSubCategories();
            } else {
                this.categories = [];
            }
        });
    }

    getSubCategories() {
        this.subCategoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.subCategories = data['data'];
                this.addProductForm.patchValue({
                    'country_id': '100'
                });
                if (this.data && this.data['id']) {
                    this.patchForm();
                }
            } else {
                this.subCategories = [];
            }
        });
    }

    patchForm() {
        this.addProductForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'product_alias': (this.data && this.data['product_alias']) ? this.data['product_alias'] : '',
            'product_code': (this.data && this.data['product_code']) ? this.data['product_code'] : '',
            'tagline': (this.data && this.data['tagline']) ? this.data['tagline'] : '',
            'actual_price': (this.data && this.data['actual_price']) ? this.data['actual_price'] : '',
            'discounted_price': (this.data && this.data['discounted_price']) ? this.data['discounted_price'] : '',
            'category_id': (this.data && this.data['category_id']) ? this.data['category_id'] : '',
            'sub_category_id': (this.data && this.data['sub_category'].id) ? this.data['sub_category'].id : '2',
            'description': (this.data && this.data['description']) ? this.data['description'] : '',
            'indication': (this.data && this.data['indication']) ? this.data['indication'] : '',
            'disclaimer': (this.data && this.data['disclaimer']) ? this.data['disclaimer'] : '',
            'contraindications': (this.data && this.data['contraindications']) ? this.data['contraindications'] : '',
            'manufacturer_details': (this.data && this.data['manufacturer_details']) ? this.data['manufacturer_details'] : '',
            'rating': (this.data && this.data['rating']) ? this.data['rating'] : '',
            'url': (this.data && this.data['url']) ? this.data['url'] : '',
            'hsn': (this.data && this.data['hsn']) ? this.data['hsn'] : '',
            'cgst': (this.data && this.data['cgst']) ? this.data['cgst'] : '',
            'sgst': (this.data && this.data['sgst']) ? this.data['sgst'] : '',
            'igst': (this.data && this.data['igst']) ? this.data['igst'] : '',
            'ugst': (this.data && this.data['ugst']) ? this.data['ugst'] : '',
            'country_id': (this.data && this.data['country_id']) ? this.data['country_id'] : ''
        });
    }


    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-product').toggleOpen();
    }

    submit() {
        this.submitted = true;
        if (!this.addProductForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.name) ? this.addProductForm.value.name : '',
                'product_alias': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.product_alias) ? this.addProductForm.value.product_alias : '',
                'product_code': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.product_code) ? this.addProductForm.value.product_code : '',
                'tagline': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.tagline) ? this.addProductForm.value.tagline : '',
                'actual_price': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.actual_price) ? this.addProductForm.value.actual_price : '',
                'discounted_price': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.discounted_price) ? this.addProductForm.value.discounted_price : '',
                'category_id': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.category_id) ? this.addProductForm.value.category_id : '',
                'sub_category_id': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.sub_category_id) ? this.addProductForm.value.sub_category_id : '',
                'description': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.description) ? this.addProductForm.value.description : '',
                'indication': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.indication) ? this.addProductForm.value.indication : '',
                'disclaimer': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.disclaimer) ? this.addProductForm.value.disclaimer : '',
                'contraindications': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.contraindications) ? this.addProductForm.value.contraindications : '',
                'manufacturer_details': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.manufacturer_details) ? this.addProductForm.value.manufacturer_details : '',
                'rating': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.rating) ? this.addProductForm.value.rating : '',
                'url': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.url) ? this.addProductForm.value.url : '',
                'hsn': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.hsn) ? this.addProductForm.value.hsn : '',
                'cgst': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.cgst) ? this.addProductForm.value.cgst : '',
                'sgst': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.sgst) ? this.addProductForm.value.sgst : '',
                'igst': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.igst) ? this.addProductForm.value.igst : '',
                'ugst': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.ugst) ? this.addProductForm.value.ugst : '',
                'country_id': (this.addProductForm && this.addProductForm.value && this.addProductForm.value.country_id) ? this.addProductForm.value.country_id : '',
            };
            this.submitting = true;
            this.productService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addProductForm.reset();
                    this.addProductForm.patchValue({
                        'name': '',
                        'product_alias': '',
                        'product_code': '',
                        'tagline': '',
                        'actual_price': '',
                        'discounted_price': '',
                        'category_id': '',
                        'sub_category_id': '',
                        'description': '',
                        'indication': '',
                        'disclaimer': '',
                        'contraindications': '',
                        'manufacturer_details': '',
                        'rating': '',
                        'hsn': '',
                        'cgst': '',
                        'sgst': '',
                        'igst': '',
                        'ugst': '',
                        'url': '',
                        'country_id': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].tagline.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tagline[0]);
                        return;
                    }
                    if (error['error']['errors'].country_id && error['error']['errors'].country_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].country_id[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addProductForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addProductForm.value && this.addProductForm.value.name) ? this.addProductForm.value.name : '',
                'product_alias': (this.addProductForm.value && this.addProductForm.value.product_alias) ? this.addProductForm.value.product_alias : '',
                'product_code': (this.addProductForm.value && this.addProductForm.value.product_code) ? this.addProductForm.value.product_code : '',
                'tagline': (this.addProductForm.value && this.addProductForm.value.tagline) ? this.addProductForm.value.tagline : '',
                'actual_price': (this.addProductForm.value && this.addProductForm.value.actual_price) ? this.addProductForm.value.actual_price : '',
                'discounted_price': (this.addProductForm.value && this.addProductForm.value.discounted_price) ? this.addProductForm.value.discounted_price : '',
                'category_id': (this.addProductForm.value && this.addProductForm.value.category_id) ? this.addProductForm.value.category_id : '',
                'sub_category_id': (this.addProductForm.value && this.addProductForm.value.sub_category_id) ? this.addProductForm.value.sub_category_id : '',
                'description': (this.addProductForm.value && this.addProductForm.value.description) ? this.addProductForm.value.description : '',
                'indication': (this.addProductForm.value && this.addProductForm.value.indication) ? this.addProductForm.value.indication : '',
                'disclaimer': (this.addProductForm.value && this.addProductForm.value.disclaimer) ? this.addProductForm.value.disclaimer : '',
                'contraindications': (this.addProductForm.value && this.addProductForm.value.contraindications) ? this.addProductForm.value.contraindications : '',
                'manufacturer_details': (this.addProductForm.value && this.addProductForm.value.manufacturer_details) ? this.addProductForm.value.manufacturer_details : '',
                'rating': (this.addProductForm.value && this.addProductForm.value.rating) ? this.addProductForm.value.rating : '',
                'hsn': (this.addProductForm.value && this.addProductForm.value.hsn) ? this.addProductForm.value.hsn : '',
                'cgst': (this.addProductForm.value && this.addProductForm.value.cgst) ? this.addProductForm.value.cgst : '',
                'sgst': (this.addProductForm.value && this.addProductForm.value.sgst) ? this.addProductForm.value.sgst : '',
                'igst': (this.addProductForm.value && this.addProductForm.value.igst) ? this.addProductForm.value.igst : '',
                'ugst': (this.addProductForm.value && this.addProductForm.value.ugst) ? this.addProductForm.value.ugst : '',
                'url': (this.addProductForm.value && this.addProductForm.value.url) ? this.addProductForm.value.url : '',
                'country_id': (this.addProductForm.value && this.addProductForm.value.country_id) ? this.addProductForm.value.country_id : ''
            };
            this.submitting = true;
            this.productService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].tagline && error['error']['errors'].tagline.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].tagline[0]);
                        return;
                    }
                    if (error['error']['errors'].country_id && error['error']['errors'].country_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].country_id[0]);
                        return;
                    }
                }
            });
        }
    }
}
